import BaseModal from '../_BaseModal';
import { useEffect, useRef, useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Button from '../../Button';
import classNames from '../../../utils/classNames.ts';
import { VendorDomainTypeOption } from '../../../types/vendor-domains.ts';
import { useVendorDomainsManager } from '../../../providers/VendorDomainsManagerProvider';
import isValidCustomHostname from '../../../utils/isValidCustomHostname.ts';
import BaseCard from '../../_cards/BaseCard';
import Typography from '../../Typography';
import Input from '../../Input';

const DOMAIN_TYPES_OPTIONS = [
    {
        id: 'PLATFORM',
        title: 'Platform',
        description: 'Point your domain to your platform, where your customer will consume your content.',
    },
    {
        id: 'CHECKOUT',
        title: 'Checkout',
        description: 'Point your domain to a checkout page, where your customer will pay for your content.',
    },
] as VendorDomainTypeOption[];

// audiobook, fitness course/workout

const MINIMUM_PRODUCT_DOMAIN_NAME_LENGTH = 5;
const NewDomainModal = ({ isOpen, onClose }: { isOpen?: boolean; onClose: () => void }) => {
    const domainNameInputRef = useRef<HTMLInputElement>(null);
    const [domainName, setDomainName] = useState('');
    const [selectedDomainType, setSelectedDomainType] = useState(DOMAIN_TYPES_OPTIONS[0]);

    const { createUpdateDomains, isCreatingUpdatingDomains } = useVendorDomainsManager();

    const onResetState = () => {
        setDomainName('');
        setSelectedDomainType(DOMAIN_TYPES_OPTIONS[0]);
    };

    useEffect(() => {
        if (domainNameInputRef?.current) domainNameInputRef?.current?.focus();
    }, []);

    const onClickCreate = async () => {
        if (domainName?.length < MINIMUM_PRODUCT_DOMAIN_NAME_LENGTH || !selectedDomainType) return;

        await createUpdateDomains({
            domains: [
                {
                    domainName,
                    type: selectedDomainType.id,
                },
            ],
        });
        onClose();
        onResetState();
    };

    return (
        <BaseModal
            title="New Custom Domain"
            subtitle="Add a custom domain to your platform."
            isOpen={isOpen}
            onClose={onClose}>
            <div className="mt-4 flex flex-col gap-4 lg:max-w-lg">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                        Domain Name
                    </label>
                    <Input
                        ref={domainNameInputRef}
                        id="domain-name"
                        name="domain-name"
                        type="url"
                        autoComplete="false"
                        // className="w-full relative block py-1.5 sm:text-sm border-0 text-black outline"
                        placeholder={`Domain name (e.g. ${
                            {
                                PLATFORM: 'app',
                                CHECKOUT: 'checkout',
                            }[selectedDomainType?.id]
                        }.mywebsite.com)`}
                        value={domainName}
                        onChange={(e) => setDomainName(e?.target?.value?.toLowerCase() || '')}
                    />
                </div>
                <RadioGroup
                    value={selectedDomainType?.id}
                    onChange={(id) =>
                        setSelectedDomainType(
                            DOMAIN_TYPES_OPTIONS.find((type) => type.id === id) as VendorDomainTypeOption,
                        )
                    }
                    className="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
                    {DOMAIN_TYPES_OPTIONS.map(({ id, title, description }) => (
                        <Radio
                            key={id}
                            value={id}
                            aria-label={title}
                            aria-description={description}
                            className="group relative cursor-pointer">
                            <BaseCard className="flex h-full">
                                <span className="flex flex-1">
                                    <span className="flex flex-col">
                                        <Typography isPrimary className="block">{title}</Typography>
                                        <Typography isTertiary className="mt-1 flex items-center">
                                            {description}
                                        </Typography>
                                        {/*<span className="mt-6 text-sm font-medium text-gray-900">{mailingList.users}</span>*/}
                                    </span>
                                </span>
                                <CheckCircleIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 text-white [.group:not([data-checked])_&]:invisible"
                                />
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute -inset-px rounded-md border border-transparent group-data-[focus]:border group-data-[checked]:border-white"
                                />
                            </BaseCard>
                        </Radio>
                    ))}
                </RadioGroup>
                <div className={classNames('mt-4 flex flex-row items-center gap-4 justify-end')}>
                    <Button
                        isPrimary
                        isLoading={isCreatingUpdatingDomains}
                        isDisabled={
                            domainName?.length < MINIMUM_PRODUCT_DOMAIN_NAME_LENGTH ||
                            !selectedDomainType ||
                            isCreatingUpdatingDomains ||
                            !isValidCustomHostname(domainName)
                        }
                        onClick={onClickCreate}>
                        Create Custom Domain
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default NewDomainModal;
