import BaseDrawer from '../_BaseDrawer';
import { useEffect, useState } from 'react';
import { useVendorProductsManager } from '../../../providers/VendorProductsManagerProvider';
import Button from '../../Button';
import { PencilIcon } from '@heroicons/react/24/outline';
import Loader from '../../Loader';
import MediaPickerModal from '../../_modals/MediaPickerModal';
import ContentVideoPlayer from '../../ContentVideoPlayer';
import Input from '../../Input';
import Typography from '../../Typography';
import ReorderableContentAssetsList from '../../ReorderableContentAssetsList';
import ContentPickerModal from '../../_modals/ContentPickerModal';
import { VendorContentItem } from '../../../types/vendor-content.ts';
import ConfirmationModal from '../../_modals/ConfirmationModal';
import PageLoader from '../../PageLoader';
import BaseCard from '../../_cards/BaseCard';
import RichTextEditor from '../../../components/RichTextEditor';

export default ({ productItemId, onClose }: { productItemId?: string; onClose: () => void }) => {
    const {
        createUpdateProducts,
        isCreatingUpdatingProducts,
        isDeletingProducts,
        deleteProducts,
        productItem,
        isFetchingProductItem,
        updateProductItemQuery,
    } = useVendorProductsManager();
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [title, setTitle] = useState(productItem?.title || '');
    const [description, setDescription] = useState(productItem?.description || '');

    const [isMediaPickerModalOpen, setIsMediaPickerModalOpen] = useState(
        null as null | {
            title: string;
            defaultImageUrl?: string;
            defaultImageDarkBackground?: boolean;
            onUpdateImage: (imageUrl: string) => boolean;
        },
    );

    const [isContentPickerModalOpen, setIsContentPickerModalOpen] = useState(
        null as null | {
            title: string;
            onUpdateContentItems: (contentItems: VendorContentItem[]) => boolean;
            isMultiSelectEnabled?: boolean;
        },
    );

    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );

    useEffect(() => {
        if (productItem?.title !== title) setTitle(productItem?.title as string);
        setDescription(productItem?.description || ('' as string));
    }, [productItem]);

    useEffect(() => {
        if (productItemId)
            updateProductItemQuery({
                productItemId,
            });
    }, [productItemId]);

    const onClickUpdateThumbnail = async () => {
        setIsMediaPickerModalOpen({
            title: 'Update Thumbnail Image',
            onUpdateImage: (thumbnailImageUrl: string) => {
                if (thumbnailImageUrl && productItem?.SK)
                    (async () => {
                        await createUpdateProducts({
                            productItems: [
                                {
                                    SK: productItem?.SK,
                                    images: {
                                        thumbnail: thumbnailImageUrl,
                                    },
                                },
                            ],
                        });
                    })();
                return true;
            },
        });
    };
    //
    const onClickUpdateTitle = async () => {
        if (title && productItem?.SK)
            await createUpdateProducts({
                productItems: [
                    {
                        SK: productItem.SK,
                        title: title,
                    },
                ],
            }).then(() => {
                setTitle('');
                setIsEditingTitle(false);
            });
    };

    const onClickUpdateDescription = async () => {
        if (description !== productItem?.description && productItem?.SK)
            await createUpdateProducts({
                productItems: [{ SK: productItem?.SK as string, description: description as string }],
            });
    };

    const onClickAddAsset = async () => {
        if (productItem?.SK)
            setIsContentPickerModalOpen({
                title: 'Select content',
                isMultiSelectEnabled: true,
                onUpdateContentItems: (contentItems) => {
                    if (contentItems?.length > 0)
                        (async () => {
                            await createUpdateProducts({
                                productItems: [
                                    {
                                        SK: productItem.SK,
                                        contentAssetsSKs: [
                                            ...(productItem?.contentAssets?.map(({ SK }) => SK) || []),
                                            ...(contentItems.map(({ SK }) => SK) || []),
                                        ],
                                    },
                                ],
                            });
                        })();
                    return true;
                },
            });
    };

    const onContentAssetsReordered = async ({
        reorderedContentAssets,
    }: {
        reorderedContentAssets: VendorContentItem[];
    }) => {
        if (productItem?.SK)
            await createUpdateProducts({
                productItems: [
                    {
                        SK: productItem.SK,
                        contentAssetsSKs: reorderedContentAssets.map(({ SK }) => SK),
                    },
                ],
            });
    };

    const onDeleteContentAsset = async ({ contentAsset }: { contentAsset: VendorContentItem }) => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete the asset "${contentAsset?.fileName}"?`,
            onConfirm: async () => {
                if (productItem?.SK)
                    await createUpdateProducts({
                        productItems: [
                            {
                                SK: productItem.SK,
                                contentAssetsSKs: productItem?.contentAssets
                                    ?.filter(({ SK }) => SK !== contentAsset.SK)
                                    ?.map(({ SK }) => SK),
                            },
                        ],
                    });
            },
        });
    };

    const onClickDeleteProductItem = async () => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete "${productItem?.title}"?`,
            onConfirm: async () => {
                if (productItem?.SK) {
                    await deleteProducts({
                        productItems: [{ SK: productItem?.SK as string }],
                    });

                    onClose();
                }
            },
        });
    };

    return (
        <BaseDrawer isOpen={!!productItemId} onClose={onClose} className="relative z-[100] w-96">
            <PageLoader isLoading={isFetchingProductItem}>
                <div className="space-y-6">
                    {productItem?.content?.vdoCipherVideoData?.id && (
                        <BaseCard className="aspect-h-7 aspect-w-10 block w-full overflow-hidden !p-0">
                            <div className="flex-1 flex justify-center items-center">
                                {productItem?.content?.vdoCipherVideoData?.status !== 'ready' && (
                                    <>
                                        {(productItem?.content?.contentType?.startsWith('video/') ||
                                            productItem?.content?.contentType?.startsWith('audio/')) &&
                                            (productItem?.content?.vdoCipherVideoData?.posters?.length > 0 &&
                                            productItem?.content?.vdoCipherVideoData?.poster ? (
                                                <img
                                                    src={productItem?.content?.vdoCipherVideoData?.poster}
                                                    className="w-full flex-1 object-contain min-h-0 rounded-md overflow-hidden"
                                                />
                                            ) : (
                                                <Loader width={30} />
                                            ))}
                                    </>
                                )}
                                {productItem?.content?.vdoCipherVideoData?.status === 'ready' && (
                                    <ContentVideoPlayer contentItem={productItem?.content} />
                                )}
                            </div>
                        </BaseCard>
                    )}

                    {isEditingTitle ? (
                        <div className="flex flex-row items-center gap-2 mb-2">
                            <Input
                                type="text"
                                placeholder={productItem?.title}
                                value={title}
                                onChange={(e) => setTitle(e.target.value as string)}
                                className="flex-1 w-full"
                            />
                            <Button
                                size="sm"
                                onClick={onClickUpdateTitle}
                                isDisabled={!title || title === productItem?.title}
                                isLoading={isCreatingUpdatingProducts}>
                                Update
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div
                                className="text-base font-semibold text-gray-900 flex flex-row items-center gap-2 cursor-pointer"
                                onClick={() => setIsEditingTitle(true)}>
                                <span className="sr-only">Details for </span>
                                <Typography isPrimary>{productItem?.title}</Typography>
                                <PencilIcon className="h-3 w-3 text-white" />
                            </div>
                            <Typography isTertiary>
                                {
                                    {
                                        CONTENT_GROUP: 'Section',
                                        CONTENT: 'Content',
                                    }?.[productItem?.type as string]
                                }
                            </Typography>
                        </div>
                    )}

                    <div>
                        <Typography className="mb-2">Thumbnail</Typography>
                        <BaseCard
                            className="w-36 !p-0 overflow-hidden aspect-video relative cursor-pointer bg-black"
                            onClick={onClickUpdateThumbnail}>
                            {productItem?.images?.thumbnail && (
                                <img src={productItem?.images?.thumbnail} className="h-full w-full object-contain" />
                            )}
                            <div className="flex items-center justify-center h-full w-full absolute top-0 right-0 bottom-0 left-0 z-20">
                                {isCreatingUpdatingProducts ? (
                                    <Loader width={30} color="white" />
                                ) : (
                                    <PencilIcon className="h-5 w-5 text-white" />
                                )}
                            </div>
                        </BaseCard>
                    </div>
                    <div>
                        <Typography className="mb-2">Description</Typography>
                        <RichTextEditor
                            className="w-full h-24"
                            onTextChange={setDescription}
                            defaultValue={description}
                        />
                        {productItem?.description !== description && (
                            <Button isPrimary size="xs" onClick={onClickUpdateDescription} className="ml-auto mt-4">
                                Update Description
                            </Button>
                        )}
                    </div>

                    <div>
                        <div className="flex flex-row items-center justify-between">
                            <Typography>Assets</Typography>

                            <Button
                                size="xs"
                                onClick={onClickAddAsset}
                                isLoading={isFetchingProductItem || isCreatingUpdatingProducts}>
                                Add
                            </Button>
                        </div>
                        <ReorderableContentAssetsList
                            items={productItem?.contentAssets || []}
                            onContentAssetsReordered={onContentAssetsReordered}
                            onDeleteContentAsset={onDeleteContentAsset}
                        />
                    </div>
                    <div className="flex justify-end items-center gap-2">
                        <div
                            className="text-xs text-red-500 text-right underline cursor-pointer flex flex-row items-center justify-end gap-2"
                            onClick={onClickDeleteProductItem}>
                            {`Delete ${{
                                CONTENT_GROUP: 'Section',
                                CONTENT: 'Content',
                            }?.[productItem?.type as string]}`}
                            {isDeletingProducts && <Loader width={15} height={15} />}
                        </div>
                    </div>
                </div>
            </PageLoader>

            <MediaPickerModal
                isOpen={!!isMediaPickerModalOpen}
                onClose={() => setIsMediaPickerModalOpen(null)}
                {...isMediaPickerModalOpen}
            />

            <ContentPickerModal
                isOpen={!!isContentPickerModalOpen}
                onClose={() => setIsContentPickerModalOpen(null)}
                {...isContentPickerModalOpen}
            />

            <ConfirmationModal
                isOpen={!!isShowConfirmationModal}
                onClose={() => setIsShowConfirmationModal(null)}
                {...isShowConfirmationModal}
            />
        </BaseDrawer>
    );
};
