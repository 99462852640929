import { signOut, useAuth } from '../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PageHeading from '../../components/PageHeading';
import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import Typography from '../../components/Typography';

const SettingsPage = () => {
    const { t } = useTranslation();
    const { cognitoUser, updateCognitoUserAttributes } = useAuth();

    const [isEditingName, setIsEditingName] = useState(false);
    const [firstName, setFirstName] = useState(cognitoUser?.attributes?.givenName);
    const [lastName, setLastName] = useState(cognitoUser?.attributes?.familyName);
    const [isUpdatingName, setIsUpdatingName] = useState(false);

    const UpdateButton = ({
        isLoading,
        onClick,
    }: {
        isLoading?: boolean;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
    }) => (
        <Button isLoading={isLoading} onClick={onClick}>
            {t('Update')}
        </Button>
    );

    const onClickUpdateName = async () => {
        if (!isEditingName) {
            setFirstName(cognitoUser?.attributes?.givenName);
            setLastName(cognitoUser?.attributes?.familyName);
            return setIsEditingName(true);
        }

        if (!firstName) return alert('First name is required.');
        if (!lastName) return alert('Last name is required.');

        if (firstName !== cognitoUser?.attributes?.givenName || lastName !== cognitoUser?.attributes?.familyName) {
            setIsUpdatingName(true);
            await updateCognitoUserAttributes({
                given_name: firstName,
                family_name: lastName,
            });
            setIsUpdatingName(false);
        }

        setIsEditingName(false);
    };

    // const currentLanguage = supportedLngs.find(({ isoCode }) => isoCode === i18n.language);

    return (
        <VendorsLoadingLayout>
            <PageHeading title={t('Profile')} subtitle={t('Profile.Description')} />
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                <dl className="mt-6 space-y-6 -sm ">
                    <div className="pt-6 sm:flex">
                        <dt className="sm:w-64 sm:flex-none sm:pr-6">
                            <Typography isPrimary>{t('Full name')}</Typography>
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            {isEditingName ? (
                                <div className="flex flex-row gap-4">
                                    <Input
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className="flex-1 relative block w-full"
                                    />
                                    <Input
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className="flex-1 relative block w-full"
                                    />
                                </div>
                            ) : (
                                <Typography isPrimary>{`${cognitoUser?.attributes?.givenName ?? ''} ${
                                    cognitoUser?.attributes?.familyName ?? ''
                                }`}</Typography>
                            )}
                            <UpdateButton onClick={onClickUpdateName} isLoading={isUpdatingName} />
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="sm:w-64 sm:flex-none sm:pr-6">
                            <Typography isPrimary>{t('Email address')}</Typography>
                        </dt>
                        <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <Typography isPrimary>{cognitoUser?.attributes?.email}</Typography>
                            {/*<button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                            {/*    Update*/}
                            {/*</button>*/}
                        </dd>
                    </div>
                </dl>
                <div className="flex items-center justify-end">
                    <Typography className="cursor-pointer underline" onClick={signOut}>
                        Sign Out
                    </Typography>
                </div>

                {/*<div>*/}
                {/*    <h2 className="text-base font-semibold text-gray-900">{t('Language and dates')}</h2>*/}
                {/*    <p className="mt-1 text-sm text-gray-500">{t('Language and dates.Description')}</p>*/}

                {/*    <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm">*/}
                {/*        <div className="pt-6 sm:flex">*/}
                {/*            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('Language')}</dt>*/}
                {/*            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">*/}
                {/*                <div className="text-gray-900"> {currentLanguage?.label}</div>*/}
                {/*                <UpdateButton />*/}
                {/*            </dd>*/}
                {/*        </div>*/}
                {/*        <div className="pt-6 sm:flex">*/}
                {/*            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('Time Zone')}</dt>*/}
                {/*            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">*/}
                {/*                <div className="text-gray-900">Asia/Dubai</div>*/}
                {/*                <UpdateButton />*/}
                {/*            </dd>*/}
                {/*        </div>*/}
                {/*    </dl>*/}
                {/*</div>*/}
            </div>
        </VendorsLoadingLayout>
    );
};

export default SettingsPage;
