import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { VendorMediaManagerProvider, VendorMediaQuery } from '../../types/vendor-media-manager-provider.ts';
import moment from 'moment/moment';
import { useVendorManager } from '../VendorManagerProvider';
import { entries, isEmpty, isEqual, omit } from 'lodash-es';
import getVendorMediaQuery from '../../api/media/getVendorMediaQuery.graphql';
import { VendorMediaItem } from '../../types/vendor-media.ts';
import getVendorMediaUploadUrlsQuery from '../../api/media/getVendorMediaUploadUrlsQuery.graphql';
import hyphenize from '../../utils/hyphenize.ts';
import axios from 'axios';
import { toast } from 'react-toastify';

// Provider definition
const defaultContext = {} as VendorMediaManagerProvider;
const VendorMediaManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorMediaManagerContext;
const VendorMediaManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { currentVendor } = useVendorManager();
    const [query, setQuery] = useState({} as VendorMediaQuery);

    const updateMediaQuery = (config: VendorMediaQuery) => {
        if (!isEqual(config, query)) setQuery(config);
    };
    const { client, loading, data, fetchMore } = useQuery(getVendorMediaQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            query,
        },
        skip: !currentVendor?.vendorUuid || isEmpty(query),
    });
    const [mediaData, setMediaData] = useState(data?.getVendorMedia?.data);

    useEffect(() => {
        setMediaData(data?.getVendorMedia?.data);
    }, [data]);

    const media = {
        items: mediaData?.items as VendorMediaItem[],
        count: mediaData?.count,
        cursor: mediaData?.cursor,
        fetchMore: async () => {
            if (mediaData?.cursor)
                await fetchMore({
                    variables: {
                        vendorUuid: currentVendor?.vendorUuid,
                        query: {
                            cursor: mediaData?.cursor,
                            limit: query?.limit,
                            sortBy: query?.sortBy,
                            sortDirection: query?.sortDirection,
                        },
                    },
                });
        },
    };

    const [mediaUploadProgress, setMediaUploadProgress] = useState(
        [] as {
            percentUploaded?: number;
            uploadStartedAt?: string;
            uploadEndedAt?: string;
            totalUploadSeconds?: number;
        }[],
    );
    const [isUploading, setIsUploading] = useState(false);

    const refetchMediaItems = async () =>
        client.refetchQueries({
            include: [getVendorMediaQuery],
        });

    const uploadMedia = async (files: File[]) => {
        if (!files?.length) throw new Error('No files to upload.');

        setIsUploading(true);
        setMediaUploadProgress(files.map((_) => ({ percentUploaded: 0 })));
        const response = await client.query({
            query: getVendorMediaUploadUrlsQuery,
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                files: files.map((file) => ({
                    fileName: file.name,
                    contentType: file.type,
                })),
            },
            fetchPolicy: 'network-only',
        });
        const mediaUploadUrls = response?.data?.getVendorMediaUploadUrls?.data.map((mediaUploadUrl: object) =>
            hyphenize(omit(mediaUploadUrl, ['__typename', 'fields.__typename'])),
        );
        await Promise.all(
            files.map(async (file, index) => {
                const formData = new FormData();

                formData.append('Content-Type', file.type);
                entries(mediaUploadUrls[index].fields).forEach(([k, v]) => formData.append(k, v as Blob));
                formData.append('file', file); // must be the last one

                await axios.post(mediaUploadUrls[index].url, formData, {
                    onUploadProgress: (event) => {
                        if (event.total)
                            setMediaUploadProgress((prevState) =>
                                prevState.map((fileUploadProgress, uploadProgressIndex) => {
                                    if (index === uploadProgressIndex) {
                                        const percentUploaded =
                                            Math.round(
                                                (((event?.loaded || 0) * 100) / (event?.total || 1) + Number.EPSILON) *
                                                    100,
                                            ) / 100;
                                        const uploadStartedAt = fileUploadProgress?.uploadStartedAt
                                            ? fileUploadProgress?.uploadStartedAt
                                            : new Date().toISOString();
                                        const uploadEndedAt =
                                            percentUploaded === 100
                                                ? new Date().toISOString()
                                                : fileUploadProgress?.uploadEndedAt;

                                        let totalUploadSeconds = undefined;
                                        if (uploadStartedAt && uploadEndedAt)
                                            totalUploadSeconds = moment(uploadEndedAt).diff(
                                                moment(uploadStartedAt),
                                                'seconds',
                                            );
                                        return {
                                            ...fileUploadProgress,
                                            percentUploaded,
                                            uploadStartedAt,
                                            uploadEndedAt,
                                            totalUploadSeconds,
                                        };
                                    }

                                    return fileUploadProgress;
                                }),
                            );
                    },
                });

                // Give time for backend to process the images
                setTimeout(refetchMediaItems, 3000);
            }),
        );

        await setMediaUploadProgress([]);
        setIsUploading(false);
        toast.success('Media uploaded to library.');
    };

    return (
        <Provider
            value={{
                updateMediaQuery,
                isFetchingMedia: loading,
                media,
                mediaUploadProgress,
                isUploading,
                uploadMedia,
                refetchMediaItems,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorMediaManager = (config?: { initialQuery: VendorMediaQuery }) => {
    const { updateMediaQuery, ...functions } = useContext(VendorMediaManagerContext);
    useEffect(() => {
        if (config?.initialQuery) updateMediaQuery(config?.initialQuery);
    }, [config]);

    return { ...functions, updateMediaQuery };
};

export { VendorMediaManagerProvider, ConfigConsumer, useVendorMediaManager };
