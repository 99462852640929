import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import PageHeading from '../../components/PageHeading';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import MediaLibraryUploadModal from '../../components/_modals/MediaLibraryUploadModal';
import PageLoader from '../../components/PageLoader';
import ThemedSelect from '../../components/ThemedSelect';
import { sortByOptions, sortDirectionOptions } from '../../consts/media-library.ts';
import {
    VendorMediaQuerySortByOptions,
    VendorMediaQuerySortDirections,
} from '../../types/vendor-media-manager-provider.ts';
import { useVendorMediaManager } from '../../providers/VendorMediaManagerProvider';
import MediaItem from '../../components/_items/MediaItem';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const MediaLibraryPage = () => {
    const [sortDirection, setSortDirection] = useState({
        value: 'DESC',
        label: 'Descending',
    });
    const [sortBy, setSortBy] = useState({
        value: 'UPLOADED_AT',
        label: 'Uploaded At',
    });
    const { isFetchingMedia, media } = useVendorMediaManager({
        initialQuery: {
            limit: 30,
            sortBy: sortBy?.value as VendorMediaQuerySortByOptions,
            sortDirection: sortDirection?.value as VendorMediaQuerySortDirections,
        },
    });

    const [sentryRef] = useInfiniteScroll({
        loading: isFetchingMedia,
        hasNextPage: !!media?.cursor,
        onLoadMore: media?.fetchMore,
        // disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    const [isMediaLibraryUploadModalOpen, setIsMediaLibraryUploadModalOpen] = useState(false);

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Media Library"
                subtitle="Manage your media library used for assets throughout your platform."
                actions={[
                    {
                        title: 'Upload',
                        isPrimary: true,
                        leftIcon: <ArrowUpOnSquareIcon className={`h-4 w-4`} aria-hidden="true" />,
                        onClick: () => setIsMediaLibraryUploadModalOpen(true),
                    },
                ]}
            />

            <div className="mt-6 flex flex-col md:flex-row flex-wrap gap-6">
                <div className="flex flex-row items-center w-full gap-4">
                    <ThemedSelect
                        defaultValue={sortBy}
                        onChange={(e) => e && setSortBy({ value: e.value, label: e.label })}
                        options={sortByOptions?.filter((option) => option.value !== sortBy?.value)}
                        isSearchable={false}
                    />
                    <ThemedSelect
                        defaultValue={sortDirection}
                        onChange={(e) => e && setSortDirection({ value: e.value, label: e.label })}
                        options={sortDirectionOptions?.filter((option) => option.value !== sortDirection?.value)}
                        isSearchable={false}
                    />
                </div>
                <PageLoader isLoading={isFetchingMedia}>
                    <div className="flex flex-row flex-wrap gap-4">
                        {media?.items?.map((mediaItem) => <MediaItem key={mediaItem.SK} mediaItem={mediaItem} />)}
                        {(isFetchingMedia || !!media?.cursor) && <div ref={sentryRef}>Loading...</div>}
                    </div>
                </PageLoader>
            </div>

            <MediaLibraryUploadModal
                isOpen={!!isMediaLibraryUploadModalOpen}
                onClose={() => setIsMediaLibraryUploadModalOpen(false)}
            />
        </VendorsLoadingLayout>
    );
};
export default MediaLibraryPage;
