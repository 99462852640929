import { forwardRef, ComponentPropsWithRef } from 'react';
import classNames from '../../utils/classNames.ts';
import BaseCard from '../_cards/BaseCard';

const Input = forwardRef<
    HTMLInputElement,
    ComponentPropsWithRef<'input'> & {
        containerClassName?: string;
        textSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
        isDisabled?: boolean;
    }
>(({ containerClassName, className = '', textSize = 'sm', isDisabled, ...props }, ref) => {
    return (
        <BaseCard className={classNames(`!p-0`, containerClassName)}>
            <input
                ref={ref}
                autoComplete="off"
                className={classNames(
                    `w-full text-sm border-0 focus:outline-white focus:ring-0 rounded-md text-white bg-transparent ${textSize}`,
                    className,
                )}
                disabled={isDisabled}
                {...props}
            />
        </BaseCard>
    );
});

export default Input;
