import { startCase } from 'lodash-es';
import Pill from '../index.tsx';
import { VendorProductsTypes } from '../../../types/vendor-product.ts';

const TYPES_STYLES = {
    COURSE: {
        bgClass: 'bg-transparent',
        textClass: 'text-cyan-500/80',
        ringClass: 'ring-cyan-400/20',
    },
    DOWNLOADABLE: {
        bgClass: 'bg-transparent',
        textClass: 'text-rose-500/80',
        ringClass: 'ring-rose-400/20',
    },
} as { [key: string]: { bgClass: string; textClass: string; ringClass: string } };
export default ({ type }: { type?: VendorProductsTypes }) => {
    if (!type) return null;
    return <Pill {...(TYPES_STYLES?.[type] || {})}>{startCase(type?.toLowerCase())}</Pill>;
};
