import { PencilIcon } from '@heroicons/react/24/outline';
import fileSizeBytesToString from '../../../utils/fileSizeBytesToString.ts';
import moment from 'moment/moment';
import Loader from '../../Loader';
import classNames from '../../../utils/classNames.ts';
import { useMutation } from '@apollo/client';
import { useVendorManager } from '../../../providers/VendorManagerProvider';
import { useEffect, useState } from 'react';
import getVdoCipherVideoFileDownloadURLMutation from '../../../api/content/getVdoCipherVideoFileDownloadURLMutation.graphql';
import Button from '../../Button';
import { useVendorContentManager } from '../../../providers/VendorContentManagerProvider';
import MediaPickerModal from '../../_modals/MediaPickerModal';
import BaseDrawer from '../_BaseDrawer';
import ContentVideoPlayer from '../../ContentVideoPlayer';
import DetailsList, { DetailsListRow } from '../../DetailsList';
import { VendorContentItem } from '../../../types/vendor-content.ts';
import getFileNameAndExtension from '../../../utils/getFileNameAndExtension.ts';
import ContentAssetViewerModal from '../../_modals/ContentAssetViewerModal';
import Typography from '../../Typography';
import PageLoader from '../../PageLoader';
import BaseCard from '../../_cards/BaseCard';
import Input from '../../Input';

export default ({ contentId, onClose }: { contentId?: string; onClose: () => void }) => {
    const { currentVendor } = useVendorManager();
    const {
        deleteContent,
        isDeletingContent,
        updateContent,
        isUpdatingContent,
        contentItem,
        isFetchingContentItem,
        updateContentItemQuery,
    } = useVendorContentManager();
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState('');

    useEffect(() => {
        if (contentId) updateContentItemQuery({ contentId });
    }, [contentId]);

    const [getVdoCipherVideoFileDownloadURL, { loading: isFetchingDownloadURL }] = useMutation(
        getVdoCipherVideoFileDownloadURLMutation,
    );

    const onClickDownload = async () => {
        const response = await getVdoCipherVideoFileDownloadURL({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                videoId: contentItem?.vdoCipherVideoData?.id,
                fileId: contentItem?.vdoCipherVideoOriginalFile?.id,
            },
        });
        const downloadUrl = response.data?.getVdoCipherVideoFileDownloadURL?.data;
        if (downloadUrl) window.open(downloadUrl, '_blank');
    };

    const [isMediaPickerModalOpen, setIsMediaPickerModalOpen] = useState(
        null as null | {
            title: string;
            defaultImageUrl?: string;
            defaultImageDarkBackground?: boolean;
            onUpdateImage: (imageUrl: string) => boolean;
        },
    );

    const [isShowContentAssetViewer, setIsShowContentAssetViewer] = useState(
        null as null | {
            contentId: string;
        },
    );

    const onClickUpdatePosterImage = async () => {
        setIsMediaPickerModalOpen({
            title: 'Update Poster Image',
            onUpdateImage: (posterImageUrl: string) => {
                if (posterImageUrl && contentItem?.SK)
                    (async () => {
                        await updateContent([
                            {
                                SK: contentItem.SK,
                                posterImageUrl,
                            },
                        ]);
                    })();
                return true;
            },
        });
    };

    const onClickUpdateTitle = async () => {
        if (newTitle && contentItem?.SK)
            await updateContent([
                {
                    SK: contentItem.SK,
                    title: newTitle,
                },
            ]).then(() => {
                setNewTitle('');
                setIsEditingTitle(false);
            });
    };

    const rows: DetailsListRow<VendorContentItem>[] = [
        {
            label: 'Uploaded By',
            render: (contentItem) =>
                `${contentItem?.uploaderCognitoUser?.attributes?.givenName} ${contentItem?.uploaderCognitoUser?.attributes?.familyName} (${contentItem?.uploaderCognitoUser?.attributes?.email})`.trim(),
        },
        {
            label: 'Uploaded',
            render: (contentItem) => moment(contentItem?.updatedAt).format('DD MMM YYYY  hh:mm a'),
        },
        {
            label: 'Dimensions',
            isHidden:
                !contentItem?.vdoCipherVideoOriginalFile?.width || !contentItem?.vdoCipherVideoOriginalFile?.height,
            render: (contentItem) =>
                `${contentItem?.vdoCipherVideoOriginalFile?.width}x${contentItem?.vdoCipherVideoOriginalFile?.height}`,
        },
        {
            label: 'Bitrate',
            isHidden: !contentItem?.vdoCipherVideoOriginalFile?.bitrate,
            render: (contentItem) => `${contentItem?.vdoCipherVideoOriginalFile?.bitrate} kbit/s`,
        },
        {
            label: 'Video Codec',
            isHidden: !contentItem?.vdoCipherVideoOriginalFile?.videoCodec,
            render: (contentItem) => contentItem?.vdoCipherVideoOriginalFile?.videoCodec?.toUpperCase(),
        },
        {
            label: 'Audio Codec',
            isHidden: !contentItem?.vdoCipherVideoOriginalFile?.audioCodec,
            render: (contentItem) => contentItem?.vdoCipherVideoOriginalFile?.audioCodec?.toUpperCase(),
        },
    ];

    const { fileName } = getFileNameAndExtension(contentItem?.fileName || '');

    return (
        <BaseDrawer isOpen={!!contentId} onClose={onClose} className="relative z-[100] w-96">
            <PageLoader isLoading={isFetchingContentItem}>
                <div className="space-y-6">
                    {contentItem?.vdoCipherVideoData?.id && (
                        <BaseCard className="aspect-h-7 aspect-w-10 block w-full overflow-hidden !p-0">
                            <div className="flex-1 flex justify-center items-center">
                                {contentItem?.vdoCipherVideoData?.status !== 'ready' && (
                                    <>
                                        {(contentItem?.contentType?.startsWith('video/') ||
                                            contentItem?.contentType?.startsWith('audio/')) &&
                                            (contentItem?.vdoCipherVideoData?.posters?.length > 0 &&
                                            contentItem?.vdoCipherVideoData?.poster ? (
                                                <img
                                                    src={contentItem?.vdoCipherVideoData?.poster}
                                                    className="w-full flex-1 object-contain min-h-0 rounded-md overflow-hidden"
                                                />
                                            ) : (
                                                <Loader width={30} />
                                            ))}
                                    </>
                                )}
                                {contentItem?.vdoCipherVideoData?.status === 'ready' && (
                                    <ContentVideoPlayer contentItem={contentItem} />
                                )}
                            </div>
                        </BaseCard>
                    )}
                    <div className="w-full">
                        {isEditingTitle ? (
                            <div className="flex flex-row items-center gap-2 mb-2">
                                <Input
                                    type="text"
                                    placeholder={fileName}
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value as string)}
                                    containerClassName="flex-1"
                                />
                                <Button
                                    size="sm"
                                    onClick={onClickUpdateTitle}
                                    isDisabled={!newTitle || newTitle === contentItem?.fileName}
                                    isLoading={isUpdatingContent}>
                                    Update
                                </Button>
                            </div>
                        ) : (
                            <h2
                                className="text-base font-semibold text-gray-900 flex flex-row items-center gap-2 cursor-pointer"
                                onClick={() => setIsEditingTitle(true)}>
                                <span className="sr-only">Details for </span>
                                <Typography isPrimary>{contentItem?.fileName}</Typography>
                                <PencilIcon className="h-3 w-3 text-white" />
                            </h2>
                        )}
                        <div className="flex flex-row items-center justify-between">
                            {contentItem?.fileSizeBytes && (
                                <Typography className="text-sm font-medium text-gray-500">
                                    {fileSizeBytesToString(contentItem.fileSizeBytes)}
                                </Typography>
                            )}
                            {contentItem?.vdoCipherVideoData?.length !== undefined && (
                                <Typography className="text-sm font-medium text-gray-500">
                                    {moment.utc(contentItem?.vdoCipherVideoData?.length * 1000).format('HH:mm:ss')}
                                </Typography>
                            )}
                            {!(
                                contentItem?.contentType?.startsWith('audio/') ||
                                contentItem?.contentType?.startsWith('video/')
                            ) && (
                                <Typography
                                    className="underline cursor-pointer"
                                    onClick={() =>
                                        setIsShowContentAssetViewer({ contentId: contentItem?.SK as string })
                                    }>
                                    Preview
                                </Typography>
                            )}
                        </div>
                        {contentItem?.vdoCipherVideoData?.status !== undefined &&
                            contentItem?.vdoCipherVideoData?.status !== 'ready' && (
                                <div
                                    className={classNames(
                                        'inline-flex items-baseline rounded-full text-sm font-medium',
                                        {
                                            'pre-upload': 'text-red-500',
                                            queued: 'text-orange-500',
                                            processing: 'text-green-500',
                                            'encoding error': 'text-red-700',
                                        }?.[contentItem?.vdoCipherVideoData?.status?.toLowerCase()] || '',
                                    )}>
                                    {{
                                        'pre-upload': 'Uploading...',
                                        queued: 'Processing...',
                                        processing: 'Encrypting...',
                                        'encoding error': 'FAILED',
                                    }?.[contentItem?.vdoCipherVideoData?.status?.toLowerCase()] || ''}
                                </div>
                            )}
                    </div>
                    <DetailsList<VendorContentItem> rows={rows} data={contentItem as VendorContentItem} />

                    <div className="flex justify-end items-center gap-2">
                        <>
                            {!!contentItem?.vdoCipherVideoOriginalFile?.id && (
                                <Button size="xs" onClick={onClickUpdatePosterImage} isLoading={isUpdatingContent}>
                                    Update Poster Image
                                </Button>
                            )}
                            <Button size="xs" onClick={onClickDownload} isLoading={isFetchingDownloadURL}>
                                Download
                            </Button>
                            <Button
                                size="xs"
                                onClick={() => deleteContent([contentItem?.SK as string]).then(onClose)}
                                isLoading={isDeletingContent}>
                                Delete
                            </Button>
                        </>
                    </div>
                </div>
            </PageLoader>
            <MediaPickerModal
                isOpen={!!isMediaPickerModalOpen}
                onClose={() => setIsMediaPickerModalOpen(null)}
                {...isMediaPickerModalOpen}
            />

            <ContentAssetViewerModal
                isOpen={!!isShowContentAssetViewer}
                {...isShowContentAssetViewer}
                onClose={() => setIsShowContentAssetViewer(null)}
            />
        </BaseDrawer>
    );
};
