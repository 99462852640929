import { useState } from 'react';
import Button from '../../Button';
import BaseModal from '../_BaseModal';
import PageLoader from '../../PageLoader';
import { useNavigate } from 'react-router-dom';
import ThemedSelect from '../../ThemedSelect';
import { sortByOptions, sortDirectionOptions } from '../../../consts/media-library.ts';
import { VendorContentItem } from '../../../types/vendor-content.ts';
import { useVendorContentManager } from '../../../providers/VendorContentManagerProvider';
import {
    VendorContentQuerySortByOptions,
    VendorContentQuerySortDirections,
} from '../../../types/vendor-content-manager-provider.ts';
import ContentItem from '../../_items/ContentItem';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const ContentPickerModal = ({
    isOpen,
    onClose,
    title = 'Select content',
    onUpdateContentItems,
    isMultiSelectEnabled,
}: {
    isOpen?: boolean;
    onClose: () => void;
    title?: string;
    isMultiSelectEnabled?: boolean;
    onUpdateContentItems?: (contentItem: VendorContentItem[]) => boolean;
}) => {
    const navigate = useNavigate();
    const [multiSelectedContentItems, setMultiSelectedContentItems] = useState([] as VendorContentItem[]);
    const toggleMultiSelectContentItem = (contentItem: VendorContentItem) => {
        if (multiSelectedContentItems.map(({ SK }) => SK).indexOf(contentItem.SK) > -1)
            setMultiSelectedContentItems((prevState) => prevState.filter(({ SK }) => SK !== contentItem.SK));
        else setMultiSelectedContentItems((prevState) => [...prevState, contentItem]);
    };
    const [sortDirection, setSortDirection] = useState({
        value: 'DESC',
        label: 'Descending',
    });
    const [sortBy, setSortBy] = useState({
        value: 'UPLOADED_AT',
        label: 'Uploaded At',
    });
    const { isFetchingContent, contentItems } = useVendorContentManager({
        initialQuery: {
            limit: 100,
            sortBy: sortBy?.value as VendorContentQuerySortByOptions,
            sortDirection: sortDirection?.value as VendorContentQuerySortDirections,
        },
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: isFetchingContent,
        hasNextPage: !!contentItems?.cursor,
        onLoadMore: contentItems?.fetchMore,
        // disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <BaseModal
            title={title}
            subtitle="Select content from your content library by clicking on it below"
            isOpen={isOpen}
            onClose={onClose}>
            <div className="mt-4 flex flex-row items-center w-full gap-4">
                <ThemedSelect
                    defaultValue={sortBy}
                    onChange={(e) => e && setSortBy({ value: e.value, label: e.label })}
                    options={sortByOptions?.filter((option) => option.value !== sortBy?.value)}
                    isSearchable={false}
                />
                <ThemedSelect
                    defaultValue={sortDirection}
                    onChange={(e) => e && setSortDirection({ value: e.value, label: e.label })}
                    options={sortDirectionOptions?.filter((option) => option.value !== sortDirection?.value)}
                    isSearchable={false}
                />
            </div>
            <div className="mt-4 flex flex-col lg:flex-row  gap-4 md:w-[45vw]">
                <PageLoader isLoading={isFetchingContent}>
                    <div className="max-h-[60vh] overflow-y-scroll" ref={rootRef}>
                        <div className="w-full flex flex-row flex-wrap gap-4">
                            {contentItems?.items?.map((contentItem) => (
                                <ContentItem
                                    key={contentItem.SK}
                                    contentItem={contentItem}
                                    onClick={() => isMultiSelectEnabled ? toggleMultiSelectContentItem(contentItem) : setMultiSelectedContentItems([contentItem])}
                                    {...(isMultiSelectEnabled
                                        ? {
                                              isMultiSelected:
                                                  multiSelectedContentItems
                                                      .map(({ SK }) => SK)
                                                      .indexOf(contentItem.SK) > -1,
                                          }
                                        : {
                                              isSelected:
                                                  multiSelectedContentItems
                                                      .map(({ SK }) => SK)
                                                      .indexOf(contentItem.SK) > -1,
                                          })}
                                    isMultiSelectEnabled={isMultiSelectEnabled}
                                    onToggleMultiSelect={() => toggleMultiSelectContentItem(contentItem)}
                                />
                            ))}
                            {(isFetchingContent || !!contentItems?.cursor) && <div ref={sentryRef}>Loading...</div>}
                        </div>
                    </div>
                </PageLoader>
            </div>
            <div className="mt-4 flex flex-row justify-end gap-4">
                <Button onClick={() => navigate('/content-library')}>Upload New</Button>
                <Button
                    isPrimary
                    isDisabled={!multiSelectedContentItems?.length}
                    onClick={() => {
                        if (
                            onUpdateContentItems &&
                            onUpdateContentItems(multiSelectedContentItems as VendorContentItem[])
                        )
                            onClose();
                    }}>
                    Select
                </Button>
            </div>
        </BaseModal>
    );
};

export default ContentPickerModal;
