import { VendorProductItem } from '../../types/vendor-product.ts';
import { Accordion as DefaultAccordion, AccordionItem, AccordionProps } from '@szhsin/react-accordion';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, Cog8ToothIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline'; // Added the drag handle icon here
import classNames from '../../utils/classNames.ts';
import serveMediaLibraryImage from '../../utils/serveMediaLibraryImage.ts';
import StagePill from '../Pill/StagePill';
import { VendorProductStages } from '../../types/vendor-products-manager-provider.ts';
import { useVendorProductsManager } from '../../providers/VendorProductsManagerProvider';

import { closestCenter, DndContext, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Button from '../Button';
import { isEqual } from 'lodash-es';
import NewProductItemModal from '../_modals/NewProductItemModal';
import ProductItemDetailDrawer from '../_drawers/ProductItemDetailDrawer';
import Typography from '../Typography';
import { useNavigate, useParams } from 'react-router-dom';
import BaseCard from '../_cards/BaseCard';

const Accordion = (props: AccordionProps) => (
    <DefaultAccordion className="w-full rounded-lg flex flex-col gap-4 " allowMultiple {...props} />
);

const SortableAccordionItem = ({
    item,
    onUpdateStage,
    depth,
    setIsNewProductItemModalOpen,
    onClickEditProductItem,
}: {
    item: VendorProductItem;
    onUpdateStage: ({ SK, stage }: { SK: string; stage: VendorProductStages }) => Promise<void>;
    onClickEditProductItem: (productItem: VendorProductItem) => Promise<void>;
    depth: number;
    setIsNewProductItemModalOpen: Dispatch<SetStateAction<{ parentId?: string | undefined } | null>>;
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.SK as string });

    const isChildrenItemsAvailable = item?.items !== undefined && item?.items?.length > 0;

    return (
        <div
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}>
            <AccordionItem
                initialEntered={false}
                onClick={(e) => console.log(e)}
                itemKey={item.SK}
                header={({ state: { isEnter } }) => (
                    <div
                        className="w-full flex"
                        {...(item?.type === 'CONTENT_GROUP'
                            ? {}
                            : {
                                  onClick: (e) => {
                                      e.stopPropagation();
                                      onClickEditProductItem(item);
                                  },
                              })}>
                        <div
                            {...listeners}
                            {...attributes}
                            className="cursor-move flex flex-row opacity-50 my-auto text-white">
                            <EllipsisVerticalIcon className="h-6 w-6 -ml-2" />
                            <EllipsisVerticalIcon className="h-6 w-6 -ml-4" />
                        </div>

                        <div
                            className={classNames('w-full text-left flex flex-row items-center justify-between gap-4')}>
                            {/* Thumbnail Image */}
                            <div className="w-24 aspect-video rounded-sm border-card-border border ">
                                {item?.images?.thumbnail && (
                                    <img
                                        className="w-full h-full object-contain"
                                        alt={item.title}
                                        src={serveMediaLibraryImage(item?.images?.thumbnail, {
                                            width: 128,
                                            format: 'png',
                                        })}
                                    />
                                )}
                            </div>

                            <div className="flex-1">
                                <Typography>{item.title}</Typography>
                                <Typography isTertiary>
                                    {
                                        {
                                            CONTENT_GROUP: 'Section',
                                            CONTENT: 'Content',
                                        }?.[item.type as string]
                                    }
                                </Typography>
                                <div className="flex flex-row items-center gap-2">
                                    <StagePill
                                        isDropdown
                                        stage={item?.stage}
                                        onUpdateStage={(stage: VendorProductStages) =>
                                            onUpdateStage({
                                                SK: item?.SK as string,
                                                stage,
                                            })
                                        }
                                    />
                                    {item?.type === 'CONTENT_GROUP' && (
                                        <Button
                                            size="xs"
                                            leftIcon={<PlusIcon className="w-4 h-4 text-white" />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsNewProductItemModalOpen({ parentId: item.SK });
                                            }}>
                                            Add
                                        </Button>
                                    )}
                                    <Button
                                        size="xs"
                                        leftIcon={<Cog8ToothIcon className="w-4 h-4 text-white" />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onClickEditProductItem(item);
                                        }}>
                                        Edit
                                    </Button>
                                </div>
                            </div>

                            <div className="flex flex-row items-center gap-4">
                                {isChildrenItemsAvailable && (
                                    <ChevronDownIcon
                                        className={classNames(
                                            'h-6 w-5 text-white cursor-pointer ml-auto transition-transform duration-200 ease-out my-auto mr-2',
                                            isEnter && 'rotate-180',
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                buttonProps={{
                    className: ({ isEnter }) => `flex w-full text-left ${isEnter && isChildrenItemsAvailable && ''}`,
                }}
                contentProps={{
                    className: 'transition-height duration-200 ease-out',
                }}
                panelProps={{ className: 'pl-5 border-l-8 border-gray-300' }}>
                {isChildrenItemsAvailable && (
                    <div className="mt-4">
                        <SortableContext
                            items={item?.items?.map((subItem) => subItem.SK) as string[]}
                            strategy={verticalListSortingStrategy}>
                            <Accordion>
                                {item?.items?.map((subItem) => (
                                    <SortableAccordionItem
                                        key={subItem.SK}
                                        item={subItem}
                                        onUpdateStage={onUpdateStage}
                                        onClickEditProductItem={onClickEditProductItem}
                                        depth={depth + 1}
                                        setIsNewProductItemModalOpen={setIsNewProductItemModalOpen}
                                    />
                                ))}
                            </Accordion>
                        </SortableContext>
                    </div>
                )}
            </AccordionItem>
        </div>
    );
};

export default ({
    items,
    onProductItemsReordered,
}: {
    items: VendorProductItem[];
    onProductItemsReordered?: ({ reorderedItems }: { reorderedItems: VendorProductItem[] }) => void;
}) => {
    const { productId, productItemId } = useParams();
    const navigate = useNavigate();
    const { createUpdateProducts } = useVendorProductsManager();
    const [reorderedItems, setReorderedItems] = useState(items || []);
    const debounceDisplayOrderUpdateRef = useRef(null as ReturnType<typeof setTimeout> | null);
    const [isNewProductItemModalOpen, setIsNewProductItemModalOpen] = useState(null as null | { parentId?: string });
    const [selectedProductItemId, setSelectedProductItemId] = useState(productItemId);

    useEffect(() => {
        if (productItemId) setSelectedProductItemId(`${productId}#content#${productItemId}`);
        else setSelectedProductItemId(undefined);
    }, [productItemId]);

    useEffect(() => {
        setReorderedItems(items);
        if (items?.length > 0) {
            const productItem = items?.find(({ SK }) => SK === selectedProductItemId);
            if (productItem) navigate(`/digital-products/${productId}/${productItem.SK?.split('#')?.pop()}`);
        }
    }, [items]);

    useEffect(() => {
        (async () => {
            if (!isEqual(items, reorderedItems) && onProductItemsReordered) {
                if (debounceDisplayOrderUpdateRef?.current) clearTimeout(debounceDisplayOrderUpdateRef.current);

                debounceDisplayOrderUpdateRef.current = setTimeout(async () => {
                    onProductItemsReordered({ reorderedItems });
                }, 5000);
            }
        })();
    }, [reorderedItems]);

    const onUpdateStage = async ({ SK, stage }: { SK: string; stage: VendorProductStages }) => {
        if (SK)
            await createUpdateProducts({
                productItems: [
                    {
                        SK,
                        stage,
                    },
                ],
            });
    };

    const onClickEditProductItem = async (productItem: VendorProductItem) =>
        navigate(`/digital-products/${productId}/${productItem.SK?.split('#').pop()}`);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over) return; // Prevent error if no drop target found

        if (active.id !== over?.id)
            setReorderedItems((items) => {
                const findAndMove = (
                    items: VendorProductItem[],
                    activeId: UniqueIdentifier,
                    overId: UniqueIdentifier,
                ): VendorProductItem[] => {
                    const oldIndex = items.findIndex((item) => item.SK === activeId);
                    const newIndex = items.findIndex((item) => item.SK === overId);

                    if (oldIndex !== -1 && newIndex !== -1) return arrayMove(items, oldIndex, newIndex);

                    return items.map((item) => ({
                        ...item,
                        items: item.items ? findAndMove(item.items, activeId, overId) : item.items,
                    }));
                };

                return findAndMove(items, active.id, over.id);
            });
    };

    const renderedItems = useMemo(() => {
        const renderItems = ({ items, depth }: { items?: VendorProductItem[]; depth: number }) => {
            return (
                <SortableContext
                    items={items?.map((item) => item.SK) as string[]}
                    strategy={verticalListSortingStrategy}>
                    {items?.map((item) => (
                        <BaseCard>
                            <SortableAccordionItem
                                key={item.SK}
                                item={item}
                                onUpdateStage={({ SK, stage }) =>
                                    onUpdateStage({
                                        SK,
                                        stage,
                                    })
                                }
                                onClickEditProductItem={onClickEditProductItem}
                                depth={depth}
                                setIsNewProductItemModalOpen={setIsNewProductItemModalOpen}
                            />
                        </BaseCard>
                    ))}
                </SortableContext>
            );
        };

        return renderItems({ items: reorderedItems, depth: 0 });
    }, [reorderedItems]);

    return (
        <>
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <Accordion>{renderedItems}</Accordion>
            </DndContext>
            <NewProductItemModal
                isOpen={!!isNewProductItemModalOpen}
                onClose={() => setIsNewProductItemModalOpen(null)}
                parentId={isNewProductItemModalOpen?.parentId}
            />
            <ProductItemDetailDrawer
                productItemId={selectedProductItemId}
                onClose={() => navigate(`/digital-products/${productId}`)}
            />
        </>
    );
};
