// import { Oval } from 'react-loader-spinner';

const Loader = ({
    height = 60,
    width = 60,
    // color = '#FFFFFF',
}: {
    height?: number;
    width?: number;
    color?: string;
}) => {
     return <img className="object-contain" style={{ width, height }} src='/loader.gif' />;

    // return (
    //     <Oval
    //         height={height}
    //         width={width}
    //         color={color}
    //         wrapperStyle={{}}
    //         wrapperClass=""
    //         visible={true}
    //         ariaLabel="oval-loading"
    //         secondaryColor="white"
    //         strokeWidth={2}
    //         strokeWidthSecondary={2}
    //     />
    // );
}

export default Loader;
