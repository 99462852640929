import React, { ComponentPropsWithRef } from 'react';
import classNames from '../../utils/classNames.ts';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const Button = ({
    children,
    className = '',
    size = 'sm',
    to,
    leftIcon,
    rightIcon,
    isLoading,
    isPrimary,
    isSecondary,
    isDisabled,
    onClick,
    ...props
}: ComponentPropsWithRef<'button'> & {
    children: React.ReactNode;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
    to?: string;
    isLoading?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
    isDisabled?: boolean;
}) => {
    const secondaryButtonStyleClassNames = 'bg-secondary-button text-secondary-button-text ring-1 ring-inset ring-secondary-button-text';
    let buttonsStyleClassNames = secondaryButtonStyleClassNames; // default is secondary

    if(isPrimary) buttonsStyleClassNames = 'bg-primary-button text-primary-button-text';
    if(isSecondary) buttonsStyleClassNames = secondaryButtonStyleClassNames;
    if(isDisabled) buttonsStyleClassNames = '!bg-disabled-button !text-disabled-button-text';

    const ButtonComponent = () => (
        <button
            className={classNames(
                `flex items-center focus:outline-white focus:ring-0 justify-center rounded-md px-3 py-1.5 text-${size.replace(
                    'md',
                    'base',
                )}  font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 shadow-sm`,
                buttonsStyleClassNames,
                className,
            )}
            disabled={isDisabled}
            {...(!isLoading ? { onClick } : {})}
            {...props}>
            <div className={`flex flex-row items-center gap-1`}>
                {!isLoading && leftIcon}
                {isLoading && (
                    <div className='mr-1'>
                        <Oval
                            height={12}
                            width={12}
                            color={isPrimary ? '#000000' : '#FFFFFF'}
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor={isPrimary ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.4)'}
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                )}
                {children}
                {!isLoading && rightIcon}
            </div>
        </button>
    )

    if(to) return <Link to={to}><ButtonComponent /></Link>

    return <ButtonComponent />
}


export default Button;
