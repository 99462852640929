import { isArray, isObject, transform } from 'lodash-es';

const hyphenize = (obj: object) =>
  transform(obj, (acc: {
      [key: string]: object | string,
  }, value: object | string, key: string, target) => {
    const hyphenKey = isArray(target) ? key : key.replace(/_/g, '-');

    acc[hyphenKey] = isObject(value) ? hyphenize(value) : value;
  });

export default hyphenize;
