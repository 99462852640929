import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthProvider } from '../../types/auth-provider.ts';
import camelize from '../../utils/camelize.ts';
import { CustomCognitoUser } from '../../types/cognito.ts';
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from '@bugsnag/js';
import getVendorUsersPermissionsQuery from '../../api/team/getVendorUsersPermissionsQuery.graphql';
import { useApolloClient } from '@apollo/client';

const signInPasswordless = async ({ email }: { email: string }) => {
    const validEmail = email?.toLowerCase();
    try {
        const user = (await Auth.signIn(validEmail)) as CognitoUser;
        return user;
    } catch (err) {
        const error = err as {
            code: string;
        };
        console.log('Something went wrong!', error);
        if (error.code === 'UserNotFoundException') {
            const signUp = await Auth.signUp({
                username: uuidv4(),
                password: uuidv4(),
                attributes: {
                    email: validEmail,
                },
            });

            if (signUp) {
                const user = (await signInPasswordless({ email: validEmail })) as CognitoUser;
                return user;
            }
        } else if (error.code === 'NotAuthorizedException') throw new Error('Incorrect email address or password!');
    }
};

const verifySignInCode = async ({ unverifiedUser, code }: { unverifiedUser: CognitoUser; code: string }) => {
    try {
        await Auth.sendCustomChallengeAnswer(unverifiedUser, code);
        // This will throw an error if the user is not yet authenticated:
        return Auth.currentSession();
    } catch (err) {
        const error = err as {
            code: string;
        };
        console.log('Something went wrong!', error);
        if (error.code === 'NotAuthorizedException')
            throw new Error('Your code seems to have expired, please try again.');
        else throw new Error('Your code seems to be incorrect!');
    }
};

const signOut = async () => {
    await Auth.signOut();
    window.location.href = '/sign-in';
};

// Provider definition
const AuthContext = createContext({} as AuthProvider);
const { Provider, Consumer: AuthConsumer } = AuthContext;
const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [cognitoUser, setCognitoUser] = useState(null as CustomCognitoUser | null);
    const [isUpdatingCognitoUserAttribute, setIsUpdatingCognitoUserAttribute] = useState(false);
    const client = useApolloClient();

    useEffect(() => {
        if (cognitoUser)
            Bugsnag.setUser(
                cognitoUser?.attributes?.sub,
                cognitoUser?.attributes?.email,
                `${cognitoUser?.attributes?.givenName || ''} ${cognitoUser?.attributes?.familyName || ''}`.trim(),
            );
    }, [cognitoUser]);

    const refreshCognitoUser = async () => {
        try {
            const { attributes, ...user } = await Auth.currentAuthenticatedUser();

            if (user)
                setCognitoUser({
                    ...user,
                    attributes: camelize(attributes),
                });
        } catch {
            setCognitoUser(null);
        }
    };

    const updateCognitoUserAttributes = async (attributes: { [key: string]: string }) => {
        setIsUpdatingCognitoUserAttribute(true);
        if (cognitoUser) {
            await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), attributes);
            await refreshCognitoUser();
            await  client.refetchQueries({
                include: [getVendorUsersPermissionsQuery],
            });
        }

        setIsUpdatingCognitoUserAttribute(false);
    };

    useEffect(() => {
        refreshCognitoUser(); // On mount

        // On auth events
        Hub.listen('auth', async (data) => {
            switch (data.payload.event) {
                case 'signIn':
                case 'signUp':
                case 'tokenRefresh':
                    await refreshCognitoUser();
                    break;
                case 'signOut':
                    setCognitoUser(null);
                    break;
                // case 'signIn_failure':
                //     console.log('user sign in failed');
                //     break;
                // case 'configured':
                //     console.log('the Auth module is configured');
            }
        });
    }, []);
    return (
        <Provider
            value={{
                signInPasswordless,
                verifySignInCode,
                signOut,
                cognitoUser,
                updateCognitoUserAttributes,
                isUpdatingCognitoUserAttribute,
                refreshCognitoUser,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useAuth = () => {
    const functions = useContext(AuthContext);

    return functions;
};

export { AuthProvider, AuthConsumer, useAuth, signOut };
