import { VendorContentItem } from '../../../types/vendor-content.ts';
import { DocumentIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import serveMediaLibraryImage from '../../../utils/serveMediaLibraryImage.ts';
import Loader from '../../Loader';
import fileSizeBytesToString from '../../../utils/fileSizeBytesToString.ts';
import classNames from '../../../utils/classNames.ts';
import { useVendorContentManager } from '../../../providers/VendorContentManagerProvider';
import { HTMLAttributes, ReactNode } from 'react';
import BaseCard from '../../_cards/BaseCard';
import Typography from '../../Typography';

export default ({
    contentItem,
    isSelected,
    isMultiSelectEnabled,
    isMultiSelected,
    onToggleMultiSelect,
    thumbnailOverrideComponent,
    className,
    ...props
}: {
    contentItem: VendorContentItem;
    isSelected?: boolean;
    isMultiSelectEnabled?: boolean;
    isMultiSelected?: boolean;
    onToggleMultiSelect?: () => void;
    thumbnailOverrideComponent?: ReactNode | ReactNode[];
} & HTMLAttributes<HTMLDivElement>) => {
    const { fileName, fileSizeBytes, vdoCipherVideoData, contentType, s3UploadParams, vdoCipherUploadParams } =
        contentItem;
    const { contentUploadProgress } = useVendorContentManager();

    const contentItemUploadProgress = contentUploadProgress.find(
        ({ fileS3Key }) => fileS3Key === (vdoCipherUploadParams?.key || s3UploadParams?.key),
    );

    let contentTypeLabel = '';
    if (contentType?.startsWith('video/')) contentTypeLabel = 'Video';
    else if (contentType?.startsWith('audio/')) contentTypeLabel = 'Audio';
    else if (contentType === 'application/pdf') contentTypeLabel = 'PDF';
    else if (contentType === 'application/msword') contentTypeLabel = 'DOC';
    else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        contentTypeLabel = 'DOCX';

    // : contentType?.toLowerCase()?.split('/')?.[1]?.toUpperCase();
    return (
        <BaseCard className={classNames('w-32 flex flex-col cursor-pointer gap-2 relative', isSelected && 'border-white', className)} {...props}>
            {isMultiSelectEnabled && (
                <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 absolute top-1 right-1"
                    checked={isMultiSelected}
                    onChange={onToggleMultiSelect}
                    onClick={(e) => e.stopPropagation()}
                />
            )}

            <div className="flex-1 flex justify-center items-center">
                {thumbnailOverrideComponent ? (
                    thumbnailOverrideComponent
                ) : (
                    <>
                        {contentType?.startsWith('audio/') && (
                            <SpeakerWaveIcon className={`h-88 w-8 text-white`} aria-hidden="true" />
                        )}
                        {[
                            'application/pdf',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        ].indexOf(contentType) > -1 && (
                            <DocumentIcon className={`h-88 w-8 text-white`} aria-hidden="true" />
                        )}
                        {contentType?.startsWith('video/') &&
                            (vdoCipherVideoData?.posters?.length > 0 ? (
                                <img
                                    src={serveMediaLibraryImage(vdoCipherVideoData.poster, {
                                        width: 12,
                                        format: 'png',
                                        // greyscale: true,
                                    })}
                                    style={{
                                        height: 128 / 1.77777778,
                                    }}
                                    className="w-full flex-1 object-contain min-h-0 rounded-md overflow-hidden"
                                />
                            ) : (
                                <Loader width={30} />
                            ))}
                    </>
                )}
            </div>

            <div className="w-full flex flex-col items-center justify-center text-center">
                {contentItemUploadProgress?.percentUploaded !== undefined &&
                contentItemUploadProgress?.percentUploaded < 100 ? (
                    <div className="flex flex-col items-center justify-center">
                        <Typography isTertiary>Uploading...</Typography>
                        <Typography isTertiary>{`${contentItemUploadProgress?.percentUploaded}%`}</Typography>
                    </div>
                ) : (
                    <Typography isTertiary>
                        {vdoCipherVideoData?.status?.toLowerCase() === 'pre-upload'
                            ? contentItemUploadProgress?.percentUploaded === 100
                                ? 'Finishing upload...'
                                : 'Preparing upload...'
                            : {
                                  queued: 'Processing...',
                                  started: 'Uploading...',
                                  processing: 'Encrypting...',
                                  'encoding error': 'FAILED',
                              }?.[vdoCipherVideoData?.status?.toLowerCase() || s3UploadParams?.status?.toLowerCase()] ||
                              ''}
                    </Typography>
                )}
            </div>

            <div className="text-xs text-center">
                <Typography isTertiary className="overflow-ellipsis whitespace-nowrap overflow-hidden">
                    {fileName}
                </Typography>
                <Typography isTertiary className="italic">
                    {fileSizeBytesToString(fileSizeBytes)}
                </Typography>
                <Typography
                    isTertiary
                    className={classNames('mt-2 font-bold text-white table mx-auto px-1 rounded border px-1')}>
                    {contentTypeLabel}
                </Typography>
            </div>
        </BaseCard>
    );
};
