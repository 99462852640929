import { ConfigType } from '../types/config.ts';
import env from '../../env.ts';

const { hostname: hostName } = window?.location;
const hostNameArray = hostName?.split('.');
const domainName = hostNameArray?.slice(Math.max(hostNameArray.length - 2, 1)).join('.');

export const staging = {
    appsync: {
        url: 'https://iet2q2fxk5ffrnilzgpdxxojye.appsync-api.eu-west-1.amazonaws.com/graphql',
        region: 'eu-west-1',
        type: 'AWS_IAM',
    },
    cognito: {
        identityPoolId: 'eu-west-1:4936b8f4-66cd-4175-84e5-7a983eb47180',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_3sxDScGLH',
        userPoolWebClientId: '4jgkebm1mh2v2r8vq05f4q21b3',
        mandatorySignIn: false,
        // cookieStorage: {
        //     domain: hostName === 'localhost' ? 'localhost' : `.${domainName}`,
        //     secure: hostName !== 'localhost',
        // },
    }
};

export const production = {
    appsync: {
        url: 'https://jwpit5fbmzeofoh3iwe7vopuqu.appsync-api.eu-west-1.amazonaws.com/graphql',
        region: 'eu-west-1',
        type: 'AWS_IAM',
    },
    cognito: {
        identityPoolId: 'eu-west-1:8429ef9e-4f57-45f6-8354-4ec9b80a5fb9',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_lVdmqTrRz',
        userPoolWebClientId: '52veqmv4vo352fqmsevpr7vjk3',
        mandatorySignIn: false,
        cookieStorage: {
            domain: hostName === 'localhost' ? 'localhost' : `.${domainName}`,
            secure: hostName !== 'localhost',
        },
    }
};

export default {
    production,
    staging,
    // For now development is pointing to staging
    // but this can be pointed to an alternative config in the future
    development: staging,
}[env] as ConfigType;
