import PageHeading from '../../components/PageHeading';
import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import PageLoader from '../../components/PageLoader';
import { startCase } from 'lodash-es';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import ThemedSelect from '../../components/ThemedSelect';
import { sortByOptions, sortDirectionOptions } from '../../consts/users.ts';
import { useVendorUsersManager } from '../../providers/VendorUsersManagerProvider';
import {
    VendorsUsersQuerySortByOptions,
    VendorsUsersQuerySortDirections,
} from '../../types/vendor-users-manager-provider.ts';
import DataTable, { DataTableColumn } from '../../components/DataTable';
import { VendorUserItem } from '../../types/vendor-user.ts';
import NewUserModal from '../../components/_modals/NewUserModal';
import UserDetailDrawer from '../../components/_drawers/UserDetailDrawer';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Input';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import BaseCard from '../../components/_cards/BaseCard';
import Typography from '../../components/Typography';

const UsersPage = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { userId } = useParams();
    const isSearchEnabled = search.trim().length >= 3;

    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(userId);
    useEffect(() => {
        setSelectedUserId(userId);
    }, [userId]);

    const [sortDirection, setSortDirection] = useState({
        value: 'DESC',
        label: 'Descending',
    });
    const [sortBy, setSortBy] = useState({
        value: 'CREATED_AT',
        label: 'Created At',
    });
    const { isFetchingUsers, users, refetchUserItems } = useVendorUsersManager({
        initialQuery: {
            limit: 20,
            sortBy: sortBy?.value as VendorsUsersQuerySortByOptions,
            sortDirection: sortDirection?.value as VendorsUsersQuerySortDirections,
            ...(isSearchEnabled && { search }),
        },
    });

    const onNewUserCreated = (user: VendorUserItem) => {
        setIsNewUserModalOpen(false);
        setSelectedUserId(user?.cognitoSub as string);
    };

    const columns: DataTableColumn<VendorUserItem>[] = [
        {
            label: 'Name',
            render: (user) => (
                <div className="flex flex-row items-center gap-2">
                    <div className="h-8 w-8">
                        {user?.attributes?.picture ? (
                            <img src={user.attributes.picture} className="h-full w-full rounded-full" />
                        ) : (
                            <div />
                        )}
                    </div>
                    <Typography>{`${user?.attributes?.givenName || ''} ${user?.attributes?.familyName || ''}`.trim()}</Typography>
                </div>
            ),
        },
        { label: 'Email', accessor: 'attributes.email' },
        {
            label: 'Community Memberships',
            render: (user) => (
                <div className="flex flex-col gap-2">
                    {user?.communityMemberships?.map((membership) => {
                        const communityType = membership.SK.split('#')[0];
                        const communityUsername = {
                            discord: membership?.user?.username,
                            slack: membership?.user?.name,
                        }?.[communityType as string];
                        return (
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex flex-row items-center gap-2">
                                    <img src={`/community-icons/${communityType}.svg`} className="h-6 w-6" />
                                    <Typography>{startCase(communityType)}</Typography>
                                </div>
                                <Typography>{`@${communityUsername}`}</Typography>
                            </div>
                        );
                    })}
                </div>
            ),
        },
        { label: 'Created', render: (user) => moment(user?.createdAt).format('DD MMM YYYY  hh:mm a') },
    ];

    const [sentryRef] = useInfiniteScroll({
        loading: isFetchingUsers,
        hasNextPage: !!users?.cursor,
        onLoadMore: users?.fetchMore,
        // disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Users"
                subtitle="Manage your customers."
                actions={[
                    {
                        title: 'Create User',
                        isPrimary: true,
                        onClick: () => setIsNewUserModalOpen(true),
                    },
                ]}
            />
            <div className="mt-6 flex flex-col md:flex-row flex-wrap gap-6">
                <div className="flex flex-row items-center w-full gap-4">
                    <ThemedSelect
                        defaultValue={sortBy}
                        onChange={(e) => e && setSortBy({ value: e.value, label: e.label })}
                        options={sortByOptions?.filter((option) => option.value !== sortBy?.value)}
                        isSearchable={false}
                    />
                    <ThemedSelect
                        defaultValue={sortDirection}
                        onChange={(e) => e && setSortDirection({ value: e.value, label: e.label })}
                        options={sortDirectionOptions?.filter((option) => option.value !== sortDirection?.value)}
                    />
                    <Input
                        type="text"
                        placeholder="Search by name, email, or community username..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value as string)}
                        containerClassName="ml-auto w-96"
                    />
                </div>
            </div>

            <PageLoader isLoading={isFetchingUsers}>
                <BaseCard className='mt-6'>
                    <DataTable<VendorUserItem>
                        columns={columns}
                        data={users?.items as VendorUserItem[]}
                        getRowKey={(row) => row.cognitoSub}
                        onRowClick={({ data: user }) => navigate(`/users/${user.cognitoSub}`)}
                    />
                    {(isFetchingUsers || !!users?.cursor) && <div ref={sentryRef}>Loading...</div>}
                </BaseCard>
            </PageLoader>

            <NewUserModal
                isOpen={isNewUserModalOpen}
                onClose={() => {
                    (async () => {
                        setIsNewUserModalOpen(false);
                        await refetchUserItems();
                    })();
                }}
                onNewUserCreated={onNewUserCreated}
            />

            <UserDetailDrawer
                userId={selectedUserId}
                onClose={() => {
                    (async () => {
                        navigate('/users');
                        await refetchUserItems();
                    })();
                }}
            />
        </VendorsLoadingLayout>
    );
};
export default UsersPage;
