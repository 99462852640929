import BaseDrawer from '../_BaseDrawer';
import { useEffect, useState } from 'react';
import ConfirmationModal from '../../_modals/ConfirmationModal';
import { useVendorUsersManager } from '../../../providers/VendorUsersManagerProvider';
import DetailsList, { DetailsListRow } from '../../DetailsList';
import { VendorUserItem, VendorUserItemUpdates } from '../../../types/vendor-user.ts';
import moment from 'moment';
import Button from '../../Button';
import { useVendorCommunitiesManager } from '../../../providers/VendorCommunitiesManagerProvider';
import PageLoader from '../../PageLoader';
import { isEmpty } from 'lodash-es';
import MediaPickerModal from '../../_modals/MediaPickerModal';
import Typography from '../../Typography';
import BaseCard from '../../_cards/BaseCard';
import { PencilIcon } from '@heroicons/react/24/outline';

export default ({ userId, onClose }: { userId?: string; onClose: () => void }) => {
    const {
        userItem,
        isFetchingUserItem,
        updateUserItemQuery,
        updateUser,
        isUpdatingUser,
        deleteUser,
        isDeletingUser,
    } = useVendorUsersManager();
    const { communities } = useVendorCommunitiesManager();
    const [userUpdates, setUserUpdates] = useState({} as VendorUserItemUpdates);
    const [isMediaPickerModalOpen, setIsMediaPickerModalOpen] = useState(
        null as null | {
            title: string;
            defaultImageUrl?: string;
            defaultImageDarkBackground?: boolean;
            onUpdateImage: (imageUrl: string) => boolean;
        },
    );

    const addUserUpdates = (changes: VendorUserItemUpdates) =>
        setUserUpdates(
            (prev) =>
                Object.fromEntries(
                    Object.entries({
                        ...prev,
                        ...changes,
                    }).filter(([, value]) => Boolean(value)),
                ) as VendorUserItemUpdates,
        );

    useEffect(() => {
        if (userId) updateUserItemQuery({ userId });
    }, [userId]);

    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );

    const rows: DetailsListRow<VendorUserItem>[] = [
        {
            label: 'Created',
            render: (userItem) => moment(userItem.createdAt).format('DD MMM YYYY  hh:mm a'),
        },
        {
            label: 'First Name',
            accessor: 'attributes.givenName',
            isEditable: true,
            onEdit: (newValue) => {
                addUserUpdates({ givenName: newValue });
            },
        },
        {
            label: 'Last Name',
            accessor: 'attributes.familyName',
            isEditable: true,
            onEdit: (newValue) => {
                addUserUpdates({ familyName: newValue });
            },
        },
        {
            label: 'Email',
            accessor: 'attributes.email',
        },
        ...(userItem?.communityMemberships?.map((membership) => {
            const community = communities?.find(({ SK }) => membership.SK.startsWith(SK));
            const communityType = community?.SK?.split('#')[0];
            const communityName = {
                discord: community?.discordGuild?.name,
                slack: community?.slackWorkspace?.team?.name,
            }?.[communityType as string];
            const communityUsername = {
                discord: membership?.user?.username,
                slack: membership?.user?.name,
            }?.[communityType as string];
            return {
                label: `${communityName} | Community Username`,
                render: () => (
                    <div className="mt-1 flex flex-row items-center gap-2">
                        <img
                            alt=""
                            src={`/community-icons/${communityType}.svg`}
                            className="h-5 w-5 flex-shrink-0 rounded-full bg-gray-300"
                        />
                        <div>{`@${communityUsername}`}</div>
                    </div>
                ),
            };
        }) || []),
    ];

    const onClickUpdate = async () => {
        if (userItem?.cognitoSub && !isEmpty(userUpdates) && !isUpdatingUser)
            await updateUser({
                user: {
                    cognitoSub: userItem?.cognitoSub as string,
                    ...userUpdates,
                },
            }).then(() => setUserUpdates({}));
    };

    const onClickDelete = async () => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete the user "${userItem?.attributes?.email}"?`,
            onConfirm: async () => {
                if (userItem?.cognitoSub)
                    await deleteUser({
                        userId: userItem?.cognitoSub as string,
                    }).then(onClose);
            },
        });
    };

    const onClickUpdatePicture = async () => {
        setIsMediaPickerModalOpen({
            title: 'Update Profile Picture',
            defaultImageUrl: userItem?.attributes?.picture,
            onUpdateImage: (picture: string) => {
                if (picture && userItem?.cognitoSub) addUserUpdates({ picture });
                return true;
            },
        });
    };

    const profileImageUrl = userUpdates?.picture || userItem?.attributes?.picture;

    return (
        <BaseDrawer isOpen={!!userId} onClose={onClose} className="relative z-[100] w-96">
            <PageLoader isLoading={isFetchingUserItem}>
                <div className="space-y-6">
                    <div className="flex flex-row items-center gap-2">
                        <BaseCard
                            className="relative !rounded-full !p-0 h-16 w-16 cursor-pointer"
                            onClick={onClickUpdatePicture}>
                            {!!profileImageUrl && (
                                <img
                                    alt=""
                                    src={profileImageUrl}
                                    className="h-full w-full flex-shrink-0 rounded-full cursor-pointer"
                                />
                            )}

                            <div className="flex items-center justify-center h-full w-full absolute top-0 right-0 bottom-0 left-0 z-20">
                                <PencilIcon className="h-5 w-5 text-white" />
                            </div>
                        </BaseCard>
                        <div>
                            <Typography isPrimary>
                                {`${userItem?.attributes?.givenName ?? ''} ${
                                    userItem?.attributes?.familyName ?? ''
                                }`.trim()}
                            </Typography>
                        </div>
                    </div>

                    <DetailsList<VendorUserItem> rows={rows} data={userItem as VendorUserItem} />

                    <div className="flex justify-end items-center gap-2">
                        {!!userItem?.cognitoSub && (
                            <>
                                <Button
                                    isPrimary
                                    size="xs"
                                    isDisabled={isEmpty(userUpdates) || !userItem?.cognitoSub || isUpdatingUser}
                                    onClick={onClickUpdate}
                                    isLoading={isUpdatingUser}>
                                    Update
                                </Button>
                                <Button
                                    size="xs"
                                    isDisabled={!userItem?.cognitoSub || isDeletingUser}
                                    onClick={onClickDelete}
                                    isLoading={isDeletingUser}>
                                    Delete
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <ConfirmationModal
                    isOpen={!!isShowConfirmationModal}
                    onClose={() => setIsShowConfirmationModal(null)}
                    {...isShowConfirmationModal}
                />

                <MediaPickerModal
                    isOpen={!!isMediaPickerModalOpen}
                    onClose={() => setIsMediaPickerModalOpen(null)}
                    {...isMediaPickerModalOpen}
                />
            </PageLoader>
        </BaseDrawer>
    );
};
