import { Auth } from 'aws-amplify';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider as ApolloClientProvider,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { createAppSyncLink } from '@genflow/aws-appsync-apollo-link';
import environmentConfig from '../../environment-config';
import { ReactNode } from 'react';

const AppSyncConfig = environmentConfig.appsync;

const config = {
    url: AppSyncConfig.url,
    region: AppSyncConfig.region,
};

// Better performance over reversing then uniqBy and the reversing again or using an object instead of a map
const getLatestUniqueItems = <T extends Record<string, any>>({ items, idKey }: { items: T[]; idKey: keyof T }) => {
    return [
        ...items
            .reduce((map, item) => {
                map.set(item[idKey], item);
                return map;
            }, new Map())
            .values(),
    ];
};

const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    getVendorMedia: {
                        keyArgs: ['vendorUuid', 'query', ['limit', 'sortBy', 'sortDirection']],
                        merge: (existing, incoming) => {
                            if (!existing) return incoming;
                            const uniqueItems = getLatestUniqueItems({
                                items: [...existing.data.items, ...incoming.data.items],
                                idKey: 'SK',
                            });
                            return {
                                ...existing,
                                data: {
                                    ...existing.data,
                                    count: uniqueItems?.length,
                                    cursor: incoming.data.cursor,
                                    items: uniqueItems,
                                },
                            };
                        },
                    },
                    getVendorContent: {
                        keyArgs: ['vendorUuid', 'query', ['limit', 'sortBy', 'sortDirection']],
                        merge: (existing, incoming) => {
                            if (!existing) return incoming;
                            const uniqueItems = getLatestUniqueItems({
                                items: [...existing.data.items, ...incoming.data.items],
                                idKey: 'SK',
                            });
                            return {
                                ...existing,
                                data: {
                                    ...existing.data,
                                    count: uniqueItems?.length,
                                    cursor: incoming.data.cursor,
                                    items: uniqueItems,
                                },
                            };
                        },
                    },
                    getVendorProducts: {
                        keyArgs: ['vendorUuid', 'query', ['stage', 'limit', 'sortBy', 'sortDirection']],
                        merge: (existing, incoming) => {
                            if (!existing) return incoming;
                            const uniqueItems = getLatestUniqueItems({
                                items: [...existing.data.items, ...incoming.data.items],
                                idKey: 'SK',
                            });
                            return {
                                ...existing,
                                data: {
                                    ...existing.data,
                                    count: uniqueItems?.length,
                                    cursor: incoming.data.cursor,
                                    items: uniqueItems,
                                },
                            };
                        },
                    },
                    getVendorUsers: {
                        keyArgs: ['vendorUuid', 'query', ['search', 'limit', 'sortBy', 'sortDirection']],
                        merge: (existing, incoming) => {
                            if (!existing) return incoming;
                            const uniqueItems = getLatestUniqueItems({
                                items: [...existing.data.items, ...incoming.data.items],
                                idKey: 'cognitoSub',
                            });
                            return {
                                ...existing,
                                data: {
                                    ...existing.data,
                                    count: uniqueItems?.length,
                                    cursor: incoming.data.cursor,
                                    items: uniqueItems,
                                },
                            };
                        },
                    },
                },
            },
        },
    }),
    link: ApolloLink.from([
        createAppSyncLink(Auth, config),
        new HttpLink({
            uri: config.url,
        }),
    ]),
});

const ApolloProvider = ({ children }: { children: ReactNode }) => (
    <ApolloClientProvider client={apolloClient}>{children}</ApolloClientProvider>
);

export default ApolloProvider;
