import { Link, Outlet, useLocation } from 'react-router-dom';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
    AdjustmentsVerticalIcon,
    AtSymbolIcon,
    Bars3Icon,
    BookOpenIcon,
    BoltIcon,
    BuildingOffice2Icon,
    BuildingStorefrontIcon,
    ChatBubbleLeftEllipsisIcon,
    ChevronRightIcon,
    ChevronUpDownIcon,
    CurrencyDollarIcon,
    GlobeAltIcon,
    HomeIcon,
    InboxArrowDownIcon,
    LockClosedIcon,
    PhotoIcon,
    SparklesIcon,
    UserCircleIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import classNames from '../../utils/classNames.ts';
import { NavigationType } from '../../types/navigation.ts';
import { useAuth } from '../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useVendorManager } from '../../providers/VendorManagerProvider';
import Loader from '../Loader';
import { useVendorDomainsManager } from '../../providers/VendorDomainsManagerProvider';
import { orderBy } from 'lodash-es';
import Typography from '../Typography';
import BaseCard from '../_cards/BaseCard';

// Handles local link, external link and on click navigation methods
const CustomLink = ({
    to,
    href,
    onClick,
    className,
    children,
    style,
}: Omit<NavigationType, 'name'> & React.HTMLProps<HTMLAnchorElement>) => {
    if (to)
        return (
            <Link to={to as string} className={className} style={style}>
                {children}
            </Link>
        );

    if (href)
        return (
            <a href={href} className={className} target="_blank" style={style}>
                {children}
            </a>
        );

    return (
        <a onClick={onClick} className={classNames(className ?? '', 'cursor-pointer')} style={style}>
            {children}
        </a>
    );
};
const PabloLogo = ({ className }: { className?: string }) => (
    <Link className={classNames('flex justify-center items-center p-2', className)} to="https://app.pablo.ai">
        <img className="w-32" src="/pablo-logo.svg" alt="Genflow" />
    </Link>
);

const DashboardLayout = () => {
    const { t } = useTranslation();
    const { pathname: pathName } = useLocation();
    const { cognitoUser } = useAuth();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { vendors, selectVendor, currentVendor, fetchingVendor } = useVendorManager();
    const { domains } = useVendorDomainsManager();
    const nonCustomPlatformDomain = domains?.find(
        ({ isCustomHostname, type }) => !isCustomHostname && type === 'PLATFORM',
    );
    const customPlatformDomain = orderBy(domains, ['createdAt'], ['desc'])?.find(
        ({ isCustomHostname, type }) => isCustomHostname && type === 'PLATFORM',
    );

    useEffect(() => {
        setIsSidebarOpen(false);
    }, [pathName]);

    const navigation = [
        { name: t('Home'), to: '/', leftIcon: HomeIcon },
        { name: t('Content Library'), to: '/content-library', leftIcon: BookOpenIcon },
        { name: t('Digital Products'), to: '/digital-products', leftIcon: SparklesIcon },
        { name: t('Checkout'), to: '/checkout', leftIcon: CurrencyDollarIcon },
        { name: t('Theming'), to: '/theming', leftIcon: AdjustmentsVerticalIcon },
        { name: t('Media Library'), to: '/media-library', leftIcon: PhotoIcon },
        { name: t('Communities'), to: '/communities', leftIcon: ChatBubbleLeftEllipsisIcon },
        { name: t('Access Control'), to: '/access-control', leftIcon: PhotoIcon },
        { name: t('Landing Pages'), to: '/landing-pages', leftIcon: BuildingStorefrontIcon },
        { name: t('Users'), to: '/users', leftIcon: UsersIcon },
        { name: t('Email Management'), to: '/email-management', leftIcon: InboxArrowDownIcon },
        { name: t('Email Lists'), to: '/email-lists', leftIcon: AtSymbolIcon },
        { name: t('Domains'), to: '/domains', leftIcon: GlobeAltIcon },
        { name: t('Team'), to: '/team', leftIcon: BuildingOffice2Icon },
        { name: t('Integrations'), to: '/integrations', leftIcon: BoltIcon },
        // { name: t('Developer/Webhook'), to: '/team', leftIcon: BuildingOffice2Icon },
        // { name: t('Billing/Plans'), to: '/team', leftIcon: BuildingOffice2Icon },
    ].filter(Boolean) as NavigationType[];

    const vendorsList = [
        ...(vendors || []).map((vendor) => ({
            ...vendor,
            onClick: () => selectVendor(vendor.vendorUuid),
            ...(currentVendor?.vendorUuid !== vendor.vendorUuid ? { rightIcon: ChevronRightIcon } : {}),
        })),
    ];

    const UserMenu = () => (
        <Link to="/settings" className="flex items-center">
            {cognitoUser?.attributes?.picture ? (
                <img
                    className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                    src={cognitoUser?.attributes?.picture}
                    alt="Profile Picture"
                />
            ) : (
                <UserCircleIcon className="h-8 w-8 text-white" aria-hidden="true" />
            )}

            <span className="flex-1 flex items-center">
                <div className="flex-1 flex flex-col text-left ml-4">
                    <Typography>
                        {`${cognitoUser?.attributes?.givenName ?? ''} ${cognitoUser?.attributes?.familyName ?? ''}`}
                    </Typography>
                    <Typography isTertiary>{`${cognitoUser?.attributes?.email ?? ''}`}</Typography>
                </div>
            </span>
        </Link>
    );

    const VendorPicker = () => (
        <Menu as="div">
            <Menu.Button className="w-full">
                <BaseCard className="flex flex-row items-center justify-center !p-2">
                    {fetchingVendor && <Loader width={30} height={30} color="#FFFFFF" />}
                    {!fetchingVendor && currentVendor && (
                        <div className="w-full flex items-center text-left">
                            <ChevronUpDownIcon className="h-5 text-white" aria-hidden="true" />
                            <div className="flex-1">
                                <div className="flex-1 flex items-center">
                                    <Typography className="text-white">{currentVendor?.brandName}</Typography>
                                </div>
                                {(nonCustomPlatformDomain || customPlatformDomain) && (
                                    <Link
                                        to={`https://${
                                            customPlatformDomain?.domainName || nonCustomPlatformDomain?.domainName
                                        }`}
                                        target="_blank">
                                        <Typography isTertiary >
                                            {customPlatformDomain?.domainName || nonCustomPlatformDomain?.domainName}
                                        </Typography>
                                    </Link>
                                )}
                            </div>
                            {currentVendor?.theme?.logos?.logoDark && (
                                <div className="w-8 h-8 border rounded-full flex items-center justify-center p-1">
                                    <img
                                        className="h-full w-full object-contain"
                                        src={currentVendor?.theme?.logos?.logoDark}
                                        alt="Genflow"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </BaseCard>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="flex flex-col gap-2 absolute right-4 left-4 z-10 text-black mt-2.5 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none bg-card-background-dark">
                    {vendorsList?.map(({ onClick, ...vendor }) => (
                        <Menu.Item key={vendor?.vendorUuid || vendor?.brandName}>
                            {({ focus }) => (
                                <div
                                    onClick={onClick}
                                    className={classNames(
                                        focus ? 'bg-white' : '',
                                        'cursor-pointer flex items-center p-2',
                                    )}>
                                    {vendor?.theme?.logos?.logoDark && (
                                        <div className="w-8 h-8 border rounded-full flex items-center justify-center p-1">
                                            <img
                                                className="h-full w-full object-contain"
                                                src={currentVendor?.theme?.logos?.logoDark}
                                                alt="Genflow"
                                            />
                                        </div>
                                    )}
                                    <Typography className={classNames("flex-1", focus && '!text-black')}>{vendor.brandName}</Typography>
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );

    return (
        <div className="main">
            <Transition.Root show={isSidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setIsSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full">
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() => setIsSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>

                                {/* Static sidebar for mobile */}
                                <div
                                    className={`flex grow flex-col overflow-y-auto pb-4 bg-card-background-dark`}
                                    style={{
                                        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .7)), url("/background.png")',
                                        backgroundSize: '100% 100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundAttachment: 'fixed',
                                        borderRight: 'solid 1px rgba(255, 255, 255, 0.1)',
                                    }}>
                                    <nav className="flex flex-1 flex-col pt-4 px-4 gap-4">
                                        <PabloLogo />
                                        <VendorPicker />
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="space-y-1">
                                                    {navigation.map(
                                                        ({
                                                            to,
                                                            href,
                                                            onClick,
                                                            leftIcon: LeftIcon,
                                                            rightIcon: RightIcon,
                                                            isLocked,
                                                            ...item
                                                        }) => (
                                                            <li key={item.name}>
                                                                <CustomLink
                                                                    {...(isLocked
                                                                        ? {}
                                                                        : {
                                                                              to,
                                                                              href,
                                                                              onClick,
                                                                          })}
                                                                    className={`group flex items-center gap-x-3 rounded-md p-2 ${
                                                                        pathName === to
                                                                            ? `bg-[#ffffff1a] text-white`
                                                                            : ``
                                                                    }`}>
                                                                    {LeftIcon && (
                                                                        <LeftIcon
                                                                            className={`h-6 w-6`}
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                    <Typography
                                                                        isPrimary={pathName === to}
                                                                        className={classNames(
                                                                            'flex-1',
                                                                            pathName === to ? `text-white` : ``,
                                                                        )}>
                                                                        {item.name}
                                                                    </Typography>
                                                                    {RightIcon && (
                                                                        <RightIcon
                                                                            style={
                                                                                pathName === to
                                                                                    ? {
                                                                                          color: 'red',
                                                                                      }
                                                                                    : {}
                                                                            }
                                                                            className={'h-6 w-6'}
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                    {isLocked && (
                                                                        <LockClosedIcon
                                                                            className={'h-6 w-6'}
                                                                            aria-hidden="true"
                                                                        />
                                                                    )}
                                                                </CustomLink>
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </li>
                                            <li className="mt-auto flex flex-col gap-4">
                                                <UserMenu />
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="flex md:hidden sticky top-0 text-white  z-40 h-16 shrink-0 items-center justify-between gap-x-4 px-4 shadow-sm sm:gap-x-6">
                <div className="flex items-center gap-4 w-64">
                    <button type="button" className="-m-2.5 p-2.5 lg:hidden" onClick={() => setIsSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:flex lg:w-72 lg:flex-col h-full">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div
                    className={`flex grow flex-col overflow-y-auto pb-4`}
                    style={{
                        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .3))',
                        borderRight: 'solid 1px rgba(255, 255, 255, 0.1)',
                    }}>
                    <nav className="flex flex-1 flex-col pt-4 px-4 gap-4">
                        <PabloLogo />
                        <VendorPicker />
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="space-y-1">
                                    {navigation.map(
                                        ({
                                            to,
                                            href,
                                            onClick,
                                            leftIcon: LeftIcon,
                                            rightIcon: RightIcon,
                                            isLocked,
                                            ...item
                                        }) => (
                                            <li key={item.name}>
                                                <CustomLink
                                                    {...(isLocked
                                                        ? {}
                                                        : {
                                                              to,
                                                              href,
                                                              onClick,
                                                          })}
                                                    className={`group flex items-center gap-x-3 rounded-md p-2 ${
                                                        pathName === to ? `bg-[#ffffff1a] text-white` : ``
                                                    }`}>
                                                    {LeftIcon && (
                                                        <LeftIcon className={`h-6 w-6 text-white`} aria-hidden="true" />
                                                    )}
                                                    <Typography
                                                        isPrimary={pathName === to}
                                                        className={classNames(
                                                            'flex-1',
                                                            pathName === to ? `text-white` : ``,
                                                        )}>
                                                        {item.name}
                                                    </Typography>
                                                    {RightIcon && (
                                                        <RightIcon
                                                            className={`h-6 w-6 ${pathName === to ? `text-white` : ``}`}
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                    {isLocked && (
                                                        <LockClosedIcon className={'h-6 w-6'} aria-hidden="true" />
                                                    )}
                                                </CustomLink>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </li>

                            <li className="mt-auto flex flex-col gap-4">
                                <UserMenu />
                                {/*{customerSupport?.helpCenterUrl && (*/}
                                {/*    <div className="text-xs flex flex-col justify-end ">*/}
                                {/*        <strong>*/}
                                {/*            <a href={customerSupport?.helpCenterUrl} target="_blank">*/}
                                {/*                Contact Support*/}
                                {/*            </a>*/}
                                {/*        </strong>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="lg:pl-72">
                <main className="py-10">
                    <div className="mx-auto max-w-6xl px-6 lg:px-8">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
