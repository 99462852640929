export const sortByOptions = [
    {
        value: 'FIRST_NAME',
        label: 'First Name',
    },
    {
        value: 'LAST_NAME',
        label: 'Last Name',
    },
    {
        value: 'EMAIL',
        label: 'Email',
    },
    {
        value: 'CREATED_AT',
        label: 'Created At',
    },
];
export const sortDirectionOptions = [
    {
        value: 'DESC',
        label: 'Descending',
    },
    {
        value: 'ASC',
        label: 'Ascending',
    },
];
