import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import Button from '../../Button';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import { isEqual, isFinite, parseInt } from 'lodash-es';
import BaseModal from '../_BaseModal';
import Typography from '../../Typography';
import Input from '../../Input';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from '../../../utils/classNames.ts';
import AnglePicker from '../../AnglePicker';
import isValidHex from '../../../utils/isValidHex.ts';

type RGBA = { red?: number; green?: number; blue?: number; alpha?: number };
const ColourPickerModal = ({
    isOpen,
    onClose,
    defaultColours,
    defaultAngle,
    title = 'Select colour',
    isGradientColourOptionEnabled,
    onUpdateColours,
}: {
    isOpen?: boolean;
    onClose: () => void;
    defaultColours?: string[];
    defaultAngle?: number;
    title?: string;
    isGradientColourOptionEnabled?: boolean;
    onUpdateColours?: ({ hexColours, angle }: { hexColours: string[]; angle?: number }) => boolean;
}) => {
    const [activeColourIndex, setActiveColourIndex] = useState(0);
    const [hexColours, setHexColours] = useState<string[]>(defaultColours?.length ? defaultColours : ['#FFFFFF']);
    const [rgbaColours, setRgbaColours] = useState<RGBA[]>([{ red: 0, green: 0, blue: 0, alpha: 100 }]);
    const [gradientAngle, setGradientAngle] = useState(0);

    useEffect(() => {
        setHexColours(defaultColours?.length ? defaultColours : ['#FFFFFF']);
        setRgbaColours([{ red: 0, green: 0, blue: 0, alpha: 100 }]);
        setActiveColourIndex(0);
    }, [defaultColours]);

    useEffect(() => {
        setGradientAngle(defaultAngle || 0);
    }, [defaultAngle]);

    const onAddNewColourStop = () => {
        setHexColours((prev) => [...prev, '#FFFFFF']);
        setRgbaColours((prev) => [...prev, { red: 0, green: 0, blue: 0, alpha: 100 }]);
        setActiveColourIndex(hexColours?.length);
    };

    const onClickDeleteColourStop = (index: number) => {
        setHexColours((prev) => prev.filter((_, i) => i !== index));
        setRgbaColours((prev) => prev.filter((_, i) => i !== index));
        setActiveColourIndex(Math.max(0, activeColourIndex - 1));
    };

    useEffect(() => {
        try {
            setRgbaColours((prev) =>
                prev.map((rgba, index) => {
                    if (!isValidHex(hexColours[index])) return rgba;
                    const { alpha, ...rest } = hexRgb(hexColours[index]);
                    return { ...rest, alpha: Math.floor(alpha * 100 + 0.5) };
                }),
            );
        } catch (error) {
            console.log('Error', error);
        }
    }, [hexColours]);

    const onUpdateRGBA = (rgbaChanges: RGBA) => {
        // setRgbaColours((prev) => prev.map((rgba, i) => (i === activeColourIndex ? { ...rgba, ...rgbaChanges } : rgba)));
        const currentRGBA = { ...rgbaColours[activeColourIndex], ...rgbaChanges } as RGBA;

        setHexColours((prev) =>
            prev.map((hex, i) =>
                i === activeColourIndex
                    ? `#${rgbHex(
                          currentRGBA?.red || 0,
                          currentRGBA?.green || 0,
                          currentRGBA?.blue || 0,
                          `${isFinite(currentRGBA?.alpha as number) ? currentRGBA?.alpha : 100}%`,
                      ).toUpperCase()}`
                    : hex,
            ),
        );
    };
    
    return (
        <BaseModal
            title={title}
            subtitle="Select your colour by either dragging the colour picker or entering a specific colour below."
            isOpen={isOpen}
            onClose={onClose}>
            <div className="mt-4 flex flex-col lg:flex-row gap-4">
                <HexColorPicker
                    color={hexColours[activeColourIndex]}
                    onChange={(hex) => {
                        if (isValidHex(hex))
                            setHexColours((prev) =>
                                prev.map((color, i) => (i === activeColourIndex ? hex.toUpperCase() : color)),
                            );
                    }}
                />

                <div>
                    <div className="flex gap-2">
                        {hexColours?.map((hexColour, index) => (
                            <div className="flex flex-col justify-center items-center gap-1" key={index}>
                                <div
                                    className={classNames(
                                        'h-10 w-10 rounded-md cursor-pointer border border-gray-600',
                                        activeColourIndex == index && 'border-2 border-white',
                                    )}
                                    style={{ backgroundColor: hexColour }}
                                    onClick={() => setActiveColourIndex(index)}
                                />
                                <div className="h-4">
                                    {index > 0 && (
                                        <TrashIcon
                                            className="w-4 h-full text-white cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onClickDeleteColourStop(index);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                        {isGradientColourOptionEnabled && (
                            <div
                                className="h-10 w-10 border-2 rounded-md cursor-pointer flex justify-center items-center"
                                onClick={onAddNewColourStop}>
                                <PlusIcon className={`h-5 w-5 text-white`} aria-hidden="true" />
                            </div>
                        )}
                    </div>

                    {hexColours?.length > 1 && (
                        <div className="mt-2">
                            <Typography>Gradient</Typography>
                            <div className="mt-2 flex flex-row items-center gap-2">
                                <div className="bg-white rounded-full">
                                    <AnglePicker
                                        angle={gradientAngle}
                                        onAngleChange={(value) => setGradientAngle(value)}
                                    />
                                </div>
                                <Input
                                    type="number"
                                    placeholder="90"
                                    containerClassName="w-20"
                                    value={gradientAngle}
                                    onChange={(e) =>
                                        setGradientAngle(Math.max(0, Math.min(360, parseInt(e.target.value))))
                                    }
                                />
                                <div
                                    className="flex-1 h-9 rounded-md"
                                    style={{
                                        background: `linear-gradient(${gradientAngle}deg, ${hexColours?.join(',')})`,
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    <div className="mt-2">
                        <Typography>Hex Colour</Typography>
                        <Input
                            type="text"
                            containerClassName="mt-2"
                            placeholder="000000"
                            value={hexColours[activeColourIndex]}
                            onChange={(e) => {
                                const hexString = e.target.value.startsWith('#')
                                    ? e.target.value.toUpperCase()
                                    : `#${e.target.value.toUpperCase()}`;

                                setHexColours((prev) =>
                                    prev.map((color, i) => (i === activeColourIndex ? hexString : color)),
                                );
                            }}
                        />
                    </div>

                    <div className="mt-4">
                        <Typography>RGBA Colour</Typography>
                        <div className="mt-2 flex flex-row gap-1">
                            <Input
                                type="number"
                                name="rr"
                                className="flex-1 !rounded-r-none text-center"
                                placeholder="000"
                                min={0}
                                max={255}
                                step={1}
                                value={rgbaColours?.[activeColourIndex]?.red}
                                onChange={(e) => onUpdateRGBA({ red: parseInt(e.target.value) })}
                            />
                            <Input
                                type="number"
                                name="gg"
                                className="flex-1 text-center"
                                placeholder="000"
                                min={0}
                                max={255}
                                step={1}
                                value={rgbaColours[activeColourIndex]?.green}
                                onChange={(e) => onUpdateRGBA({ green: parseInt(e.target.value) })}
                            />
                            <Input
                                type="number"
                                name="bb"
                                className="flex-1 text-center"
                                placeholder="000"
                                min={0}
                                max={255}
                                step={1}
                                value={rgbaColours[activeColourIndex]?.blue}
                                onChange={(e) => onUpdateRGBA({ blue: parseInt(e.target.value) })}
                            />
                            <Input
                                type="number"
                                name="aa"
                                className="flex-1 text-center"
                                placeholder="100"
                                min={0}
                                max={100}
                                step={1}
                                value={rgbaColours[activeColourIndex]?.alpha}
                                onChange={(e) =>
                                    onUpdateRGBA({ alpha: Math.max(0, Math.min(100, parseInt(e.target.value))) })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 flex flex-row justify-end">
                <Button
                    isPrimary
                    isDisabled={
                        !hexColours[0] ||
                        (isEqual(defaultColours, hexColours) && isEqual(defaultAngle, gradientAngle)) ||
                        !hexColours?.every(isValidHex)
                    }
                    onClick={() => {
                        const success = onUpdateColours && onUpdateColours({ hexColours, angle: gradientAngle });
                        if (success) onClose();
                    }}>
                    Update
                </Button>
            </div>
        </BaseModal>
    );
};

export default ColourPickerModal;
