import { createContext, ReactNode, useContext } from 'react';
import environmentConfig from '../../environment-config';
import { ConfigType } from '../../types/config.ts';

// Provider definition
const defaultConfig = environmentConfig;
const ConfigContext = createContext(defaultConfig);
const { Provider, Consumer: ConfigConsumer } = ConfigContext;
const ConfigProvider = ({ children }: { children: ReactNode }) => <Provider value={defaultConfig}>{children}</Provider>;

const addDefaults = (creatorConfig: Omit<ConfigType, 'appsync' | 'cognito' | 'content'>) => {
    return {
        ...creatorConfig,
    };
};

// Quick access hook
const useConfig = () => {
    const defaultConfig = addDefaults({} as ConfigType);
    const environmentConfig = useContext(ConfigContext);
    const config = { ...environmentConfig, ...defaultConfig };

    return config as ConfigType;
};

export { ConfigProvider, ConfigConsumer, useConfig };
