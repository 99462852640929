import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from '../../utils/classNames.ts';
import { useState } from 'react';
import SignInIssuesModal from '../_modals/SignInIssuesModal';
import Typography from '../Typography';

const SignInIssuesButton = ({ className }: { className?: string }) => {
    const [isSignInIssueModalOpen, setIsSignInIssueModalOpen] = useState(false);

    return (
        <div
            className={classNames('cursor-pointer text-xs p-2 rounded-md flex-row items-center gap-4', className)}
            onClick={() => setIsSignInIssueModalOpen(true)}>
            <Typography className="flex-1 flex flex-row items-center gap-2">
                <ExclamationTriangleIcon className="h-4 w-4" /> Sign in issues?
            </Typography>

            <SignInIssuesModal isOpen={isSignInIssueModalOpen} onClose={() => setIsSignInIssueModalOpen(false)} />
        </div>
    );
};

export default SignInIssuesButton;
