import BaseModal from '../_BaseModal';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { VendorProductItemTypeOption } from '../../../types/vendor-product.ts';
import Button from '../../Button';
import { useVendorProductsManager } from '../../../providers/VendorProductsManagerProvider';
import ContentPickerModal from '../ContentPickerModal';
import { VendorContentItem } from '../../../types/vendor-content.ts';
import ContentItem from '../../_items/ContentItem';
import Typography from '../../Typography';
import { TrashIcon } from '@heroicons/react/24/outline';
import Input from '../../Input';
import BaseCard from '../../_cards/BaseCard';

const PRODUCT_ITEM_TYPES = [
    {
        id: 'CONTENT',
        title: 'Content',
        description: 'Add some content from your content library.',
        // users: '621 users',
    },
    {
        id: 'CONTENT_GROUP',
        title: 'Section',
        description: 'Create a section to group content e.g. modules, folders, etc.',
        // users: '1200 users',
    },
] as VendorProductItemTypeOption[];

const MINIMUM_PRODUCT_ITEM_TITLE_LENGTH = 3;

const ProductItem = ({
    titleInputRef,
    setIsContentPickerModalOpen,
    onUpdate,
    onDelete,
    index,
}: {
    titleInputRef: RefObject<HTMLInputElement> | null;
    setIsContentPickerModalOpen: ({
        title,
        onUpdateContentItems,
    }: {
        title: string;
        onUpdateContentItems: (contentItems: VendorContentItem[]) => boolean;
    }) => void;
    onUpdate: ({
        title,
        itemType,
        contentItem,
    }: {
        title: string;
        itemType: VendorProductItemTypeOption;
        contentItem?: VendorContentItem;
    }) => void;
    onDelete: () => void;
    index: number
}) => {
    const [title, setTitle] = useState('');
    const [selectedProductItemType, setSelectedProductItemType] = useState(PRODUCT_ITEM_TYPES[0]);
    const [selectedContentItem, setSelectedContentItem] = useState(undefined as VendorContentItem | undefined);

    const onClickSelectContent = () => {
        setIsContentPickerModalOpen({
            title: 'Select content',
            onUpdateContentItems: (contentItems) => {
                if (contentItems?.[0]?.SK) setSelectedContentItem(contentItems[0]);
                return true;
            },
        });
    };

    useEffect(() => {
        onUpdate({ title, itemType: selectedProductItemType, contentItem: selectedContentItem });
    }, [title, selectedProductItemType, selectedContentItem]);

    return (
        <div className="mt-4 flex flex-col gap-4 lg:max-w-lg">
            <div className="flex flex-row items-center gap-2 ">
                <div className="w-3/5">
                    <label htmlFor="email-address" className="sr-only">
                        Title
                    </label>
                    <Input
                        ref={titleInputRef}
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="false"
                        placeholder="Title..."
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>

                {
                    index > 0 && (
                        <TrashIcon
                            className="w-4 h-4 text-white"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete();
                            }}
                        />
                    )
                }
            </div>
            <RadioGroup
                value={selectedProductItemType?.id}
                onChange={(id) =>
                    setSelectedProductItemType(
                        PRODUCT_ITEM_TYPES.find((type) => type.id === id) as VendorProductItemTypeOption,
                    )
                }
                className="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
                {PRODUCT_ITEM_TYPES.map(({ id, title, description }) => (
                    <Radio
                        key={id}
                        value={id}
                        aria-label={title}
                        aria-description={description}
                        className="group relative cursor-pointer">
                        <BaseCard className="flex h-full">
                            <span className="flex flex-1">
                                <span className="flex flex-col">
                                    <Typography isPrimary>{title}</Typography>
                                    <Typography isTertiary>{description}</Typography>
                                    {selectedProductItemType?.id === 'CONTENT' && id === 'CONTENT' && (
                                        <Button size="xs" onClick={onClickSelectContent} className="mt-2">
                                            Select content from library
                                        </Button>
                                    )}
                                    {/*<span className="mt-6 text-sm font-medium text-gray-900">{mailingList.users}</span>*/}
                                </span>
                            </span>
                            <CheckCircleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-white [.group:not([data-checked])_&]:invisible"
                            />
                            <span
                                aria-hidden="true"
                                className="pointer-events-none absolute -inset-px rounded-md border border-transparent group-data-[focus]:border group-data-[checked]:border-white"
                            />
                        </BaseCard>

                    </Radio>
                ))}
            </RadioGroup>

            {selectedContentItem && (
                <div className="flex flex-col gap-2 items-center">
                    <Typography>Selected Content:</Typography>
                    <ContentItem contentItem={selectedContentItem} />
                </div>
            )}
        </div>
    );
};

const NewProductItemModal = ({
    isOpen,
    parentId,
    onClose,
}: {
    isOpen?: boolean;
    parentId?: string;
    onClose: () => void;
}) => {
    const titleInputRef = useRef<HTMLInputElement>(null);
    const [productItems, setProductItems] = useState([
        {
            title: '',
            itemType: PRODUCT_ITEM_TYPES[0],
            contentItem: undefined,
        },
    ] as Array<{ title: string; itemType: VendorProductItemTypeOption; contentItem?: VendorContentItem }>);
    const { createUpdateProducts, isCreatingUpdatingProducts } = useVendorProductsManager();
    const [isContentPickerModalOpen, setIsContentPickerModalOpen] = useState(
        null as null | {
            title: string;
            onUpdateContentItems: (contentItems: VendorContentItem[]) => boolean;
        },
    );

    const onResetState = () =>
        setProductItems([
            {
                title: '',
                itemType: PRODUCT_ITEM_TYPES[0],
                contentItem: undefined,
            },
        ]);

    useEffect(() => {
        if (titleInputRef?.current) titleInputRef?.current?.focus();
    }, []);

    const isCreateEnabled =
        productItems?.length > 0 &&
        productItems?.every(({ title }) => title?.length >= MINIMUM_PRODUCT_ITEM_TITLE_LENGTH) &&
        productItems?.every(({ itemType }) => !!itemType) &&
        productItems?.every(({ itemType, contentItem }) => (itemType?.id === 'CONTENT' ? !!contentItem : true));

    const onClickCreate = async () => {
        if (!isCreateEnabled) return;

        await createUpdateProducts({
            productItems: productItems.reverse().map(({ title, itemType, contentItem }, index) => ({
                title,
                type: itemType.id,
                displayOrder: -1 * (index + 1),
                ...(parentId ? { parentSK: parentId } : {}),
                ...(itemType?.id === 'CONTENT' && contentItem ? { contentSK: contentItem.SK } : {}),
            })),
        });
        onClose();
        onResetState();
    };

    const onClickAddAnotherItem = () =>
        setProductItems((prevState) => [
            ...prevState,
            {
                title: '',
                itemType: PRODUCT_ITEM_TYPES[0],
                contentItem: undefined,
            },
        ]);

    return (
        <BaseModal title="New Content" subtitle="Add new content or sections." isOpen={isOpen} onClose={onClose}>
            <div className="flex flex-col gap-4">
                {productItems?.map((_, index) => (
                    <ProductItem
                        key={index}
                        titleInputRef={index === 0 ? titleInputRef : null}
                        setIsContentPickerModalOpen={setIsContentPickerModalOpen}
                        onUpdate={({ title, itemType, contentItem }) => {
                            setProductItems(
                                productItems.map((productItem, i) =>
                                    i === index ? { ...productItem, title, itemType, contentItem } : productItem,
                                ),
                            );
                        }}
                        onDelete={() => setProductItems((prevState) => prevState.filter((_, i) => i !== index))}
                        index={index}
                    />
                ))}
            </div>
            <div className="mt-4 flex flex-row items-center justify-end gap-2 ">
                <Typography className="underline cursor-pointer" onClick={onClickAddAnotherItem}>
                    Add another item
                </Typography>
                <Button
                    isPrimary
                    isLoading={isCreatingUpdatingProducts}
                    isDisabled={!isCreateEnabled || isCreatingUpdatingProducts}
                    onClick={onClickCreate}>
                    Create
                </Button>
            </div>

            <ContentPickerModal
                isOpen={!!isContentPickerModalOpen}
                onClose={() => setIsContentPickerModalOpen(null)}
                {...isContentPickerModalOpen}
            />
        </BaseModal>
    );
};

export default NewProductItemModal;
