import { startCase } from 'lodash-es';
import Pill from '../index.tsx';

const TYPES_STYLES = {
    ACCEPTED: {
        bgClass: 'bg-transparent',
        textClass: 'text-green-300/80',
        ringClass: 'ring-green-200/20',
    },
    PENDING: {
        bgClass: 'bg-transparent',
        textClass: 'text-orange-300/80',
        ringClass: 'ring-orange-200/20',
    },
    REJECTED: {
        bgClass: 'bg-transparent',
        textClass: 'text-red-500/80',
        ringClass: 'ring-red-400/20',
    },
} as { [key: string]: { bgClass: string; textClass: string; ringClass: string } };
export default ({ status }: { status?: 'ACCEPTED' | 'REJECTED' | 'PENDING'; }) => {
    if (!status) return null;
    return <Pill {...(TYPES_STYLES?.[status] || {})}>{startCase(status?.toLowerCase())}</Pill>;
};
