import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import PageHeading, { PageHeadingAction } from '../../components/PageHeading';
import { CurrencyDollarIcon, PencilIcon, PhotoIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useVendorProductsManager } from '../../providers/VendorProductsManagerProvider';
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import Button from '../../components/Button';
import MediaPickerModal from '../../components/_modals/MediaPickerModal';
import NewProductItemModal from '../../components/_modals/NewProductItemModal';
import ConfirmationModal from '../../components/_modals/ConfirmationModal';
import { VendorContentItem } from '../../types/vendor-content.ts';
import ContentPickerModal from '../../components/_modals/ContentPickerModal';
import Input from '../../components/Input';
import ProductOutline from '../../components/ProductOutline';
import { ListBulletIcon } from '@heroicons/react/24/solid';
import ProductDesign from '../../components/ProductDesign';

const tabs = [
    { label: 'Outline', id: 'outline', icon: ListBulletIcon },
    { label: 'Design', id: 'design', icon: PhotoIcon },
    { label: 'Pricing', id: 'pricing', icon: CurrencyDollarIcon },
];
const DigitalProductDetailPage = () => {
    const { isFetchingProduct, product, updateProductQuery, createUpdateProducts, isCreatingUpdatingProducts } =
        useVendorProductsManager();
    const { productId } = useParams();

    const [selectedTabId, setSelectedTabId] = useState('outline');
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [title, setTitle] = useState(product?.title || '');

    const [isNewProductItemModalOpen, setIsNewProductItemModalOpen] = useState(false);
    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );
    const [isContentPickerModalOpen, setIsContentPickerModalOpen] = useState(
        null as null | {
            title: string;
            onUpdateContentItems: (contentItems: VendorContentItem[]) => boolean;
            isMultiSelectEnabled?: boolean;
        },
    );

    useEffect(() => {
        if (product?.title) setTitle(product.title);
    }, [product]);

    const [isMediaPickerModalOpen, setIsMediaPickerModalOpen] = useState(
        null as null | {
            title: string;
            defaultImageUrl?: string;
            defaultImageDarkBackground?: boolean;
            onUpdateImage: (imageUrl: string) => boolean;
        },
    );

    useEffect(() => {
        if (productId)
            updateProductQuery({
                productId,
            });
    }, [productId]);

    const onClickUpdateTitle = async () => {
        if (title && product?.SK) {
            await createUpdateProducts({
                products: [{ SK: product?.SK as string, title: title as string }],
            });
            setIsEditingTitle(false);
        }
    };

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title={
                    isEditingTitle ? (
                        <div className="flex flex-row items-center mb-2 w-full md:w-4/6 gap-2">
                            <Input
                                type="text"
                                placeholder={product?.title}
                                defaultValue={product?.title}
                                value={title}
                                onChange={(e) => setTitle(e.target.value as string)}
                                className="text-xl "
                                containerClassName="flex-1"
                            />
                            <Button
                                isSecondary
                                size="lg"
                                onClick={onClickUpdateTitle}
                                isDisabled={!title || title === product?.title}
                                isLoading={isCreatingUpdatingProducts}>
                                Update
                            </Button>
                        </div>
                    ) : (
                        <div
                            className="flex flex-row items-center gap-2 cursor-pointer"
                            onClick={() => setIsEditingTitle(true)}>
                            {product?.title}
                            {product?.title && <PencilIcon className="h-3 w-3" />}
                        </div>
                    )
                }
                actions={[
                    selectedTabId === 'outline' && {
                        title: 'Add content',
                        isPrimary: true,
                        leftIcon: <PlusIcon className={`h-4 w-4`} aria-hidden="true" />,
                        onClick: () => setIsNewProductItemModalOpen(true),
                    },
                ].filter(Boolean) as PageHeadingAction[]}
            />

            <PageLoader isLoading={isFetchingProduct}>
                <div>
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            defaultValue={tabs?.find((tab) => tab?.id === selectedTabId)?.label}
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                            {tabs.map((tab) => (
                                <option key={tab.id}>{tab.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav aria-label="Tabs" className="flex gap-2">
                            {tabs.map(({ icon: Icon, ...tab }) => (
                                <Button
                                    leftIcon={<Icon className="h-4 w-4" />}
                                    key={tab.id}
                                    onClick={() => setSelectedTabId(tab.id)}
                                    aria-current={tab.id === selectedTabId ? 'page' : undefined}
                                    {...(tab.id === selectedTabId ? { isPrimary: true } : { isSecondary: true })}>
                                    {tab.label}
                                </Button>
                            ))}
                        </nav>
                    </div>
                </div>

                <div className="mt-4">
                    {selectedTabId === 'outline' && (
                        <ProductOutline
                            productId={product?.SK as string}
                            setIsShowConfirmationModal={setIsShowConfirmationModal}
                            setIsContentPickerModalOpen={setIsContentPickerModalOpen}
                        />
                    )}
                    {selectedTabId === 'design' && (
                        <ProductDesign
                            productId={product?.SK as string}
                            setIsShowConfirmationModal={setIsShowConfirmationModal}
                            setIsMediaPickerModalOpen={setIsMediaPickerModalOpen}
                        />
                    )}
                </div>
            </PageLoader>

            <MediaPickerModal
                isOpen={!!isMediaPickerModalOpen}
                onClose={() => setIsMediaPickerModalOpen(null)}
                {...isMediaPickerModalOpen}
            />

            <NewProductItemModal
                isOpen={!!isNewProductItemModalOpen}
                onClose={() => setIsNewProductItemModalOpen(false)}
                parentId={product?.SK}
            />

            <ConfirmationModal
                isOpen={!!isShowConfirmationModal}
                onClose={() => setIsShowConfirmationModal(null)}
                {...isShowConfirmationModal}
            />

            <ContentPickerModal
                isOpen={!!isContentPickerModalOpen}
                onClose={() => setIsContentPickerModalOpen(null)}
                {...isContentPickerModalOpen}
            />
        </VendorsLoadingLayout>
    );
};
export default DigitalProductDetailPage;
