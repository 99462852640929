import { useEffect, useState } from 'react';
import { startCase } from 'lodash-es';
import { VendorProductStages } from '../../../types/vendor-products-manager-provider.ts';
import Loader from '../../Loader';
import classNames from '../../../utils/classNames.ts';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

// Stage styles for the Pill
const STAGE_STYLES = {
    LIVE: {
        bgClass: 'bg-transparent',
        textClass: 'text-green-500',
        ringClass: 'ring-green-400/20',
    },
    DRAFT: {
        bgClass: 'bg-transparent',
        textClass: 'text-orange-500',
        ringClass: 'ring-orange-400/20',
    },
} as { [key in VendorProductStages]: { bgClass: string; textClass: string; ringClass: string } };

// Dropdown options
const stageOptions = [
    { value: 'LIVE', label: 'Live' },
    { value: 'DRAFT', label: 'Draft' },
] as { value: VendorProductStages; label: string }[];

export default ({
    stage: propStage,
    isDropdown = false,
    onUpdateStage,
}: {
    stage?: VendorProductStages;
    isDropdown?: boolean;
    onUpdateStage?: (stage: VendorProductStages) => Promise<void>;
}) => {
    const [stage, setStage] = useState<VendorProductStages | undefined>(propStage);
    const [isUpdatingStage, setIsUpdatingStage] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (propStage !== stage) setStage(propStage);
    }, [propStage]);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const onStageChange = async (newStage: VendorProductStages) => {
        setIsDropdownOpen(false);
        if (onUpdateStage) {
            setIsUpdatingStage(true);
            await onUpdateStage(newStage);
            setIsUpdatingStage(false);
        }
    };

    return (
        <div className="relative flex items-center space-x-4">
            {!isDropdown ? (
                <div
                    className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                        STAGE_STYLES[stage as VendorProductStages]?.bgClass
                    } ${STAGE_STYLES[stage as VendorProductStages]?.textClass} ${
                        STAGE_STYLES[stage as VendorProductStages]?.ringClass
                    }`}>
                    {startCase(stage?.toLowerCase())}
                </div>
            ) : (
                <div className="relative">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown();
                        }}
                        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset focus:outline-none ${
                            STAGE_STYLES[stage as VendorProductStages]?.bgClass
                        } ${STAGE_STYLES[stage as VendorProductStages]?.textClass} ${
                            STAGE_STYLES[stage as VendorProductStages]?.ringClass
                        }`}>
                        {startCase(stage?.toLowerCase())}
                        {isUpdatingStage ? (
                            <div className="ml-1">
                                <Loader height={12} width={12} />
                            </div>
                        ) : (
                            <ChevronDownIcon
                                className={classNames(
                                    'ml-1 h-3 w-3 transition-transform  duration-200 ease-out',
                                    isDropdownOpen && 'rotate-180',
                                )}
                            />
                        )}
                    </button>

                    {isDropdownOpen && (
                        <div className="absolute left-0 mt-2 w-40 rounded-lg bg-white shadow-lg z-[100]">
                            {stageOptions.map((option) => (
                                <button
                                    key={option.value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onStageChange(option.value);
                                    }}
                                    className={`block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 ${
                                        option.value === stage ? 'font-semibold bg-gray-100' : ''
                                    }`}>
                                    {option.label}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
