import { useEffect, useState } from 'react';
import Button from '../../Button';
import BaseModal from '../_BaseModal';
import { VendorPermission, VendorPermissionRole } from '../../../types/vendor-permission.ts';
import { useVendorManager } from '../../../providers/VendorManagerProvider';
import { useMutation, useQuery } from '@apollo/client';
import getVendorUsersPermissionsRolesQuery from '../../../api/team/getVendorUsersPermissionsRolesQuery.graphql';
import createUpdateVendorUsersPermissionsMutation from '../../../api/team/createUpdateVendorUsersPermissionsMutation.graphql';
import getVendorUsersPermissionsQuery from '../../../api/team/getVendorUsersPermissionsQuery.graphql';
import deleteVendorUserPermissionsMutation from '../../../api/team/deleteVendorUserPermissionsMutation.graphql';
import ThemedSelect from '../../ThemedSelect';
import { isEqual } from 'lodash-es';
import isValidEmail from '../../../utils/isValidEmail.ts';
import classNames from '../../../utils/classNames.ts';
import Loader from '../../Loader';
import Input from '../../Input';

const ManageTeamUserModal = ({
    isOpen,
    onClose,
    vendorPermission,
}: {
    isOpen?: boolean;
    onClose: () => void;
    vendorPermission?: VendorPermission;
}) => {
    const { currentVendor } = useVendorManager();
    const [teamUser, setTeamUser] = useState(vendorPermission as undefined | VendorPermission);

    const onResetState = () => setTeamUser(undefined);

    useEffect(() => {
        setTeamUser(vendorPermission);
    }, [vendorPermission]);
    const updateTeamUser = (changes: VendorPermission) => setTeamUser({ ...teamUser, ...changes });

    const { data: rolesData, loading: rolesLoading } = useQuery(getVendorUsersPermissionsRolesQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
        },
        skip: !currentVendor?.vendorUuid,
    });

    const vendorPermissionsRoles = rolesData?.getVendorUsersPermissionsRoles?.roles as VendorPermissionRole[];
    const isNewUser = !teamUser?.cognitoUser;

    const [createUpdateVendorUsersPermissions, { loading }] = useMutation(createUpdateVendorUsersPermissionsMutation);
    const [deleteVendorUserPermissions, { loading: isDeletingUserPermission }] = useMutation(
        deleteVendorUserPermissionsMutation,
    );

    const onClickSave = async () => {
        await createUpdateVendorUsersPermissions({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                vendorPermissions: [
                    isNewUser
                        ? {
                              cognitoEmail: teamUser?.cognitoEmail,
                              roleUuid: teamUser?.roleUuid,
                          }
                        : {
                              cognitoSub: teamUser?.cognitoUser?.attributes?.sub,
                              roleUuid: teamUser?.roleUuid,
                          },
                ],
            },
            refetchQueries: [
                {
                    query: getVendorUsersPermissionsQuery,
                    variables: {
                        vendorUuid: currentVendor?.vendorUuid,
                    },
                },
            ],
            awaitRefetchQueries: true,
        });
        onClose();
        onResetState();
    };

    const onClickDelete = async () => {
        const cognitoSub = teamUser?.cognitoUser?.attributes?.sub;
        const newUserEmail = teamUser?.newUserEmail;
        if (!cognitoSub && !newUserEmail) return;

        if (
            confirm(
                `Are you sure you want to remove all access to "${currentVendor?.brandName}" for ${
                    vendorPermission?.cognitoUser?.attributes?.givenName
                        ? `${vendorPermission?.cognitoUser?.attributes?.givenName || ''} ${
                              vendorPermission?.cognitoUser?.attributes?.familyName || ''
                          }`.trim()
                        : vendorPermission?.newUserEmail
                }?`,
            )
        ) {
            await deleteVendorUserPermissions({
                variables: {
                    vendorUuid: currentVendor?.vendorUuid,
                    vendorPermissions: cognitoSub ? [{ cognitoSub }] : [{ newUserEmail }],
                },
                refetchQueries: [
                    {
                        query: getVendorUsersPermissionsQuery,
                        variables: {
                            vendorUuid: currentVendor?.vendorUuid,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            });

            onClose();
        }
    };

    return (
        <BaseModal
            title={
                isNewUser
                    ? 'Invite new team user'
                    : `${vendorPermission?.cognitoUser?.attributes?.givenName || ''} ${
                          vendorPermission?.cognitoUser?.attributes?.familyName || ''
                      }`.trim()
            }
            subtitle={isNewUser ? '' : 'Manage team user'}
            isOpen={isOpen}
            onClose={onClose}>
            <div className="mt-4 flex flex-col gap-4">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                        Email address
                    </label>
                    <Input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        disabled={!isNewUser}
                        // className="w-64"
                        placeholder="Email address"
                        value={teamUser?.cognitoUser?.attributes?.email || teamUser?.newUserEmail}
                        onChange={(e) => updateTeamUser({ cognitoEmail: e.target.value } as VendorPermission)}
                    />
                </div>
                {!rolesLoading && (
                    <ThemedSelect
                        defaultValue={
                            teamUser?.role && {
                                value: teamUser?.role.roleUuid,
                                label: teamUser?.role.roleName,
                            }
                        }
                        onChange={(e) => e && updateTeamUser({ roleUuid: e.value } as VendorPermission)}
                        options={vendorPermissionsRoles?.map((role) => ({
                            value: role.roleUuid,
                            label: role.roleName,
                        }))}
                        isSearchable={false}
                        classNames={{
                            control: () => '!w-64',
                        }}
                        placeholder="Select role..."
                    />
                )}
            </div>
            <div
                className={classNames(
                    `mt-4 flex flex-row items-center gap-4`,
                    isNewUser ? 'justify-end' : 'justify-between',
                )}>
                {!isNewUser &&
                    (isDeletingUserPermission ? (
                        <Loader width={20} height={20} />
                    ) : (
                        <div className="text-xs text-red-500 underline cursor-pointer" onClick={onClickDelete}>
                            Delete User
                        </div>
                    ))}
                <Button
                    isPrimary
                    isLoading={loading}
                    isDisabled={
                        (isNewUser && (!teamUser?.cognitoEmail || !teamUser?.roleUuid)) ||
                        (isNewUser && !isValidEmail(teamUser?.cognitoEmail)) ||
                        (!isNewUser && isEqual(teamUser, vendorPermission)) ||
                        isDeletingUserPermission
                    }
                    onClick={onClickSave}>
                    {isNewUser ? 'Invite' : 'Update'}
                </Button>
            </div>
        </BaseModal>
    );
};

export default ManageTeamUserModal;
