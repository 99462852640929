import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import getVendorDomainsQuery from '../../api/domains/getVendorDomainsQuery.graphql';
import createUpdateVendorDomainsMutation from '../../api/domains/createUpdateVendorDomainsMutation.graphql';
import deleteVendorDomainsMutation from '../../api/domains/deleteVendorDomainsMutation.graphql';
import { useVendorManager } from '../VendorManagerProvider';
import { VendorDomainsManagerProvider } from '../../types/vendor-domains-manager-provider.ts';
import { VendorDomain, VendorDomainTypes } from '../../types/vendor-domains.ts';

// Provider definition
const defaultContext = {} as VendorDomainsManagerProvider;
const VendorDomainsManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorDomainsManagerContext;
const VendorDomainsManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { currentVendor } = useVendorManager();

    const [domainsData, setDomainsData] = useState([] as VendorDomain[]);
    const [isRefetchingDomains, setIsRefetchingDomains] = useState(false);

    const {
        client,
        loading: isFetchingDomains,
        data: domains,
    } = useQuery(getVendorDomainsQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
        },
        skip: !currentVendor?.vendorUuid,
    });

    const [createUpdateVendorDomains, { loading: isCreatingUpdatingDomains }] = useMutation(
        createUpdateVendorDomainsMutation,
    );
    const [deleteVendorDomains, { loading: isDeletingDomains }] = useMutation(deleteVendorDomainsMutation);

    useEffect(() => {
        setDomainsData(domains?.getVendorDomains?.data);
    }, [domains]);

    const createUpdateDomains = async ({
        domains,
    }: {
        domains?: { domainName: string; type: VendorDomainTypes }[];
    }) => {
        const response = await createUpdateVendorDomains({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                domains,
            },
        });

        await refetchDomains();

        return response?.data?.createUpdateVendorDomains?.data;
    };

    const deleteDomains = async ({ domainNames }: { domainNames?: string[] }) => {
        const response = await deleteVendorDomains({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                domainNames,
            },
        });

        await refetchDomains();

        return response?.data?.deleteVendorDomains?.data;
    };

    const refetchDomains = async () => {
        setIsRefetchingDomains(true);
        await client.refetchQueries({
            include: [getVendorDomainsQuery],
        });
        setIsRefetchingDomains(false);
    };

    return (
        <Provider
            value={{
                isFetchingDomains,
                domains: domainsData,
                createUpdateDomains,
                isCreatingUpdatingDomains,
                deleteDomains,
                isDeletingDomains,
                refetchDomains,
                isRefetchingDomains,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorDomainsManager = () => {
    const functions = useContext(VendorDomainsManagerContext);
    return functions;
};

export { VendorDomainsManagerProvider, ConfigConsumer, useVendorDomainsManager };
