import { entries } from 'lodash-es';

export default (
    url: string,
    options?: {
        width?: number;
        height?: number;
        fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
        trim?: boolean;
        rotate?: number;
        greyscale?: boolean;
        format?:
            | 'avif'
            | 'dz'
            | 'fits'
            | 'gif'
            | 'heif'
            | 'input'
            | 'jpeg'
            | 'jpg'
            | 'jp2'
            | 'jxl'
            | 'magick'
            | 'openslide'
            | 'pdf'
            | 'png'
            | 'ppm'
            | 'raw'
            | 'svg'
            | 'tiff'
            | 'tif'
            | 'v'
            | 'webp';
        quality?: number;
    },
) => {
    const newUrl = new URL(url);
    for (const [key, value] of entries(options)) newUrl.searchParams.set(key, value as string);
    return newUrl.toString();
};
