import BaseCard from '../_cards/BaseCard';
import serveMediaLibraryImage from '../../utils/serveMediaLibraryImage.ts';
import Button from '../Button';
import { useVendorProductsManager } from '../../providers/VendorProductsManagerProvider';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { omit } from 'lodash-es';
import Typography from '../Typography';
import { VendorProductImagesFields } from '../../types/vendor-product.ts';

const imageKeyLabels = {
    cover: 'Cover Image',
    resourceCover: 'Resource Cover Image',
};
export default ({
    productId,
    setIsMediaPickerModalOpen,
    setIsShowConfirmationModal,
}: {
    productId: string;
    setIsShowConfirmationModal: Dispatch<
        SetStateAction<{
            question: string;
            onConfirm: () => Promise<void>;
        } | null>
    >;
    setIsMediaPickerModalOpen: Dispatch<
        SetStateAction<{
            title: string;
            defaultImageUrl?: string;
            defaultImageDarkBackground?: boolean;
            onUpdateImage: (imageUrl: string) => boolean;
        } | null>
    >;
}) => {
    const { product, updateProductQuery, createUpdateProducts, isCreatingUpdatingProducts } =
        useVendorProductsManager();

    useEffect(() => {
        if (productId)
            updateProductQuery({
                productId,
            });
    }, [productId]);

    const onClickUpdateCoverImage = async () => {
        setIsMediaPickerModalOpen({
            title: 'Update Cover Image',
            onUpdateImage: (coverImageUrl: string) => {
                if (coverImageUrl && product?.SK)
                    (async () => {
                        await createUpdateProducts({
                            products: [
                                {
                                    SK: product?.SK,
                                    images: {
                                        ...omit(product?.images, '__typename'),
                                        cover: coverImageUrl,
                                    },
                                },
                            ],
                        });
                    })();
                return true;
            },
        });
    };

    const onClickUpdateResourceCoverImage = async () => {
        setIsMediaPickerModalOpen({
            title: 'Update Resource Cover Image',
            onUpdateImage: (coverImageUrl: string) => {
                if (coverImageUrl && product?.SK)
                    (async () => {
                        await createUpdateProducts({
                            products: [
                                {
                                    SK: product?.SK,
                                    images: {
                                        ...omit(product?.images, '__typename'),
                                        resourceCover: coverImageUrl,
                                    },
                                },
                            ],
                        });
                    })();
                return true;
            },
        });
    };

    const onClickDeleteImage = async (imageKey: VendorProductImagesFields) => {
        if (product?.images?.[imageKey])
            setIsShowConfirmationModal({
                question: `Are you sure you want to delete the image currently set for your ${imageKeyLabels[imageKey]}?`,
                onConfirm: async () => {
                    if (product?.SK)
                        await createUpdateProducts({
                            products: [
                                {
                                    SK: product?.SK,
                                    images: {
                                        ...omit(product?.images, '__typename'),
                                        [imageKey]: null,
                                    },
                                },
                            ],
                        });
                },
            });
    };

    return (
        <div className="flex flex-row items-start gap-4 ">
            {(['cover', 'resourceCover'] as VendorProductImagesFields[]).map((imageKey) => (
                <BaseCard className="w-64 flex flex-col items-end" key={imageKey}>
                    {product?.images?.[imageKey] && (
                        <div>
                            <img
                                className="w-full object-contain"
                                alt={product?.title}
                                src={serveMediaLibraryImage(product?.images?.[imageKey] as string, {
                                    width: 512,
                                    format: 'png',
                                })}
                            />
                        </div>
                    )}
                    <Button
                        size="xs"
                        className="mt-4"
                        onClick={
                            {
                                cover: onClickUpdateCoverImage,
                                resourceCover: onClickUpdateResourceCoverImage,
                            }[imageKey]
                        }
                        isLoading={isCreatingUpdatingProducts}>
                        {`Update ${imageKeyLabels[imageKey]}`}
                    </Button>
                    {!!product?.images?.[imageKey] && (
                        <Typography
                            isError
                            className="mt-2 underline cursor-pointer"
                            onClick={() => onClickDeleteImage(imageKey)}>
                            Delete
                        </Typography>
                    )}
                </BaseCard>
            ))}
        </div>
    );
};
