import Button from '../Button';
import { ReactNode } from 'react';
import Typography from '../Typography';

export type PageHeadingAction = {
    title: string;
    onClick?: () => void;
    isPrimary?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
};

export default ({
    title,
    subtitle,
    actions,
}: {
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    actions?: PageHeadingAction[];
}) => {
    return (
        <div className="mb-4 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
                {/*<h1 className="font-bold sm:truncate text-3xl md:text-2xl sm:tracking-tight">{title}</h1>*/}
                <Typography isHeading block>{title}</Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
                {/*{subtitle && <p className="mt-2 text-lg">{subtitle}</p>}*/}
            </div>
            {actions && actions?.length > 0 && (
                <div className="mt-4 flex md:ml-4 md:mt-0 items-center gap-2">
                    {actions?.map(({ title, onClick, isPrimary, isDisabled, isLoading, leftIcon, rightIcon }, index) => (
                        <Button
                            key={index}
                            onClick={onClick}
                            isPrimary={isPrimary}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            leftIcon={leftIcon}
                            rightIcon={rightIcon}
                        >
                            {title}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};
