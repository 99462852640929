import BaseDrawer from '../_BaseDrawer';
import { useEffect, useState } from 'react';
import Loader from '../../Loader';
import { useVendorCommunitiesManager } from '../../../providers/VendorCommunitiesManagerProvider';
import Pill from '../../Pill';
import { startCase } from 'lodash-es';
import DetailsList, { DetailsListRow } from '../../DetailsList';
import { VendorCommunityItem } from '../../../types/vendor-communities.ts';
import moment from 'moment';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Button from '../../Button';
import ConfirmationModal from '../../_modals/ConfirmationModal';
import Typography from '../../Typography';

export default ({ communityId, onClose }: { communityId?: string; onClose: () => void }) => {
    const { communities, isFetchingCommunities, isDisconnectingCommunity, disconnectCommunity } =
        useVendorCommunitiesManager();
    const [community, setCommunity] = useState(communities?.find(({ SK }) => SK === communityId));
    const communityType = community?.SK?.split('#')[0];
    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );

    const communityName = {
        discord: community?.discordGuild?.name,
        slack: community?.slackWorkspace?.team?.name,
    }?.[communityType as string];
    const communityIconImage = {
        discord: community?.discordGuild?.iconURL,
        slack: community?.slackWorkspace?.team?.icon?.image132,
    }?.[communityType as string];
    const communityUrl = {
        discord: `https://discord.com/channels/${community?.discordGuild?.id}`,
        slack: community?.slackWorkspace?.team?.url,
    }?.[communityType as string] as string;

    useEffect(() => {
        setCommunity(communities?.find(({ SK }) => SK === communityId));
    }, [communityId, communities]);

    const rows: DetailsListRow<VendorCommunityItem>[] = [
        {
            label: 'Connected By',
            accessor: 'connectorCognitoSub',
        },
        {
            label: 'Created',
            render: (communityItem) => moment(communityItem.createdAt).format('DD MMM YYYY  hh:mm a'),
        },
        {
            label: 'Users',
            render: (communityItem) =>
                `${{
                    discord: communityItem?.discordGuild?.memberCount,
                    slack: communityItem?.slackWorkspace?.totalUsers,
                }?.[communityType as string]}`,
        },
    ];

    const onClickDisconnect = async () => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to disconnect "${communityName}"?`,
            onConfirm: async () => {
                if (community?.SK) {
                    const [communityType, communityId] = community?.SK?.split('#') || [];
                    await disconnectCommunity({
                        communityType: communityType as 'discord' | 'slack',
                        communityId,
                    }).then(onClose);
                }
            },
        });
    };

    return (
        <BaseDrawer isOpen={!!communityId} onClose={onClose} className="relative z-[100] w-96">
            {isFetchingCommunities && <Loader width={30} />}
           <div className='space-y-6'>
               <div className="flex flex-row items-center gap-2">
                   <img alt="" src={communityIconImage} className="h-14 w-14 flex-shrink-0 rounded-full bg-gray-300" />
                   <div className="w-full">
                       <Typography className="truncate mb-1">{communityName}</Typography>
                       <div className="flex items-center justify-between w-full">
                           <Pill>
                               <img
                                   alt=""
                                   src={`/community-icons/${community?.SK?.split('#')?.[0]}.svg`}
                                   className="h-4 w-4 flex-shrink-0 rounded-full"
                               />
                               <div>{startCase(communityType)}</div>
                           </Pill>
                           <Typography
                               className="underline cursor-pointer flex items-center gap-1"
                               onClick={() => window.open(communityUrl, '_blank')}>
                               <ArrowTopRightOnSquareIcon aria-hidden="true" className="h-4 w-4 text-primary-text" />
                               Visit Community
                           </Typography>
                       </div>
                   </div>
               </div>
               <DetailsList<VendorCommunityItem> rows={rows} data={community as VendorCommunityItem} />

               <div className="flex justify-end items-center gap-2">
                   {!!community?.SK && (
                       <>
                           <Button size="xs" onClick={onClickDisconnect} isLoading={isDisconnectingCommunity}>
                               Disconnect
                           </Button>
                       </>
                   )}
               </div>
           </div>

            <ConfirmationModal
                isOpen={!!isShowConfirmationModal}
                onClose={() => setIsShowConfirmationModal(null)}
                {...isShowConfirmationModal}
            />
        </BaseDrawer>
    );
};
