import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useVendorManager } from '../VendorManagerProvider';
import { isEmpty, isEqual } from 'lodash-es';
import {
    VendorUserItemQuery,
    VendorUsersManagerProvider,
    VendorUsersQuery,
} from '../../types/vendor-users-manager-provider.ts';
import getVendorUsersQuery from '../../api/users/getVendorUsersQuery.graphql';
import getVendorUserQuery from '../../api/users/getVendorUserQuery.graphql';
import { VendorUserItem, VendorUserItemUpdates } from '../../types/vendor-user.ts';
import createVendorUserMutation from '../../api/users/createVendorUserMutation.graphql';
import updateVendorUserMutation from '../../api/users/updateVendorUserMutation.graphql';
import deleteVendorUserMutation from '../../api/users/deleteVendorUserMutation.graphql';
import { toast } from 'react-toastify';

// Provider definition
const defaultContext = {} as VendorUsersManagerProvider;
const VendorUsersManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorUsersManagerContext;
const VendorUsersManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { currentVendor } = useVendorManager();
    const [usersQuery, setUsersQuery] = useState({} as VendorUsersQuery);
    const [userItemQuery, setUserItemQuery] = useState({} as VendorUserItemQuery);

    const updateUsersQuery = (config: VendorUsersQuery) => {
        if (!isEqual(config, usersQuery)) setUsersQuery(config);
    };

    const updateUserItemQuery = (config: VendorUserItemQuery) => {
        if (!isEqual(config, userItemQuery)) setUserItemQuery(config);
    };

    const [usersData, setUsersData] = useState({
        items: [] as VendorUserItem[],
        count: 0,
        cursor: undefined,
    });

    const [userItemData, setUserItemData] = useState(undefined as VendorUserItem | undefined);

    const {
        client,
        loading: isFetchingUsers,
        data: usersQueryData,
        fetchMore,
    } = useQuery(getVendorUsersQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            query: usersQuery,
        },
        skip: !currentVendor?.vendorUuid || isEmpty(usersQuery),
        fetchPolicy: 'network-only'
    });

    const { loading: isFetchingUserItem, data: userItemQueryData } = useQuery(getVendorUserQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            userId: userItemQuery?.userId,
        },
        skip: !currentVendor?.vendorUuid || !userItemQuery?.userId,
    });

    const [createVendorUser, { loading: isCreatingUser }] = useMutation(createVendorUserMutation);
    const [updateVendorUser, { loading: isUpdatingUser }] = useMutation(updateVendorUserMutation);
    const [deleteVendorUser, { loading: isDeletingUser }] = useMutation(deleteVendorUserMutation);

    useEffect(() => {
        setUsersData(usersQueryData?.getVendorUsers?.data);
    }, [usersQueryData]);

    useEffect(() => {
        (async () => {
            if (userItemQueryData?.getVendorUser?.error?.code === 'USER_NOT_FOUND') {
                await refetchUserItems();
                toast.error('User not found.');
                setUserItemData(undefined);
            } else setUserItemData(userItemQueryData?.getVendorUser?.data);
        })();
    }, [userItemQueryData]);

    const users = {
        items: usersData?.items as VendorUserItem[],
        count: usersData?.count,
        cursor: usersData?.cursor,
        fetchMore: async () => {
            if (usersData?.cursor)
                await fetchMore({
                    variables: {
                        vendorUuid: currentVendor?.vendorUuid,
                        query: {
                            cursor: usersData?.cursor,
                            limit: usersQuery?.limit,
                            sortBy: usersQuery?.sortBy,
                            sortDirection: usersQuery?.sortDirection,
                            search: usersQuery?.search,
                        },
                    },
                });
        },
    };

    const createUser = async ({ user }: { user: { givenName: string; familyName: string; email: string } }) => {
        const response = await createVendorUser({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                user,
            },
        });

        setTimeout(() => refetchUserItems(), 5000);
        toast.success('User created.');

        return response?.data?.createVendorUser as {
            data?: VendorUserItem;
            error?: { code: string };
        };
    };
    const updateUser = async ({ user }: { user: VendorUserItemUpdates & { cognitoSub: string } }) => {
        const response = await updateVendorUser({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                user,
            },
        });

        await refetchUserItem();
        toast.success('User updated.');
        setTimeout(() => refetchUserItems(), 5000);

        return response?.data?.updateVendorUser as {
            data?: VendorUserItem;
            error?: { code: string };
        };
    };

    const deleteUser = async ({ userId }: { userId: string }) => {
        await deleteVendorUser({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                userId,
            },
        });
        setUserItemQuery({});

        toast.success('User deleted.');

        await refetchUserItem();
        setTimeout(() => refetchUserItems(), 5000);
    };

    const refetchUserItems = async () =>
        client.refetchQueries({
            include: [getVendorUsersQuery],
        });

    const refetchUserItem = async () =>
        client.refetchQueries({
            include: [getVendorUserQuery],
        });

    return (
        <Provider
            value={{
                updateUsersQuery,
                updateUserItemQuery,
                isFetchingUsers,
                users,
                isFetchingUserItem,
                userItem: userItemData,
                refetchUserItem,
                refetchUserItems,
                createUser,
                isCreatingUser,
                updateUser,
                isUpdatingUser,
                deleteUser,
                isDeletingUser,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorUsersManager = (config?: { initialQuery: VendorUsersQuery }) => {
    const { updateUsersQuery, ...functions } = useContext(VendorUsersManagerContext);
    useEffect(() => {
        if (config?.initialQuery) updateUsersQuery(config?.initialQuery);
    }, [config]);

    return { ...functions, updateUsersQuery };
};

export { VendorUsersManagerProvider, ConfigConsumer, useVendorUsersManager };
