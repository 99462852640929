import { CSSProperties, ReactNode } from 'react';

const Pill = ({
    children,
    size = 'xs',
    bgClass = 'bg-gray-200',
    textClass = 'text-gray-600',
    ringClass = 'ring-gray-300',
    style,
}: {
    children: string | ReactNode | ReactNode[];
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    bgClass?: string;
    textClass?: string;
    ringClass?: string;
    style?: CSSProperties;
}) => (
    <div
        className={`inline-flex items-center gap-1 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset text-${size.replace('md', 'base')} ${bgClass} ${textClass} ${ringClass}`}
        style={style}>
        {children}
    </div>
);

export default Pill;
