import Select, { GroupBase, Props } from 'react-select';
import classNames from '../../utils/classNames.ts';
import BaseCard from '../_cards/BaseCard';

export default function ThemedSelect<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({ classNames: propsClassNames, ...props }: Props<Option, IsMulti, Group>) {
    return (
        <BaseCard className='!p-0'>
            <Select
                classNames={{
                    control: (event) =>
                        classNames(
                            'w-36 text-xs !cursor-pointer !px-0 !py-1 !min-h-0 !bg-transparent !border-0 hover:!border-white',
                            event.isFocused && '',
                            propsClassNames?.control && propsClassNames?.control(event),
                        ),
                    valueContainer: (event) =>
                        classNames(
                            'text-xs !px-2 !py-0 text-xs',
                            propsClassNames?.valueContainer && propsClassNames?.valueContainer(event),
                        ),
                    singleValue: (event) =>
                        classNames(
                            'font-semibold text-xs !text-white',
                            propsClassNames?.singleValue && propsClassNames?.singleValue(event),
                        ),
                    dropdownIndicator: (event) =>
                        classNames(
                            '!px-1 !py-0 text-xs !text-white',
                            propsClassNames?.dropdownIndicator && propsClassNames?.dropdownIndicator(event),
                        ),
                    menu: (event) => classNames('text-xs', propsClassNames?.menu && propsClassNames?.menu(event)),
                    option: (event) =>
                        classNames(
                            '!cursor-pointer hover:bg-gray-100 text-xs',
                            event.isSelected && '',
                            propsClassNames?.option && propsClassNames?.option(event),
                        ),
                }}
                {...props}
            />
        </BaseCard>
    );
}
