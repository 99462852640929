import { useEffect, useState } from 'react';
import Button from '../../Button';
import BaseModal from '../_BaseModal';
import PageLoader from '../../PageLoader';
import serveMediaLibraryImage from '../../../utils/serveMediaLibraryImage.ts';
import { useNavigate } from 'react-router-dom';
import ThemedSelect from '../../ThemedSelect';
import { sortByOptions, sortDirectionOptions } from '../../../consts/media-library.ts';
import { useVendorMediaManager } from '../../../providers/VendorMediaManagerProvider';
import {
    VendorMediaQuerySortByOptions,
    VendorMediaQuerySortDirections,
} from '../../../types/vendor-media-manager-provider.ts';
import MediaItem from '../../_items/MediaItem';
import Typography from '../../Typography';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const MediaPickerModal = ({
    isOpen,
    onClose,
    defaultImageUrl,
    defaultImageDarkBackground,
    title = 'Select image',
    onUpdateImage,
}: {
    isOpen?: boolean;
    onClose: () => void;
    defaultImageUrl?: string;
    defaultImageDarkBackground?: boolean;
    title?: string;
    onUpdateImage?: (hexColour: string) => boolean;
}) => {
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);
    const [sortDirection, setSortDirection] = useState({
        value: 'DESC',
        label: 'Descending',
    });
    const [sortBy, setSortBy] = useState({
        value: 'UPLOADED_AT',
        label: 'Uploaded At',
    });
    const { isFetchingMedia, media, refetchMediaItems } = useVendorMediaManager({
        initialQuery: {
            limit: 100,
            sortBy: sortBy?.value as VendorMediaQuerySortByOptions,
            sortDirection: sortDirection?.value as VendorMediaQuerySortDirections,
        },
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: isFetchingMedia,
        hasNextPage: !!media?.cursor,
        onLoadMore: media?.fetchMore,
        // disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    useEffect(() => {
        if(isOpen) refetchMediaItems();
    }, [isOpen]);

    return (
        <BaseModal
            title={title}
            subtitle="Select an image from your media library by clicking on it below"
            isOpen={isOpen}
            onClose={onClose}>
            {defaultImageUrl && (
                <div className="mt-4 inline-flex flex-row items-center gap-4 bg-gray-100 p-2 rounded-md ">
                    <div
                        className={`w-16 h-16 flex flex-row p-2 rounded-md cursor-pointer gap-2 ${
                            defaultImageDarkBackground ? 'bg-black' : 'bg-gray-200'
                        }`}>
                        <img
                            src={serveMediaLibraryImage(defaultImageUrl, {
                                width: 64,
                                format: 'png',
                                // greyscale: true,
                            })}
                            className="w-full h-full object-contain rounded-md"
                        />
                    </div>
                    <Typography>You are updating this image</Typography>
                </div>
            )}
            <div className="mt-4 flex flex-row items-center w-full gap-4">
                <ThemedSelect
                    defaultValue={sortBy}
                    onChange={(e) => e && setSortBy({ value: e.value, label: e.label })}
                    options={sortByOptions?.filter((option) => option.value !== sortBy?.value)}
                    isSearchable={false}
                />
                <ThemedSelect
                    defaultValue={sortDirection}
                    onChange={(e) => e && setSortDirection({ value: e.value, label: e.label })}
                    options={sortDirectionOptions?.filter((option) => option.value !== sortDirection?.value)}
                    isSearchable={false}
                />
            </div>
            <div className="mt-4 flex flex-col lg:flex-row  gap-4 md:w-[45vw]">
                <PageLoader isLoading={isFetchingMedia}>
                    <div className="max-h-[60vh] overflow-y-scroll" ref={rootRef}>
                        <div className="w-full flex flex-row flex-wrap gap-4">
                            {media?.items?.map((mediaItem) => (
                                <MediaItem
                                    key={mediaItem.SK}
                                    mediaItem={mediaItem}
                                    onClick={() => setImageUrl(mediaItem.url)}
                                    isSelected={mediaItem.url === imageUrl}
                                />
                            ))}
                            {(isFetchingMedia || !!media?.cursor) && <div ref={sentryRef}>Loading...</div>}
                        </div>
                    </div>
                </PageLoader>
            </div>
            <div className="mt-4 flex flex-row justify-end gap-4">
                <Button onClick={() => navigate('/media-library')}>Upload New</Button>
                <Button
                    isPrimary
                    isDisabled={!imageUrl || defaultImageUrl === imageUrl}
                    onClick={() => {
                        if (onUpdateImage && onUpdateImage(imageUrl as string)) onClose();
                    }}>
                    Update
                </Button>
            </div>
        </BaseModal>
    );
};

export default MediaPickerModal;
