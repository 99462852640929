import BaseModal from '../_BaseModal';
import { useEffect, useState } from 'react';
import { useVendorManager } from '../../../providers/VendorManagerProvider';
import { useQuery } from '@apollo/client';
import getVendorContentItemViewDetailsQuery from '../../../api/content/getVendorContentItemViewDetailsQuery.graphql';
import PageLoader from '../../PageLoader';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { isNumber } from 'lodash-es';
import classNames from '../../../utils/classNames.ts';
import Loader from '../../Loader';
import { generateFromObj } from '@bramus/pagination-sequence';
import BaseCard from '../../_cards/BaseCard';
import { VendorContentAsset } from '../../../types/vendor-content.ts';
import ContentVideoPlayer from '../../ContentVideoPlayer';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const ContentAssetViewerModal = ({
    isOpen,
    onClose,
    contentId,
}: {
    isOpen?: boolean;
    onClose: () => void;
    contentId?: string;
}) => {
    const { currentVendor } = useVendorManager();
    const { loading: isLoading, data } = useQuery(getVendorContentItemViewDetailsQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            contentId,
        },
        skip: !currentVendor?.vendorUuid || !contentId,
        fetchPolicy: 'network-only',
    });

    const contentAsset = data?.getVendorContentItemViewDetails?.data as VendorContentAsset;

    const [totalPages, setTotalPages] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setTotalPages(numPages);
    };
    const pagination = generateFromObj({
        curPage: currentPageNumber,
        numPages: totalPages,
        numPagesAtEdges: 2,
        numPagesAroundCurrent: 2,
    });

    useEffect(() => {
        setCurrentPageNumber(1);
    }, [contentAsset]);

    const isAudioVideoContent =
        contentAsset?.contentItem?.contentType?.startsWith('audio/') ||
        contentAsset?.contentItem?.contentType?.startsWith('video/');

    return (
        <BaseModal title={contentAsset?.contentItem?.fileName} isOpen={isOpen} onClose={onClose}>
            <div className="mt-4 flex flex-col gap-4 min-w-[20vw]">
                <PageLoader isLoading={isLoading}>
                    <div className="flex flex-col justify-center items-center gap-4">
                        {isAudioVideoContent && (
                            <ContentVideoPlayer contentItem={contentAsset?.contentItem} token={contentAsset?.token} />
                        )}
                        {!isAudioVideoContent && (
                            <>
                                <Document
                                    file={decodeURI(contentAsset?.viewUrl as string)}
                                    loading={
                                        <div className="py-10">
                                            <Loader />
                                        </div>
                                    }
                                    onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={currentPageNumber} />
                                </Document>
                                {!isLoading && totalPages > 1 && (
                                    <nav aria-label="Pagination" className="isolate inline-flex rounded-md gap-1 h-10">
                                        <BaseCard
                                            {...(currentPageNumber > 1
                                                ? { onClick: () => setCurrentPageNumber(currentPageNumber - 1) }
                                                : {})}
                                            className={classNames(
                                                currentPageNumber > 1
                                                    ? 'cursor-pointer text-white hover:bg-white hover:text-black'
                                                    : 'text-gray-600',
                                                '!p-0 w-8 h-full relative inline-flex items-center justify-center',
                                            )}>
                                            <span className="sr-only">Previous</span>
                                            <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
                                        </BaseCard>
                                        {pagination?.map((pageNumber) =>
                                            isNumber(pageNumber) ? (
                                                <BaseCard
                                                    onClick={() => setCurrentPageNumber(pageNumber)}
                                                    className={classNames(
                                                        '!p-0 w-8 h-full cursor-pointer hover:bg-white hover:text-black flex md:inline-flex items-center justify-center',
                                                        currentPageNumber == pageNumber
                                                            ? 'text-white relative z-10 text-sm focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
                                                            : 'text-gray-600 relative hidden focus:z-20 focus:outline-offset-0',
                                                    )}>
                                                    {pageNumber}
                                                </BaseCard>
                                            ) : (
                                                <BaseCard className="!p-0 w-8 h-full text-gray-600 inline-flex items-center justify-center">
                                                    ...
                                                </BaseCard>
                                            ),
                                        )}
                                        <BaseCard
                                            {...(currentPageNumber < totalPages
                                                ? { onClick: () => setCurrentPageNumber(currentPageNumber + 1) }
                                                : {})}
                                            className={classNames(
                                                currentPageNumber < totalPages
                                                    ? 'cursor-pointer text-white hover:bg-white hover:text-black'
                                                    : 'text-gray-600',
                                                '!p-0 w-8 h-full relative inline-flex items-center justify-center',
                                            )}>
                                            <span className="sr-only">Next</span>
                                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                                        </BaseCard>
                                    </nav>
                                )}
                            </>
                        )}
                    </div>
                </PageLoader>
            </div>
        </BaseModal>
    );
};

export default ContentAssetViewerModal;
