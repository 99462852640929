export const sortByOptions = [
    {
        value: 'TITLE',
        label: 'Product Title',
    },
    {
        value: 'TYPE',
        label: 'Product Type',
    },
    {
        value: 'CREATED_AT',
        label: 'Created At',
    },
];
export const sortDirectionOptions = [
    {
        value: 'DESC',
        label: 'Descending',
    },
    {
        value: 'ASC',
        label: 'Ascending',
    },
];
