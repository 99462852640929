import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { VendorContentItem } from '../../types/vendor-content.ts';
import { isEmpty, isEqual } from 'lodash-es';
import Typography from '../Typography';
import ContentAssetViewerModal from '../_modals/ContentAssetViewerModal';

// Combine SK and index to create a unique identifier for each item
const getUniqueId = (contentAsset: VendorContentItem, index: number) => `${contentAsset.SK}-${index}`;

const SortableFileItem = ({
    contentAsset,
    uniqueId,
    onDeleteContentAsset,
    setIsShowContentAssetViewer,
}: {
    contentAsset: VendorContentItem;
    uniqueId: string;
    onDeleteContentAsset: ({ contentAsset }: { contentAsset: VendorContentItem }) => void;
    setIsShowContentAssetViewer: Dispatch<
        SetStateAction<{
            contentId: string;
        } | null>
    >;
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: uniqueId });

    return (
        <div
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            onClick={() => setIsShowContentAssetViewer({ contentId: contentAsset.SK })}
            className="flex items-center justify-between py-2 border-card-border border-b cursor-pointer">
            <div {...listeners} {...attributes} className="cursor-move flex flex-row opacity-50 my-auto text-white">
                <EllipsisVerticalIcon className="h-6 w-6 -ml-2" />
                <EllipsisVerticalIcon className="h-6 w-6 -ml-4" />
            </div>
            <Typography className="w-full">{contentAsset.fileName}</Typography>
            <TrashIcon
                className="w-4 h-4 text-white"
                onClick={(e) => {
                    e.stopPropagation();
                    onDeleteContentAsset({ contentAsset });
                }}
            />
        </div>
    );
};

export default ({
    items,
    onContentAssetsReordered,
    onDeleteContentAsset,
}: {
    items?: VendorContentItem[];
    onContentAssetsReordered?: ({ reorderedContentAssets }: { reorderedContentAssets: VendorContentItem[] }) => void;
    onDeleteContentAsset: ({ contentAsset }: { contentAsset: VendorContentItem }) => void;
}) => {
    const [reorderedContentAssets, setReorderedContentAssets] = useState(items || []);
    const debounceDisplayOrderUpdateRef = useRef(null as ReturnType<typeof setTimeout> | null);
    const [isShowContentAssetViewer, setIsShowContentAssetViewer] = useState(
        null as null | {
            contentId: string;
        },
    );

    useEffect(() => {
        setReorderedContentAssets(items || []);
    }, [items]);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;

        const activeIndex = reorderedContentAssets?.findIndex((item, index) => getUniqueId(item, index) === active.id);
        const overIndex = reorderedContentAssets?.findIndex((item, index) => getUniqueId(item, index) === over.id);

        if (activeIndex !== overIndex) setReorderedContentAssets((items) => arrayMove(items, activeIndex, overIndex));
    };

    useEffect(() => {
        (async () => {
            if (!isEqual(items, reorderedContentAssets) && onContentAssetsReordered && !isEmpty(reorderedContentAssets)) {
                if (debounceDisplayOrderUpdateRef?.current) clearTimeout(debounceDisplayOrderUpdateRef.current);

                debounceDisplayOrderUpdateRef.current = setTimeout(async () => {
                    onContentAssetsReordered({ reorderedContentAssets });
                }, 5000);
            }
        })();
    }, [reorderedContentAssets]);

    return (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext
                items={reorderedContentAssets.map((contentAsset, index) => getUniqueId(contentAsset, index))}
                strategy={verticalListSortingStrategy}>
                <div className="w-full">
                    {reorderedContentAssets.map((contentAsset, index) => (
                        <SortableFileItem
                            key={getUniqueId(contentAsset, index)}
                            contentAsset={contentAsset}
                            uniqueId={getUniqueId(contentAsset, index)}
                            onDeleteContentAsset={onDeleteContentAsset}
                            setIsShowContentAssetViewer={setIsShowContentAssetViewer}
                        />
                    ))}
                </div>
            </SortableContext>

            <ContentAssetViewerModal
                isOpen={!!isShowContentAssetViewer}
                {...isShowContentAssetViewer}
                onClose={() => setIsShowContentAssetViewer(null)}
            />
        </DndContext>
    );
};
