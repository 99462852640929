import './init/init.ts';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SignInPage from './pages/sign-in';
import DashboardLayout from './components/DashboardLayout';
import { ConfigProvider } from './providers/ConfigProvider';
import ApolloProvider from './providers/ApolloProvider';
import { AuthProvider } from './providers/AuthProvider';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import ThemingPage from './pages/theming';
import SettingsPage from './pages/settings';
import NotFoundPage from './pages/not-found';
import HomePage from './pages/home';
import TeamPage from './pages/team';
import PageLoader from './components/PageLoader';
import { VendorManagerProvider } from './providers/VendorManagerProvider';
import { ToastContainer, Zoom } from 'react-toastify';

import MediaLibraryPage from './pages/media-library';
import ContentLibraryPage from './pages/content-library';
import { VendorContentManagerProvider } from './providers/VendorContentManagerProvider';
import { VendorMediaManagerProvider } from './providers/VendorMediaManagerProvider';
import DigitalProductsPage from './pages/digital-products';
import { VendorProductsManagerProvider } from './providers/VendorProductsManagerProvider';
import DigitalProductDetailPage from './pages/digital-products/detail.tsx';
import { VendorDomainsManagerProvider } from './providers/VendorDomainsManagerProvider';
import DomainsPage from './pages/domains';
import UsersPage from './pages/users';
import { VendorUsersManagerProvider } from './providers/VendorUsersManagerProvider';
import { VendorCommunitiesManagerProvider } from './providers/VendorCommunitiesManagerProvider';
import CommunitiesPage from './pages/communities';

const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const authenticate = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserLoaded(true);
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
            navigate('/sign-in');
        }
    };

    useEffect(() => {
        (async () => {
            await authenticate();
        })();
    }, [location]);

    if (!isUserLoaded) return <PageLoader isLoading />;

    // if (!isLoadingAccess && !userAccess?.length) return <NoAccessPage />;

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? <Outlet /> : <Navigate to="/sign-in" />;
};

function App() {
    return (
        <ConfigProvider>
            <ApolloProvider>
                <AuthProvider>
                    <VendorManagerProvider>
                        <VendorUsersManagerProvider>
                            <VendorMediaManagerProvider>
                                <VendorContentManagerProvider>
                                    <VendorProductsManagerProvider>
                                        <VendorDomainsManagerProvider>
                                            <VendorCommunitiesManagerProvider>
                                            <Routes>
                                                <Route path="*" element={<NotFoundPage />} />
                                                <Route path="/sign-in" element={<SignInPage />} />
                                                <Route element={<PrivateRoute />}>
                                                    <Route path="/" element={<DashboardLayout />}>
                                                        <Route index element={<HomePage />} />

                                                        <Route
                                                            path="/content-library/:contentId?"
                                                            element={<ContentLibraryPage />}
                                                        />
                                                        <Route
                                                            path="/digital-products"
                                                            element={<DigitalProductsPage />}
                                                        />
                                                        <Route
                                                            path="/digital-products/:productId/:productItemId?"
                                                            element={<DigitalProductDetailPage />}
                                                        />
                                                        <Route path="/theming" element={<ThemingPage />} />
                                                        <Route path="/media-library" element={<MediaLibraryPage />} />
                                                        <Route path="/communities" element={<CommunitiesPage />} />
                                                        <Route path="/users/:userId?" element={<UsersPage />} />
                                                        <Route path="/domains" element={<DomainsPage />} />
                                                        <Route path="/team" element={<TeamPage />} />
                                                        <Route path="/settings" element={<SettingsPage />} />
                                                    </Route>
                                                </Route>
                                            </Routes>
                                            <ToastContainer
                                                position="top-right"
                                                autoClose={3000}
                                                hideProgressBar={false}
                                                newestOnTop
                                                closeOnClick
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="light"
                                                transition={Zoom}
                                            />
                                            </VendorCommunitiesManagerProvider>
                                        </VendorDomainsManagerProvider>
                                    </VendorProductsManagerProvider>
                                </VendorContentManagerProvider>
                            </VendorMediaManagerProvider>
                        </VendorUsersManagerProvider>
                    </VendorManagerProvider>
                </AuthProvider>
            </ApolloProvider>
        </ConfigProvider>
    );
}

export default App;
