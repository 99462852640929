import Button from '../../Button';
import BaseModal from '../_BaseModal';
import FontPicker, { FontToVariant } from 'react-fontpicker-ts';
import 'react-fontpicker-ts/dist/index.css';
import { useState } from 'react';

const FontPickerModal = ({
    isOpen,
    onClose,
    defaultFontFamilyName,
    title = 'Select font',
    onUpdateFont,
}: {
    isOpen?: boolean;
    onClose: () => void;
    defaultFontFamilyName?: string;
    title?: string;
    onUpdateFont?: (fontFamilyName: string, fontUrl: string) => boolean;
}) => {
    const [selectedFontFamilyVariants, setSelectedFontFamilyVariants] = useState(null as null | FontToVariant);
    return (
        <BaseModal
            title={title}
            subtitle="Select your chosen font from the dropdown below."
            isOpen={isOpen}
            onClose={onClose}>
            <div className="mt-4 flex flex-col lg:flex-row gap-4 z-[60]">
                <FontPicker
                    autoLoad
                    defaultValue={defaultFontFamilyName || ''}
                    fontVariants={(variants: FontToVariant) => setSelectedFontFamilyVariants(variants)}
                    style={{ flex: 1 }}
                />
            </div>
            <div className="mt-4 flex flex-row justify-end">
                <Button
                    isPrimary
                    isDisabled={!selectedFontFamilyVariants || defaultFontFamilyName === selectedFontFamilyVariants?.fontName}
                    onClick={() => {
                        if (selectedFontFamilyVariants && onUpdateFont && onUpdateFont(selectedFontFamilyVariants.fontName as string, encodeURI(`https://fonts.googleapis.com/css2?family=${selectedFontFamilyVariants.fontName}:ital,wght@${selectedFontFamilyVariants.variants?.sort().join(';')}&display=swap`))) onClose();
                    }}>
                    Update
                </Button>
            </div>
        </BaseModal>
    );
};

export default FontPickerModal;
