import { useEffect, useState } from 'react';
import Button from '../../Button';
import BaseModal from '../_BaseModal';
import classNames from '../../../utils/classNames.ts';

const ConfirmationModal = ({
    isOpen,
    onClose,
    question,
    onConfirm,
}: {
    isOpen?: boolean;
    onClose: () => void;
    question?: string;
    onConfirm?: () => Promise<void>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(question);
    useEffect(() => {
        if (question !== title) setTitle(question);
    }, [question]);

    const onClickConfirm = async () => {
        setIsLoading(true);
        if (onConfirm) await onConfirm();
        setIsLoading(false);
        onClose();
    };

    return (
        <BaseModal title="Confirmation" subtitle={title} isOpen={isOpen} onClose={onClose}>
            <div className={classNames(`mt-4 flex flex-row justify-end items-center gap-2`)}>
                <Button isPrimary onClick={onClose}>
                    Cancel
                </Button>
                <Button isLoading={isLoading} onClick={onClickConfirm}>
                    Confirm
                </Button>
            </div>
        </BaseModal>
    );
};

export default ConfirmationModal;
