import serveMediaLibraryImage from '../../../utils/serveMediaLibraryImage.ts';
import { HTMLAttributes } from 'react';
import { VendorMediaItem } from '../../../types/vendor-media.ts';
import classNames from '../../../utils/classNames.ts';
import BaseCard from '../../_cards/BaseCard';
import Typography from '../../Typography';

export default ({
    mediaItem,
    isSelected,
    ...props
}: {
    mediaItem: VendorMediaItem;
    isSelected?: boolean;
} & HTMLAttributes<HTMLDivElement>) => {
    const { fileName, url, fileSizeBytes, SK } = mediaItem;

    return (
        <BaseCard
            className={classNames(
                'w-32 flex flex-col cursor-pointer gap-2',
                isSelected ? 'border-white' : '',
            )}
            key={SK}
            {...props}>
            <img
                src={serveMediaLibraryImage(url, {
                    width: 128,
                    format: 'png',
                    // greyscale: true,
                })}
                className="w-full flex-1 object-contain min-h-0 rounded-md"
            />
            <div className="text-xs text-center">
                <Typography isTertiary className="overflow-ellipsis whitespace-nowrap overflow-hidden">{fileName}</Typography>
                <Typography isTertiary className="italic">{`${(fileSizeBytes / 1000000).toFixed(3)}mb`}</Typography>
            </div>
        </BaseCard>
    );
};
