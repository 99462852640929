import { ComponentPropsWithRef, forwardRef, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import { QuillOptions } from 'quill';
import BaseCard from '../_cards/BaseCard';
import classNames from '../../utils/classNames.ts';

const RichTextEditor = forwardRef<
    HTMLDivElement,
    ComponentPropsWithRef<'div'> & {
        textSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
        quillOptions?: QuillOptions;
        defaultValue?: string;
        onTextChange?: (text: string) => void;
    }
>(({ className = '', textSize = 'sm', quillOptions, defaultValue, onTextChange, ...props }, ref) => {
    const { quill, quillRef } = useQuill({
        modules: {
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike', { 'header': 1 }, { 'header': 2 }],
                    [{'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'background', 'color', 'link', 'clean'],
                ],
            },
        },
        ...quillOptions,
    });

    useEffect(() => {
        if (quill) {
            if (defaultValue) quill.clipboard.dangerouslyPasteHTML(defaultValue || '');
            quill.on('text-change', (_, __, source) => {
                if (source === 'user' && onTextChange)
                    onTextChange(quill.root.innerHTML === '<p><br></p>' ? '' : quill.root.innerHTML);
            });

            return () => {
                quill.off('text-change');
            };
        }
    }, [quill, defaultValue]);

    return (
        <BaseCard className="!p-0">
            <div
                ref={ref || quillRef}
                className={classNames(
                    `w-full relative block text-sm border-0 rounded-md text-white bg-transparent focus:outline-white focus:ring-0 ${textSize}`,
                    className,
                )}
                {...props}
            />
        </BaseCard>
    );
});

export default RichTextEditor;
