import PageHeading from '../../components/PageHeading';
import { useVendorManager } from '../../providers/VendorManagerProvider';
import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import { useQuery } from '@apollo/client';
import getVendorUsersPermissionsQuery from '../../api/team/getVendorUsersPermissionsQuery.graphql';
import PageLoader from '../../components/PageLoader';
import { VendorPermission } from '../../types/vendor-permission.ts';
import moment from 'moment';
import { useState } from 'react';
import ManageTeamUserModal from '../../components/_modals/ManageTeamUserModal';
import { useAuth } from '../../providers/AuthProvider';
import BaseCard from '../../components/_cards/BaseCard';
import DataTable, { DataTableColumn } from '../../components/DataTable';
import Typography from '../../components/Typography';
import TeamUserStatusPill from '../../components/Pill/TeamUserStatusPill';

const TeamPage = () => {
    const { currentVendor } = useVendorManager();
    const { cognitoUser } = useAuth();

    const { data, loading: isFetchingPermissions } = useQuery(getVendorUsersPermissionsQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
        },
        skip: !currentVendor?.vendorUuid,
    });
    const vendorPermissions = data?.getVendorUsersPermissions?.permissions as VendorPermission[];

    const [currentSelectedPermission, setCurrentSelectedPermission] = useState(
        undefined as VendorPermission | undefined,
    );

    const columns: DataTableColumn<VendorPermission>[] = [
        {
            label: 'Name',
            render: (vendorPermission) => (
                <div>
                    <Typography>
                        {' '}
                        {`${`${vendorPermission?.cognitoUser?.attributes?.givenName || ''} ${
                            vendorPermission?.cognitoUser?.attributes?.familyName || ''
                        }`.trim()}${
                            vendorPermission?.cognitoUser?.attributes?.sub === cognitoUser?.attributes?.sub
                                ? ' (You)'
                                : ''
                        }`}
                    </Typography>
                    <Typography isTertiary className="mt-1">
                        {vendorPermission?.newUserEmail || vendorPermission?.cognitoUser?.attributes?.email}
                    </Typography>
                </div>
            ),
        },
        { label: 'Role', accessor: 'role.roleName' },
        {
            label: 'Status',
            render: (vendorPermission) => <TeamUserStatusPill status={vendorPermission?.inviteStatus} />,
        },
        {
            label: 'Inviter',
            render: (vendorPermission) => (
                <div>
                    <Typography>
                        {`${vendorPermission?.inviterCognitoUser?.attributes?.givenName || ''} ${
                            vendorPermission?.inviterCognitoUser?.attributes?.familyName || ''
                        }`.trim()}
                    </Typography>
                    <Typography isTertiary className="mt-1">
                        {vendorPermission?.inviterCognitoUser?.attributes?.email}
                    </Typography>
                </div>
            ),
        },
        {
            label: 'Updated',
            render: (vendorPermission) => moment(vendorPermission?.updatedAt).format('DD MMM YYYY  hh:mm a'),
        },
    ];

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Team"
                subtitle="Manage your team and their access levels."
                actions={[
                    {
                        title: 'Invite User',
                        isPrimary: true,
                        onClick: () => setCurrentSelectedPermission({} as VendorPermission),
                    },
                ]}
            />
            <div className="mt-8 flex flex-col gap-8">
                <PageLoader isLoading={isFetchingPermissions}>
                    <BaseCard className="mt-6">
                        <DataTable<VendorPermission>
                            columns={columns}
                            data={vendorPermissions as VendorPermission[]}
                            getRowKey={(row) => row?.cognitoUser?.attributes?.sub as string}
                            onRowClick={({ data: vendorPermission }) => setCurrentSelectedPermission(vendorPermission)}
                        />
                    </BaseCard>
                </PageLoader>
            </div>
            <ManageTeamUserModal
                isOpen={!!currentSelectedPermission}
                vendorPermission={currentSelectedPermission}
                onClose={() => setCurrentSelectedPermission(undefined)}
            />
        </VendorsLoadingLayout>
    );
};
export default TeamPage;
