import Button from '../../Button';
import BaseModal from '../_BaseModal';
import 'react-fontpicker-ts/dist/index.css';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from '../../../utils/classNames.ts';
import { useVendorContentManager } from '../../../providers/VendorContentManagerProvider';
import { useObjectUrls } from '../../../utils/useObjectUrls.ts';
import Typography from '../../Typography';
import { TrashIcon } from '@heroicons/react/24/outline';
import ContentItem from '../../_items/ContentItem';
import { VendorContentItem } from '../../../types/vendor-content.ts';

const ContentUploadModal = ({
    isOpen,
    onClose,
    title = 'Upload new content',
}: {
    isOpen?: boolean;
    onClose: () => void;
    title?: string;
}) => {
    const [files, setFiles] = useState([] as File[]);
    const getObjectUrl = useObjectUrls();
    const { isUploading, uploadContent } = useVendorContentManager();

    // useEffect(() => {
    //     if (
    //         mediaUploadProgress?.length > 0 &&
    //         mediaUploadProgress?.every(({ percentUploaded }) => percentUploaded === 100)
    //     ) {
    //         toast.success('Media uploaded to library.');
    //         onClose();
    //     }
    // }, [mediaUploadProgress]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            console.log('acceptedfiLES', acceptedFiles);
            setFiles([...files, ...acceptedFiles]);
        },
        [files],
    );
    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'video/*': [],
            'audio/mpeg': [],
            'audio/mp3': [],
            'audio/mp4': [],
            'audio/x-m4a': [],
            'audio/wav': [],
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        },
    });

    const thumbnails = files.map((file, index) => (
        <div className="relative" key={`${file.name}-${index}`}>
            <ContentItem
                className="h-full"
                contentItem={
                    {
                        fileName: file.name,
                        fileSizeBytes: file.size,
                        contentType: file.type,
                    } as VendorContentItem
                }
                thumbnailOverrideComponent={
                    file?.type?.startsWith('video/') && (
                        <video className="block h-24 object-contain" src={`${getObjectUrl(file)}#t=5`}></video>
                    )
                }
            />
            <TrashIcon
                className="w-4 h-4 text-white absolute top-2 right-2 cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    setFiles((prevState) => prevState.filter((_, fileIndex) => fileIndex !== index));
                }}
            />
        </div>
    ));

    const uploadFiles = async () => {
        onClose();
        await uploadContent(files);
        setFiles([]);
    };

    return (
        <BaseModal
            title={title}
            subtitle="Upload new content to your content library."
            isOpen={isOpen}
            onClose={onClose}>
            <div className="mt-4 max-w-2xl flex flex-col items-center justify-center gap-4">
                <div
                    {...getRootProps()}
                    className={classNames(
                        `flex items-center justify-center border-4 border-card-border border-dotted h-32 w-full p-4`,
                        isFocused && `border-white`,
                        isDragAccept && `border-white`,
                        isDragActive && `border-white`,
                        isDragReject && `border-red-500`,
                    )}>
                    <input {...getInputProps()} />
                    <Typography>Drag & drop your content here OR click to select files</Typography>
                </div>
                <div className="flex flex-wrap gap-2">{thumbnails}</div>
            </div>
            <div className="mt-4 flex flex-row justify-end">
                <Button isPrimary isLoading={isUploading} isDisabled={!files?.length} onClick={uploadFiles}>
                    Upload
                </Button>
            </div>
        </BaseModal>
    );
};

export default ContentUploadModal;
