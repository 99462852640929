import { ReactNode } from 'react';
import PageLoader from '../PageLoader';
import { useVendorManager } from '../../providers/VendorManagerProvider';

const VendorsLoadingLayout = ({ children }: { children: ReactNode }) => {
    const { fetchingVendors, currentVendor } = useVendorManager();

    return <PageLoader isLoading={fetchingVendors || !currentVendor}>{children}</PageLoader>;
};

export default VendorsLoadingLayout;
