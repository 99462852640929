import { useEffect, useRef, useState, MouseEvent } from 'react';

export default ({ angle = 0, onAngleChange }: { angle: number; onAngleChange: (angleDegrees: number) => void }) => {
    const [angleDegrees, setAngleDegrees] = useState(angle);
    const circleRef = useRef<HTMLDivElement>(null);

    useEffect(() => setAngleDegrees(angle), [angle]);

    const updateAngle = (e: MouseEvent<HTMLDivElement>) => {
        const { left, top, width, height } = circleRef?.current?.getBoundingClientRect() || {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
        };
        const x = e.clientX - (left + width / 2);
        const y = e.clientY - (top + height / 2);
        const newAngle = (Math.atan2(y, x) * (180 / Math.PI) + 450) % 360;
        setAngleDegrees(Math.floor(newAngle));
        onAngleChange(Math.floor(newAngle));
    };

    const [isMouseInsideCircle, setIsMouseInsideCircle] = useState(false);
    const handleMove = (moveEvent: MouseEvent<HTMLDivElement>) => {
        if (isMouseInsideCircle) updateAngle(moveEvent);
    }
    const handleDrag = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        updateAngle(e);
        setIsMouseInsideCircle(true);
    };
    const handleStopDrag = () => setIsMouseInsideCircle(false);

    const handleX = 50 + 40 * Math.cos((angleDegrees - 90) * (Math.PI / 180));
    const handleY = 50 + 40 * Math.sin((angleDegrees - 90) * (Math.PI / 180));

    return (
        <div
            ref={circleRef}
            onMouseDown={handleDrag}
            onMouseMove={handleMove}
            onMouseUp={handleStopDrag}
            style={{
                position: 'relative',
                width: 30,
                height: 30,
                borderRadius: '50%',
                background: '#FFFFFF',
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}>
            <div
                style={{
                    position: 'absolute',
                    left: `${handleX}%`,
                    top: `${handleY}%`,
                    width: 7,
                    height: 7,
                    background: '#000000',
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                    cursor: 'pointer',
                }}
            />
        </div>
    );
};
