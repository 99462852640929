import classNames from '../../utils/classNames.ts';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { get } from 'lodash-es';
import { PencilIcon } from '@heroicons/react/24/outline';
import Typography from '../Typography';
import Input from '../Input';

export type DetailsListRow<T> = {
    label: string;
    accessor?: string;
    render?: (item: T) => JSX.Element | string;
    isHidden?: boolean;
    isEditable?: boolean;
    onEdit?: (newValue: string) => void;
};

interface DetailRowItemProps<T> {
    row: DetailsListRow<T>;
    data: T;
    onEdit?: (newValue: string) => void;
}

interface DetailsListProps<T> {
    rows: DetailsListRow<T>[];
    data: T;
    className?: string;
}

function DetailRowItem<T>({ row: { label, render, accessor, isEditable, onEdit }, data }: DetailRowItemProps<T>) {
    const ref = useRef<HTMLInputElement>(null);
    const currentValue = accessor ? (get(data, accessor, '') as string) : '';
    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(currentValue);
    useEffect(() => {
        if (onEdit) {
            if (newValue !== currentValue) onEdit(newValue as string);
            else onEdit('');
        }
    }, [newValue]);

    useEffect(() => {
        if (isEditing && ref?.current) ref.current?.focus();
    }, [isEditing]);

    return (
        <div>
            <Typography isTertiary>{label}</Typography>
            <Typography
                isSecondary
                className={classNames('font-semibold flex flex-row items-center gap-2', isEditable && 'cursor-pointer')}
                onClick={() => {
                    if (isEditable) setIsEditing(true);
                }}>
                {isEditing ? (
                    <Input ref={ref} className="mt-2" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                ) : render ? (
                    render(data)
                ) : (
                    accessor && (get(data, accessor) as ReactNode)
                )}
                {isEditable && <PencilIcon className="h-3 w-3" />}
            </Typography>
        </div>
    );
}

export default function DetailsList<T>({ rows, data, className }: DetailsListProps<T>) {
    if (!data) return null;

    return (
        <div className={classNames('flex flex-col gap-4', className)}>
            {rows
                ?.filter(({ isHidden }) => !isHidden)
                ?.map((row) => <DetailRowItem<T> row={row} key={row.label} data={data} />)}
        </div>
    );
}
