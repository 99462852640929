import { useVendorDomainsManager } from '../../providers/VendorDomainsManagerProvider';
import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/outline';
import PageHeading from '../../components/PageHeading';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ArrowPathIcon, CheckCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import PageLoader from '../../components/PageLoader';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { VendorDomainRecord } from '../../types/vendor-domains.ts';
import ConfirmationModal from '../../components/_modals/ConfirmationModal';
import { useState } from 'react';
import { startCase } from 'lodash-es';
import NewDomainModal from '../../components/_modals/NewDomainModal';
import moment from 'moment';
import Pill from '../../components/Pill';
import Typography from '../../components/Typography';
import BaseCard from '../../components/_cards/BaseCard';

const DomainsPage = () => {
    const { domains, isFetchingDomains, deleteDomains, refetchDomains, isRefetchingDomains } =
        useVendorDomainsManager();
    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );
    const [isNewDomainModalOpen, setIsNewDomainModalOpen] = useState(false);

    const onClickDeleteDomain = async (domainName: string) =>
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete the domain ${domainName}?`,
            onConfirm: async () => {
                await deleteDomains({ domainNames: [domainName] });
            },
        });

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Domains Management"
                subtitle="Create and manage your domains."
                actions={[
                    {
                        title: 'New Domain',
                        isPrimary: true,
                        leftIcon: <PlusIcon className={`h-4 w-4`} aria-hidden="true" />,
                        onClick: () => {
                            setIsNewDomainModalOpen(true);
                        },
                    },
                ]}
            />
            <PageLoader isLoading={isFetchingDomains}>
                <div className='flex flex-col gap-4'>
                    {domains?.map(
                        ({
                            domainName,
                            type,
                            creatorCognitoUser,
                            cloudflareCustomHostname,
                            cloudflareCnameRecord,
                            createdAt,
                            isCustomHostname,
                            sslCertificateStatus,
                            ownershipVerificationStatus,
                            domainTargetRecordStatus,
                        }) => {
                            const isDomainActive =
                                sslCertificateStatus === 'ACTIVE' &&
                                ownershipVerificationStatus === 'ACTIVE' &&
                                domainTargetRecordStatus === 'ACTIVE';

                            return (
                                <BaseCard key={domainName} className="flex justify-between gap-x-6 py-5">
                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="min-w-0 flex-auto">
                                            {isDomainActive || !cloudflareCustomHostname ? (
                                                <div className="flex items-center gap-x-1.5">
                                                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                        <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                                    </div>
                                                    <Typography>Online</Typography>
                                                </div>
                                            ) : (
                                                <div className="flex items-center gap-x-1.5">
                                                    <div className="flex-none rounded-full bg-orange-600/20 p-1">
                                                        <div className="h-1.5 w-1.5 rounded-full bg-orange-600" />
                                                    </div>
                                                    <Typography>In Progress</Typography>
                                                </div>
                                            )}
                                            <div className="flex flex-row items-center gap-2 ">
                                                <Typography isPrimary>{domainName}</Typography>
                                                {!isCustomHostname && <Pill>Free</Pill>}
                                            </div>
                                            <Typography>{startCase(type?.toLowerCase())}</Typography>
                                            {creatorCognitoUser && (
                                                <Typography isTertiary>
                                                    {`Created by ${creatorCognitoUser?.attributes?.givenName} ${creatorCognitoUser?.attributes?.familyName}`.trim()}
                                                </Typography>
                                            )}
                                            {createdAt && (
                                                <Typography isTertiary>
                                                    {moment(createdAt).format('DD MMM YYYY  hh:mm a')}
                                                </Typography>
                                            )}
                                            {!isDomainActive && !!cloudflareCustomHostname && (
                                                <div className="mt-4 flex flex-col gap-4">
                                                    <div className="self-stretch rounded-md bg-yellow-50 p-4">
                                                        <div className="flex items-center">
                                                            <div className="flex-shrink-0">
                                                                <ExclamationTriangleIcon
                                                                    aria-hidden="true"
                                                                    className="h-5 w-5 text-yellow-400"
                                                                />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-yellow-800">
                                                                    Attention needed
                                                                </h3>
                                                                <div className="text-xs text-yellow-700">
                                                                    <div>
                                                                        Please set the records below on your domain DNS
                                                                        to begin using your custom domain.
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        *Please note that it "can" take up to 24 to 48
                                                                        hours (usually within the hour) for the DNS
                                                                        changes to propagate globally. During this
                                                                        period, some users may experience connectivity
                                                                        issues while others may already see the updated
                                                                        site. We appreciate your patience.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {[
                                                        {
                                                            label: 'Ownership Verification',
                                                            record: cloudflareCustomHostname?.ownershipVerification,
                                                            status: ownershipVerificationStatus,
                                                        },
                                                        {
                                                            label: 'SSL Certificate Delegation',
                                                            record: cloudflareCustomHostname?.ssl?.dcvDelegationRecords?.map(
                                                                ({ cname, cnameTarget }) => ({
                                                                    type: 'cname',
                                                                    name: cname,
                                                                    value: cnameTarget,
                                                                }),
                                                            )?.[0] as VendorDomainRecord,
                                                            status: sslCertificateStatus,
                                                        },
                                                        {
                                                            label: 'Domain Target',
                                                            record: cloudflareCnameRecord,
                                                            status: domainTargetRecordStatus,
                                                        },
                                                    ].map(
                                                        ({
                                                            label,
                                                            record,
                                                            status,
                                                        }: {
                                                            label: string;
                                                            record: VendorDomainRecord;
                                                            status: 'PENDING' | 'PROCESSING' | 'ACTIVE';
                                                        }) => (
                                                            <div className="text-xs">
                                                                <Typography className="underline mb-1">{label}</Typography>
                                                                {['ACTIVE', 'PROCESSING'].indexOf(status) === -1 && (
                                                                    <div>
                                                                        <div className="flex gap-1">
                                                                            <Typography isPrimary>Type:</Typography>
                                                                            <Typography isCopyEnabled>{record?.type?.toUpperCase()}</Typography>
                                                                        </div>
                                                                        <div className="flex gap-1">
                                                                            <Typography isPrimary>Name:</Typography>
                                                                            <Typography isCopyEnabled>{record?.name}</Typography>
                                                                        </div>
                                                                        <div className="flex gap-1">
                                                                            <Typography isPrimary>Data:</Typography>
                                                                            <Typography isCopyEnabled>{record?.value}</Typography>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {status === 'PROCESSING' && (
                                                                    <Typography className="flex items-center gap-1">
                                                                        <ArrowPathIcon
                                                                            aria-hidden="true"
                                                                            className="h-5 w-5 text-white [.group:not([data-checked])_&]:invisible"
                                                                        />
                                                                        Processing
                                                                    </Typography>
                                                                )}
                                                                {status === 'ACTIVE' && (
                                                                    <Typography className="flex items-center gap-1">
                                                                        <CheckCircleIcon
                                                                            aria-hidden="true"
                                                                            className="h-5 w-5 text-white [.group:not([data-checked])_&]:invisible"
                                                                        />
                                                                        Complete
                                                                    </Typography>
                                                                )}
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex shrink-0 gap-x-6">
                                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                                            {isDomainActive || !cloudflareCustomHostname ? (
                                                <Link target="_blank" to={`https://${domainName}`}>
                                                    <Button size="xs">Visit</Button>
                                                </Link>
                                            ) : (
                                                <Button
                                                    size="xs"
                                                    onClick={refetchDomains}
                                                    isDisabled={isRefetchingDomains}
                                                    isLoading={isRefetchingDomains}>
                                                    Refresh Verification Status
                                                </Button>
                                            )}
                                        </div>
                                        {isCustomHostname && (
                                            <Menu as="div" className="relative flex-none">
                                                <MenuButton className="-m-2.5 block p-2.5 hover:text-gray-900">
                                                    <span className="sr-only">Open options</span>
                                                    <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5 text-white" />
                                                </MenuButton>
                                                <MenuItems
                                                    transition
                                                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                                                    <MenuItem>
                                                        <Typography
                                                            className="block px-3 !text-black py-1 text-sm data-[focus]:bg-gray-50 cursor-pointer"
                                                            onClick={() => onClickDeleteDomain(domainName)}>
                                                            Delete<span className="sr-only">, {domainName}</span>
                                                        </Typography>
                                                    </MenuItem>
                                                </MenuItems>
                                            </Menu>
                                        )}
                                    </div>
                                </BaseCard>
                            );
                        },
                    )}
                </div>
                <ConfirmationModal
                    isOpen={!!isShowConfirmationModal}
                    onClose={() => setIsShowConfirmationModal(null)}
                    {...isShowConfirmationModal}
                />

                <NewDomainModal isOpen={isNewDomainModalOpen} onClose={() => setIsNewDomainModalOpen(false)} />
            </PageLoader>
        </VendorsLoadingLayout>
    );
};

export default DomainsPage;
