import { ComponentPropsWithRef } from 'react';
import classNames from '../../utils/classNames.ts';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

export const Typography = ({
    isPrimary,
    isSecondary,
    isTertiary,
    isHeading,
    isError,
    isCopyEnabled,
    inlineBlock,
    block = true,
    className,
    style,
    children,
    ref,
    ...props
}: ComponentPropsWithRef<'span'> & {
    isPrimary?: boolean;
    isSecondary?: boolean;
    isTertiary?: boolean;
    isHeading?: boolean;
    isError?: boolean;
    isCopyEnabled?: boolean;
    inlineBlock?: boolean;
    block?: boolean;
}) => {
    const secondaryTextStyleClassNames = 'text-secondary-text text-sm font-medium';
    let textStyleClassNames = secondaryTextStyleClassNames; // default is secondary

    if (isPrimary) textStyleClassNames = 'text-primary-text text-base font-semibold';
    if (isSecondary) textStyleClassNames = secondaryTextStyleClassNames;
    if (isTertiary) textStyleClassNames = 'text-tertiary-text text-xs';
    if (isHeading) textStyleClassNames = 'text-primary-text text-xl font-semibold';
    if (isError) textStyleClassNames = 'text-error-text text-xs font-semibold';

    const onClick = () => {
        if (!isCopyEnabled && typeof children === 'string' && children?.length > 0) return;
        navigator.clipboard.writeText(children as string);
        toast.success(`"${children}" copied to clipboard.`);
    };

    return (
        <span
            style={style}
            className={classNames(
                isCopyEnabled && 'flex items-center gap-1 cursor-pointer',
                inlineBlock && 'inline-block',
                block && 'block',
                textStyleClassNames,
                className,
            )}
            {...props}
            ref={ref}
            {...(isCopyEnabled && { onClick })}>
            {children}
            {isCopyEnabled && children && (
                <ClipboardDocumentListIcon className="h-4 w-4 hover:opacity-50" aria-hidden="true" />
            )}
        </span>
    );
};

export default Typography;
