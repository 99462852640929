import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Typography from '../../Typography';
import BaseCard from '../../_cards/BaseCard';

const BaseModal = ({
    isOpen,
    onClose,
    title,
    subtitle,
    children,
}: {
    isOpen?: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    children: ReactNode;
}) => {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[150]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 transition-opacity bg-black bg-opacity-50" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 sm:p-0 items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative transform transition-all">
                                <BaseCard className="bg-card-background-dark">
                                    <div className="absolute right-0 top-0 pr-4 pt-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-white focus:ring-offset-2"
                                            onClick={onClose}>
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div>
                                        <Dialog.Title>
                                            <Typography isHeading>{title}</Typography>
                                        </Dialog.Title>
                                        <Dialog.Description>
                                            <Typography isTertiary>{subtitle}</Typography>
                                        </Dialog.Description>
                                        {children}
                                    </div>
                                </BaseCard>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default BaseModal;
