import { ReactNode } from 'react';
import Loader from '../Loader';

export default ({ isLoading = false, children }: { isLoading?: boolean; children?: ReactNode }) =>
    isLoading ? (
        <div className="h-96 w-full flex items-center justify-center">
            <Loader />
        </div>
    ) : (
        children
    );
