import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import OtpInput from 'react-otp-input';
import SignInIssuesButton from '../../components/SignInIssuesButton';
import Typography from '../../components/Typography';
import Input from '../../components/Input';

const REMEMBER_EMAIL = 'REMEMBER_EMAIL';
const REMEMBER_ME = 'REMEMBER_ME';
const SignInPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { signInPasswordless, verifySignInCode } = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState((searchParams.get('email') || localStorage.getItem(REMEMBER_EMAIL)) ?? '');
    const isValidEmail = email && email.includes('@') && email.includes('.');

    const [rememberMe, setRememberMe] = useState(localStorage.getItem(REMEMBER_ME) === 'true');
    const [code, setCode] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [unverifiedUser, setUnverifiedUser] = useState(undefined as CognitoUser | undefined);
    const { cognitoUser } = useAuth();

    useEffect(() => {
        localStorage.setItem(REMEMBER_ME, rememberMe.toString());
    }, [rememberMe]);

    useEffect(() => {
        if (cognitoUser) navigate('/');
    }, [cognitoUser]);

    const onClickSignIn = async () => {
        try {
            setIsSigningIn(true);
            setErrorMessage('');
            if (unverifiedUser) {
                const user = await verifySignInCode({ unverifiedUser, code });
                if (user) navigate('/', { replace: true });
            } else {
                if (rememberMe) localStorage.setItem(REMEMBER_EMAIL, email);
                const user = await signInPasswordless({ email });
                setUnverifiedUser(user);
            }
        } catch (err) {
            const error = err as { message: string };
            console.log('Something went wrong!', error);
            setErrorMessage(error.message as string);
        } finally {
            setIsSigningIn(false);
        }
    };

    return (
        <div className="flex min-h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
            <div className="space-y-6 w-full max-w-sm flex flex-col justify-center items-center text-center">
                <img className="mx-auto w-64 h-auto" src="/pablo-logo.svg" alt="Genflow" />
                <Typography isHeading>
                    {t('Sign in to your account')}
                </Typography>
                {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                {!unverifiedUser && (
                    <div>
                        <label htmlFor="email-address" className="sr-only">
                            {t('Email address')}
                        </label>
                        <Input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="relative block text-center"
                            containerClassName='w-80'
                            placeholder={t('Email address')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                )}
                {unverifiedUser && (
                    <div className="flex flex-col items-center justify-center gap-4">
                        <Typography>{`${t('Enter verification code sent to')}`}</Typography>
                        <Typography isPrimary>{email}</Typography>
                        <OtpInput
                            inputType="tel"
                            placeholder="000000"
                            numInputs={6}
                            onChange={setCode}
                            value={code}
                            shouldAutoFocus
                            renderInput={(props) => <input {...props} />}
                            inputStyle="bg-card-background p-0 h-10 rounded-md font-mono mx-1 border-2 border-gray-600 shadow-lg text-3xl text-white"
                        />
                    </div>
                )}

                <div className="flex items-center gap-2">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded-md"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <Typography>{t('Remember me')}</Typography>
                </div>

                <div className="flex flex-col items-center gap-4">
                    <Button
                        isPrimary
                        isDisabled={!isValidEmail || isSigningIn}
                        onClick={onClickSignIn}
                        isLoading={isSigningIn}>
                        {isSigningIn ? t('Signing in...') : t('Sign In')}
                    </Button>
                    <SignInIssuesButton />
                </div>

                <Typography>
                    {`${t('Not a member?')} `}
                    {/*<a*/}
                    {/*    href={marketingSiteUrl}*/}
                    {/*    className="font-semibold hover:text-indigo-500"*/}
                    {/*    style={{ color: theme?.colours?.primaryColour }}>*/}
                    {/*    {t('Get Access Now')}*/}
                    {/*</a>*/}
                </Typography>
            </div>
        </div>
    );
};

export default SignInPage;
