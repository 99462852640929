import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import PageHeading from '../../components/PageHeading';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import PageLoader from '../../components/PageLoader';
import ThemedSelect from '../../components/ThemedSelect';
import {
    VendorProductQuerySortByOptions,
    VendorProductQuerySortDirections,
    VendorProductStages,
} from '../../types/vendor-products-manager-provider.ts';
import { useVendorProductsManager } from '../../providers/VendorProductsManagerProvider';
import { sortByOptions, sortDirectionOptions } from '../../consts/digital-products.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import serveMediaLibraryImage from '../../utils/serveMediaLibraryImage.ts';
import moment from 'moment';
import ProductTypePill from '../../components/Pill/ProductTypePill';
import StagePill from '../../components/Pill/StagePill';
import NewProductModal from '../../components/_modals/NewProductModal';
import ConfirmationModal from '../../components/_modals/ConfirmationModal';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Typography from '../../components/Typography';
import BaseCard from '../../components/_cards/BaseCard';
import Button from '../../components/Button';

const DigitalProductsPage = () => {
    const [sortDirection, setSortDirection] = useState({
        value: 'DESC',
        label: 'Descending',
    });
    const [sortBy, setSortBy] = useState({
        value: 'CREATED_AT',
        label: 'Created At',
    });
    const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );

    const { isFetchingProducts, products, createUpdateProducts, deleteProducts } = useVendorProductsManager({
        initialProductsQuery: {
            limit: 100,
            sortBy: sortBy?.value as VendorProductQuerySortByOptions,
            sortDirection: sortDirection?.value as VendorProductQuerySortDirections,
        },
    });

    const onUpdateStage = async ({ SK, stage }: { SK?: string; stage: VendorProductStages }) => {
        if (SK)
            await createUpdateProducts({
                products: [
                    {
                        SK: SK as string,
                        stage,
                    },
                ],
            });
    };

    const onClickDeleteProduct = async ({ SK, title }: { SK: string; title: string }) => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete the product "${title}"?`,
            onConfirm: async () => {
                if (SK)
                    await deleteProducts({
                        products: [{ SK }],
                    });
            },
        });
    };

    const [sentryRef] = useInfiniteScroll({
        loading: isFetchingProducts,
        hasNextPage: !!products?.cursor,
        onLoadMore: products?.fetchMore,
        // disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Digital Products"
                subtitle="Create and manage your digital products."
                actions={[
                    {
                        title: 'New Product',
                        isPrimary: true,
                        leftIcon: <PlusIcon className={`h-4 w-4`} aria-hidden="true" />,
                        onClick: () => {
                            setIsNewProductModalOpen(true);
                        },
                    },
                ]}
            />

            <div className="mt-6 flex flex-col md:flex-row flex-wrap gap-6">
                <div className="flex flex-row items-center w-full gap-4">
                    <ThemedSelect
                        defaultValue={sortBy}
                        onChange={(e) => e && setSortBy({ value: e.value, label: e.label })}
                        options={sortByOptions?.filter((option) => option.value !== sortBy?.value)}
                        isSearchable={false}
                    />
                    <ThemedSelect
                        defaultValue={sortDirection}
                        onChange={(e) => e && setSortDirection({ value: e.value, label: e.label })}
                        options={sortDirectionOptions?.filter((option) => option.value !== sortDirection?.value)}
                        isSearchable={false}
                    />
                </div>
                <PageLoader isLoading={isFetchingProducts}>
                    <div className="w-full flex flex-col gap-4">
                        {products?.items?.map(
                            ({ SK, title, description, images, createdAt, stage, creatorCognitoUser, type }) => (
                                <BaseCard key={SK} className="w-full flex flex-col lg:flex-row lg:items-center gap-x-0 lg:gap-x-6 py-5">
                                    <div className="w-[64px]">
                                        {images?.cover && (
                                            <img
                                                src={serveMediaLibraryImage(images?.cover, {
                                                    width: 256,
                                                    format: 'png',
                                                    // greyscale: true,
                                                })}
                                                className="flex-1 object-contain"
                                            />
                                        )}
                                    </div>
                                    <div className="flex-1">
                                        <Typography isPrimary>{title}</Typography>
                                        <Typography isTertiary className="text-sm font-extralight mb-2 text-gray-900">
                                            {description}
                                        </Typography>
                                        <div className="flex flex-row items-center gap-2">
                                            <ProductTypePill type={type} />
                                            <StagePill
                                                isDropdown
                                                stage={stage}
                                                onUpdateStage={(stage: VendorProductStages) =>
                                                    onUpdateStage({
                                                        SK,
                                                        stage,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="mt-2 flex flex-col lg:flex-row items-start lg:items-center lg:gap-x-1 text-xs font-thin">
                                            <Typography isTertiary className="whitespace-nowrap">
                                                Created{' '}
                                                <time dateTime={createdAt}>
                                                    {moment(createdAt).format('DD MMM YYYY  hh:mm a')}
                                                </time>
                                            </Typography>

                                            {creatorCognitoUser?.attributes?.sub && (
                                                <Typography isTertiary className="truncate">
                                                    {`by ${creatorCognitoUser?.attributes?.givenName} ${creatorCognitoUser?.attributes?.familyName} (${creatorCognitoUser?.attributes?.email})`.trim()}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-none self-start items-center gap-x-4">
                                        <Button isSecondary to={`/digital-products/${SK}`}>
                                            Manage<span className="sr-only">, {title}</span>
                                        </Button>
                                        <Menu as="div" className="relative flex-none">
                                            <MenuButton className="-m-2.5 block p-2.5 text-white">
                                                <span className="sr-only">Open options</span>
                                                <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                                            </MenuButton>
                                            <MenuItems
                                                transition
                                                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                                                {[
                                                    {
                                                        label: 'Delete',
                                                        onClick: () =>
                                                            onClickDeleteProduct({
                                                                SK: SK as string,
                                                                title: title as string,
                                                            }),
                                                    },
                                                ].map(({ label, onClick }) => (
                                                    <MenuItem key={label}>
                                                        <Typography
                                                            className="block px-3 py-1 text-sm !text-black data-[focus]:bg-gray-50 cursor-pointer"
                                                            onClick={onClick}>
                                                            {label}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </MenuItems>
                                        </Menu>
                                    </div>
                                </BaseCard>
                            ),
                        )}

                        {(isFetchingProducts || !!products?.cursor) && <div ref={sentryRef}>Loading...</div>}
                    </div>
                </PageLoader>
            </div>

            <NewProductModal isOpen={isNewProductModalOpen} onClose={() => setIsNewProductModalOpen(false)} />

            <ConfirmationModal
                isOpen={!!isShowConfirmationModal}
                onClose={() => setIsShowConfirmationModal(null)}
                {...isShowConfirmationModal}
            />
        </VendorsLoadingLayout>
    );
};
export default DigitalProductsPage;
