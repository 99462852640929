import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import searchVendorUserPermissionsQuery from '../../api/vendor/searchVendorUserPermissionsQuery.graphql';
import getVendorConfigQuery from '../../api/vendor/getVendorConfigQuery.graphql';
import { Vendor } from '../../types/vendor.ts';
import { VendorManagerProvider } from '../../types/vendor-manager-provider.ts';
import { useAuth } from '../AuthProvider';

// Provider definition
const defaultContext = {} as VendorManagerProvider;
const VendorManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorManagerContext;
const VendorManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { cognitoUser } = useAuth();
    const [currentVendorUuid, setCurrentVendorUuid] = useState(null as string | null);
    const [currentVendor, setCurrentVendor] = useState(null as Vendor | null);
    const [vendors, setVendors] = useState([] as Vendor[]);
    const [searchQuery, setSearchQuery] = useState('');
    const searchVendors = (query: string) => setSearchQuery(query);
    const selectVendor = (vendorUuid: string) => {
        const foundVendor = vendors.find((vendor) => vendor?.vendorUuid === vendorUuid) || null;
        if (foundVendor) setCurrentVendorUuid(foundVendor.vendorUuid);
    };

    const { loading: fetchingVendors, data: vendorsData } = useQuery(searchVendorUserPermissionsQuery, {
        variables: {
            ...(searchQuery ? { searchQuery } : {}),
        },
        skip: !cognitoUser,
    });

    const { loading: fetchingVendor, data: vendorData } = useQuery(getVendorConfigQuery, {
        variables: {
            vendorUuid: currentVendorUuid,
        },
        skip: !currentVendorUuid,
    });

    useEffect(() => {
        if (!fetchingVendors && vendorsData) {
            const networkVendors = (vendorsData?.searchVendorUserPermissions?.data as Vendor[])
                ?.slice()
                ?.sort(({ brandName: a }, { brandName: b }) => a.localeCompare(b));
            setVendors(networkVendors as Vendor[]);
        }
    }, [fetchingVendors, vendorsData, currentVendor]);

    useEffect(() => {
        if (vendors?.length > 0 && !currentVendorUuid) {
            let localVendorUuid = localStorage.getItem('currentVendorUuid') as string | null;
            if (localVendorUuid && !vendors.find((vendor) => vendor.vendorUuid === localVendorUuid)) {
                localStorage.removeItem('currentVendorUuid');
                localVendorUuid = null;
            }

            setCurrentVendorUuid(localVendorUuid || vendors[0].vendorUuid);
        }
    }, [vendors, currentVendorUuid]);

    useEffect(() => {
        if (!fetchingVendor && vendorData) {
            const networkVendor = vendorData?.getVendorConfig?.data as Vendor;
            setCurrentVendor(networkVendor);
            localStorage.setItem('currentVendorUuid', networkVendor.vendorUuid);
        }
    }, [fetchingVendor, vendorData]);

    return (
        <Provider
            value={{
                fetchingVendors,
                fetchingVendor,
                currentVendor,
                selectVendor,
                vendors,
                searchVendors,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorManager = () => {
    const functions = useContext(VendorManagerContext);

    return functions;
};

export { VendorManagerProvider, ConfigConsumer, useVendorManager };
