import { ComponentPropsWithRef } from 'react';
import { useQuery } from '@apollo/client';
import getVendorContentItemViewDetailsQuery from '../../api/content/getVendorContentItemViewDetailsQuery.graphql';
import BaseCard from '../_cards/BaseCard';
import { VendorContentItem } from '../../types/vendor-content.ts';
import { useVendorManager } from '../../providers/VendorManagerProvider';
import Loader from '../Loader';

export default ({
    contentItem,
    token,
    ...iframeProps
}: {
    contentItem: VendorContentItem;
    token?: string;
    iframeProps?: ComponentPropsWithRef<'iframe'>;
}) => {
    const { currentVendor } = useVendorManager();

    const { data: videoTokenData, loading: isLoading } = useQuery(getVendorContentItemViewDetailsQuery, {
        variables: { vendorUuid: currentVendor?.vendorUuid, contentId: contentItem?.SK },
        skip: !currentVendor?.vendorUuid || !contentItem?.SK || !!token,
        fetchPolicy: 'network-only',
    });
    const { token: videoToken } = videoTokenData?.getVendorContentItemViewDetails?.data || {};

    const isPlaybackReady = !(isLoading || !(videoToken || token));

    return (
        <BaseCard className="overflow-hidden relative w-full h-full aspect-video !bg-black">
            <iframe
                key={JSON.stringify(contentItem?.SK)}
                src={
                    isPlaybackReady
                        ? `https://player1.vdocipher.com/v2/?videoId=${contentItem?.vdoCipherVideoData?.id}&token=${
                              token || videoToken
                          }`
                        : ''
                }
                style={{
                    border: 0,
                    maxWidth: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                }}
                allowFullScreen={true}
                allow="encrypted-media"
                {...iframeProps}
            />

            {!isPlaybackReady && (
                <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                    <Loader />
                </div>
            )}
        </BaseCard>
    );
};
