import { XMarkIcon } from '@heroicons/react/24/outline';
import { HTMLAttributes } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react';
import classNames from '../../../utils/classNames.ts';
import BaseCard from '../../_cards/BaseCard';

const BaseDrawer = ({
    isOpen,
    onClose,
    children,
    className,
    ...props
}: {
    isOpen?: boolean;
    onClose: () => void;
} & HTMLAttributes<HTMLDivElement>) => (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[100]">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <DialogPanel
                        transition
                        className="pointer-events-auto relative transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                        <TransitionChild>
                            <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                </button>
                            </div>
                        </TransitionChild>
                        <BaseCard className="h-full overflow-y-auto bg-card-background-dark rounded-none">
                            <div className={classNames(className)} {...props}>
                                {children}
                            </div>
                        </BaseCard>
                    </DialogPanel>
                </div>
            </div>
        </div>
    </Dialog>
);

export default BaseDrawer;
