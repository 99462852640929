import BaseModal from '../_BaseModal';
import Typography from '../../Typography';

const SignInIssuesModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    return (
        <BaseModal onClose={onClose} isOpen={isOpen} title="Resolving sign in issues">
            <div className="mt-4 text-sm flex flex-col max-w-screen lg:max-w-lg">
                <Typography>
                    If you are facing any sign in issues, there may be some simple steps to resolve this.
                </Typography>
                <Typography isPrimary className="mt-4 !font-bold">
                    Incorrect Computer Clock Time
                </Typography>
                <Typography>
                    For security reasons (to prevent{' '}
                    <a href="https://en.wikipedia.org/wiki/Replay_attack" target="_blank" className="underline">
                        replay attacks
                    </a>
                    ), your computer clock MUST be in sync and the best way to do this is to set it to automatic.
                </Typography>
                <Typography className="mt-2">
                    If you have manually selected your clock time, it could have drifted out of sync and that is why you
                    may be facing sign in issues.
                </Typography>
                <Typography className="mt-2">
                    Set your computer clock to automatic, refresh this page and try again.
                </Typography>
                {/*<Typography isTertiary className="mt-6">*/}
                {/*    Still not able to sign in? Contact us at{' '}*/}
                {/*    {customerSupport?.helpCenterUrl && (*/}
                {/*        <Typography*/}
                {/*            isPrimary*/}
                {/*            style={{*/}
                {/*                color: theme?.colours?.primaryColours?.[0],*/}
                {/*            }}>*/}
                {/*            <a href={`${customerSupport?.helpCenterUrl}`} className="underline" target='_blank'>*/}
                {/*                {customerSupport?.helpCenterUrl}*/}
                {/*            </a>*/}
                {/*        </Typography>*/}
                {/*    )}*/}
                {/*    {customerSupport?.email && (*/}
                {/*        <Typography*/}
                {/*            style={{*/}
                {/*                color: theme?.colours?.primaryColours?.[0],*/}
                {/*            }}>*/}
                {/*            <a href={`mailto:${customerSupport?.email}`} className="underline" target='_blank'>*/}
                {/*                {customerSupport?.email}*/}
                {/*            </a>*/}
                {/*        </Typography>*/}
                {/*    )}{' '}*/}
                {/*    so that we can resolve this for you quickly!*/}
                {/*</Typography>*/}
            </div>
        </BaseModal>
    );
};

export default SignInIssuesModal;
