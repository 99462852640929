import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useVendorManager } from '../VendorManagerProvider';
import { VendorCommunitiesManagerProvider } from '../../types/vendor-communities-manager-provider.ts';
import getVendorCommunitiesQuery from '../../api/communities/getVendorCommunitiesQuery.graphql';
import connectVendorCommunityMutation from '../../api/communities/connectVendorCommunityMutation.graphql';
import disconnectVendorCommunityMutation from '../../api/communities/disconnectVendorCommunityMutation.graphql';
import { VendorCommunityItem } from '../../types/vendor-communities.ts';

// Provider definition
const defaultContext = {} as VendorCommunitiesManagerProvider;
const VendorCommunitiesManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorCommunitiesManagerContext;
const VendorCommunitiesManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { currentVendor } = useVendorManager();

    const { client, loading, data } = useQuery(getVendorCommunitiesQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
        },
        skip: !currentVendor?.vendorUuid,
    });

    const [connectVendorCommunity, { loading: isConnectingCommunity }] = useMutation(connectVendorCommunityMutation);
    const [disconnectVendorCommunity, { loading: isDisconnectingCommunity }] = useMutation(
        disconnectVendorCommunityMutation,
    );

    const [communities, setCommunities] = useState(data?.getVendorCommunities?.data as VendorCommunityItem[]);

    useEffect(() => {
        setCommunities(data?.getVendorCommunities?.data);
    }, [data]);

    const connectCommunity = async ({ communityType }: { communityType: 'discord' | 'slack' }) => {
        const response = await connectVendorCommunity({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                communityType,
            },
        });

        await refetchCommunityItems();

        return response?.data?.connectVendorCommunity?.data?.redirectUrl;
    };

    const disconnectCommunity = async ({
        communityType,
        communityId,
    }: {
        communityType: 'discord' | 'slack';
        communityId: string;
    }) => {
        await disconnectVendorCommunity({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                communityType,
                communityId,
            },
        });

        await refetchCommunityItems();
    };

    const refetchCommunityItems = async () =>
        client.refetchQueries({
            include: [getVendorCommunitiesQuery],
        });

    return (
        <Provider
            value={{
                isFetchingCommunities: loading,
                communities,
                refetchCommunityItems,
                isConnectingCommunity,
                connectCommunity,
                isDisconnectingCommunity,
                disconnectCommunity,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorCommunitiesManager = () => {
    const functions = useContext(VendorCommunitiesManagerContext);

    return functions;
};

export { VendorCommunitiesManagerProvider, ConfigConsumer, useVendorCommunitiesManager };
