import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import getVendorProductsQuery from '../../api/products/getVendorProductsQuery.graphql';
import getVendorProductQuery from '../../api/products/getVendorProductQuery.graphql';
import getVendorProductItemQuery from '../../api/products/getVendorProductItemQuery.graphql';
import createUpdateVendorProductItemsMutation from '../../api/products/createUpdateVendorProductItemsMutation.graphql';
import deleteVendorProductItemsMutation from '../../api/products/deleteVendorProductItemsMutation.graphql';
import { useVendorManager } from '../VendorManagerProvider';
import { isEmpty, isEqual } from 'lodash-es';
import {
    VendorProductItemQuery,
    VendorProductQuery,
    VendorProductsManagerProvider,
    VendorProductsQuery,
} from '../../types/vendor-products-manager-provider.ts';
import { VendorProduct, VendorProductItem } from '../../types/vendor-product.ts';
import { toast } from 'react-toastify';

// Provider definition
const defaultContext = {} as VendorProductsManagerProvider;
const VendorProductsManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorProductsManagerContext;
const VendorProductsManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { currentVendor } = useVendorManager();
    const [productsQuery, setProductsQuery] = useState({} as VendorProductsQuery);
    const [productQuery, setProductQuery] = useState({} as VendorProductQuery);
    const [productItemQuery, setProductItemQuery] = useState({} as VendorProductItemQuery);

    const updateProductsQuery = (config: VendorProductsQuery) => {
        if (!isEqual(config, productsQuery)) setProductsQuery(config);
    };
    const updateProductQuery = (config: VendorProductQuery) => {
        if (!isEqual(config, productQuery)) setProductQuery(config);
    };
    const updateProductItemQuery = (config: VendorProductItemQuery) => {
        if (!isEqual(config, productItemQuery)) setProductItemQuery(config);
    };

    const [productsData, setProductsData] = useState({
        items: [] as VendorProduct[],
        count: 0,
        cursor: undefined,
    });

    const [productData, setProductData] = useState(undefined as VendorProduct | undefined);
    const [productItemData, setProductItemData] = useState(undefined as VendorProductItem | undefined);

    const {
        client,
        loading: isFetchingProducts,
        data: productsQueryData,
        fetchMore,
    } = useQuery(getVendorProductsQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            query: productsQuery,
        },
        skip: !currentVendor?.vendorUuid || isEmpty(productsQuery),
    });

    const { loading: isFetchingProduct, data: productQueryData } = useQuery(getVendorProductQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            productId: productQuery?.productId,
            stage: productQuery?.stage,
        },
        skip: !currentVendor?.vendorUuid || !productQuery?.productId,
    });

    const { loading: isFetchingProductItem, data: productItemQueryData } = useQuery(getVendorProductItemQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            productItemId: productItemQuery?.productItemId,
        },
        skip: !currentVendor?.vendorUuid || !productItemQuery?.productItemId,
    });

    useEffect(() => {
        setProductsData(productsQueryData?.getVendorProducts?.data);
    }, [productsQueryData]);

    useEffect(() => {
        setProductData(productQueryData?.getVendorProduct?.data);
    }, [productQueryData]);

    useEffect(() => {
        setProductItemData(productItemQueryData?.getVendorProductItem?.data);
    }, [productItemQueryData]);

    const [createUpdateVendorProductItems, { loading: isCreatingUpdatingProducts }] = useMutation(
        createUpdateVendorProductItemsMutation,
    );
    const [deleteVendorProductItems, { loading: isDeletingProducts }] = useMutation(deleteVendorProductItemsMutation);

    const createUpdateProducts = async ({
        products,
        productItems,
        options = { isNotificationEnabled: true },
    }: {
        products?: VendorProduct[];
        productItems?: VendorProductItem[];
        options?: {
            isNotificationEnabled?: boolean;
        };
    }) => {
        const response = await createUpdateVendorProductItems({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                products,
                productItems,
            },
        });

        if (products !== undefined && products?.length > 0) await refetchProducts();
        if (
            (products !== undefined && products?.length > 0) ||
            (productItems !== undefined && productItems?.length > 0)
        )
            await refetchProduct();

        if (options?.isNotificationEnabled && products !== undefined && products?.length > 0)
            if (products?.some(({ SK }) => SK)) toast.success(`Product${products?.length > 1 ? 's' : ''} updated.`);
            else toast.success(`New product${products?.length > 1 ? 's' : ''} created.`);

        if (options?.isNotificationEnabled && productItems !== undefined && productItems?.length > 0)
            if (productItems?.some(({ SK }) => SK)) toast.success(`Product content updated.`);
            else toast.success(`New product content created.`);

        return response?.data?.createUpdateVendorProductItems?.data;
    };

    const deleteProducts = async ({
        products,
        productItems,
    }: {
        products?: { SK: string }[];
        productItems?: { SK: string }[];
    }) => {
        const response = await deleteVendorProductItems({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                productIds: products?.map(({ SK }) => SK),
                productItemIds: productItems?.map(({ SK }) => SK),
            },
        });

        if (products !== undefined && products?.length > 0) await refetchProducts();
        if (productItems !== undefined && productItems?.length > 0) await refetchProduct();

        if (products !== undefined && products?.length > 0)
            toast.success(`Product${products?.length > 1 ? 's' : ''} deleted.`);
        if (productItems !== undefined && productItems?.length > 0) toast.success(`Product content deleted.`);
        return response?.data?.deleteVendorProductItems?.data;
    };

    const products = {
        items: productsData?.items as VendorProduct[],
        count: productsData?.count,
        cursor: productsData?.cursor,
        fetchMore: async () => {
            if (productsData?.cursor)
                await fetchMore({
                    variables: {
                        vendorUuid: currentVendor?.vendorUuid,
                        query: {
                            cursor: productsData?.cursor,
                            limit: productsQuery?.limit,
                            sortBy: productsQuery?.sortBy,
                            sortDirection: productsQuery?.sortDirection,
                        },
                    },
                });
        },
    };

    const refetchProducts = async () =>
        client.refetchQueries({
            include: [getVendorProductsQuery],
        });

    const refetchProduct = async () =>
        client.refetchQueries({
            include: [getVendorProductQuery, getVendorProductItemQuery],
        });

    return (
        <Provider
            value={{
                updateProductsQuery,
                updateProductQuery,
                updateProductItemQuery,
                isFetchingProducts,
                products,
                isFetchingProduct,
                product: productData,
                isFetchingProductItem,
                productItem: productItemData,
                createUpdateProducts,
                isCreatingUpdatingProducts,
                deleteProducts,
                isDeletingProducts,
                // updateContent,
                // isUpdatingContent,
                refetchProducts,
                refetchProduct,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorProductsManager = (config?: { initialProductsQuery: VendorProductsQuery }) => {
    const { updateProductsQuery, ...functions } = useContext(VendorProductsManagerContext);
    useEffect(() => {
        if (config?.initialProductsQuery) updateProductsQuery(config?.initialProductsQuery);
    }, [config]);

    return { ...functions, updateProductsQuery };
};

export { VendorProductsManagerProvider, ConfigConsumer, useVendorProductsManager };
