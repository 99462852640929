import BaseModal from '../_BaseModal';
import { useEffect, useRef, useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { VendorProductTypeOptions } from '../../../types/vendor-product.ts';
import Button from '../../Button';
import classNames from '../../../utils/classNames.ts';
import { useVendorProductsManager } from '../../../providers/VendorProductsManagerProvider';
import { useNavigate } from 'react-router-dom';
import BaseCard from '../../_cards/BaseCard';
import Typography from '../../Typography';
import Input from '../../Input';
import { LockClosedIcon } from '@heroicons/react/24/outline';

const PRODUCT_TYPES_OPTIONS = [
    {
        id: 'COURSE',
        title: 'Course',
        description: 'Structured educational content to teach a skill or subject.',
    },
    {
        id: 'SHORT_FORM_COURSE',
        title: 'Short Form Course',
        description: 'Concise, bite-sized educational content for quick learning.',
        isLocked: true,
    },
    {
        id: 'DOWNLOADABLE',
        title: 'Downloadable',
        description: 'One or more digital downloadable files. ',
        isLocked: true,
    },
    {
        id: 'WEBINAR',
        title: 'Webinar',
        description: 'Access to a webinar e.g. Zoom, YouTube',
        isLocked: true,
    },
    {
        id: 'COACHING',
        title: 'Coaching',
        description: 'Virtual coaching sessions e.g. one on one, cohort, etc.',
        isLocked: true,
    },
    {
        id: 'AUDIOBOOK',
        title: 'Audiobook',
        description: 'Create a book with audio content.',
        isLocked: true,
    },
    {
        id: 'FITNESS_WORKOUT',
        title: 'Fitness Workout',
        description: 'Create a fitness workout with video content.',
        isLocked: true,
    },
] as VendorProductTypeOptions[];

// audiobook, fitness course/workout

const MINIMUM_PRODUCT_TITLE_LENGTH = 5;
const NewProductModal = ({ isOpen, onClose }: { isOpen?: boolean; onClose: () => void }) => {
    const navigate = useNavigate();
    const titleInputRef = useRef<HTMLInputElement>(null);
    const [title, setTitle] = useState('');
    const [selectedProductType, setSelectedProductType] = useState(PRODUCT_TYPES_OPTIONS[0]);

    const { createUpdateProducts, isCreatingUpdatingProducts } = useVendorProductsManager();

    const onResetState = () => {
        setTitle('');
        setSelectedProductType(PRODUCT_TYPES_OPTIONS[0]);
    };

    useEffect(() => {
        if (titleInputRef?.current) titleInputRef?.current?.focus();
    }, []);

    const onClickCreate = async () => {
        if (title?.length < MINIMUM_PRODUCT_TITLE_LENGTH || !selectedProductType) return;

        const newProducts = await createUpdateProducts({
            products: [
                {
                    title,
                    type: selectedProductType.id,
                    displayOrder: -1,
                },
            ],
        });
        navigate(`/digital-products/${newProducts?.products?.[0]?.SK}`);
        onClose();
        onResetState();
    };

    return (
        <BaseModal title="New Digital Product" subtitle="Create new digital product." isOpen={isOpen} onClose={onClose}>
            <div className="mt-4 flex flex-col gap-4 lg:max-w-2xl">
                <div className="w-3/5">
                    <label htmlFor="email-address" className="sr-only">
                        Product Title
                    </label>
                    <Input
                        ref={titleInputRef}
                        id="product-title"
                        name="product-title"
                        type="text"
                        autoComplete="false"
                        placeholder="Product title..."
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <RadioGroup
                    value={selectedProductType?.id}
                    onChange={(id) =>
                        setSelectedProductType(
                            PRODUCT_TYPES_OPTIONS.find((type) => type.id === id) as VendorProductTypeOptions,
                        )
                    }
                    className="grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-4">
                    {PRODUCT_TYPES_OPTIONS.map(({ id, title, description, isLocked }) => (
                        <Radio
                            key={id}
                            value={id}
                            aria-label={title}
                            disabled={isLocked}
                            aria-description={description}
                            className="group relative cursor-pointer">
                            <BaseCard className="flex h-full">
                                <span className="flex flex-1">
                                    <span className="flex flex-col">
                                        <Typography isPrimary className="block">
                                            {title}
                                        </Typography>
                                        <Typography isTertiary className="mt-1 flex items-center">
                                            {description}
                                        </Typography>
                                        {/*<span className="mt-6 text-sm font-medium text-gray-900">{mailingList.users}</span>*/}
                                    </span>
                                </span>
                                {isLocked ? (
                                    <LockClosedIcon className={'h-6 w-6 text-gray-500'} aria-hidden="true" />
                                ) : (
                                    <CheckCircleIcon
                                        aria-hidden="true"
                                        className="h-5 w-5 text-white [.group:not([data-checked])_&]:invisible"
                                    />
                                )}

                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute -inset-px rounded-md border border-transparent group-data-[focus]:border group-data-[checked]:border-white"
                                />
                            </BaseCard>
                        </Radio>
                    ))}
                </RadioGroup>
                <div className={classNames('mt-4 flex flex-row items-center gap-4 justify-end')}>
                    <Button
                        isPrimary
                        isLoading={isCreatingUpdatingProducts}
                        isDisabled={
                            title?.length < MINIMUM_PRODUCT_TITLE_LENGTH ||
                            !selectedProductType ||
                            isCreatingUpdatingProducts
                        }
                        onClick={onClickCreate}>
                        Create Product
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default NewProductModal;
