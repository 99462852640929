import Button from '../../components/Button';
import Typography from '../../components/Typography';

const NotFoundPage = () => (
    <div className="min-h-screen text-center flex flex-col justify-center items-center space-y-6">
        <div>
            <Typography isHeading>404</Typography>
            <Typography isHeading>Page not found</Typography>
        </div>
        <Typography>Sorry, we couldn’t find the page you’re looking for.</Typography>
        <Button to="/">Go back home</Button>
    </div>
);

export default NotFoundPage;
