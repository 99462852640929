import Button from '../../Button';
import BaseModal from '../_BaseModal';
import 'react-fontpicker-ts/dist/index.css';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from '../../../utils/classNames.ts';
import { TrashIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useVendorMediaManager } from '../../../providers/VendorMediaManagerProvider';
import Typography from '../../Typography';

const MediaLibraryUploadModal = ({
    isOpen,
    onClose,
    title = 'Upload new media',
}: {
    isOpen?: boolean;
    onClose: () => void;
    title?: string;
}) => {
    const [files, setFiles] = useState(
        [] as (File & {
            preview: string;
        })[],
    );
    const { isUploading, uploadMedia, mediaUploadProgress } = useVendorMediaManager();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setFiles([
                ...files,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            ]);
        },
        [files],
    );
    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: { 'image/*': [] },
    });

    const onModalClose = () => {
        onClose();
        setFiles([]);
    };

    const thumbnails = files.map((file, index) => (
        <div
            key={`${file.name}-${index}`}
            className="flex items-center justify-center min-w-0 overflow-hidden relative rounded-md border-2 w-28 h-28">
            {!!mediaUploadProgress?.[index]?.percentUploaded && (
                <div
                    className="bg-green-500 h-1 absolute top-0 left-0 right-0"
                    style={{ width: `${mediaUploadProgress[index].percentUploaded}%` }}
                />
            )}

            {mediaUploadProgress?.[index]?.percentUploaded === 100 && (
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full flex items-center justify-center">
                    <CheckCircleIcon className={`h-12 w-12 shrink-0 text-green-500`} aria-hidden="true" />
                </div>
            )}

            {!mediaUploadProgress?.[index]?.percentUploaded && (
                <TrashIcon
                    className="w-4 h-4 text-white absolute top-2 right-2 cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        setFiles((prevState) => prevState.filter((_, fileIndex) => fileIndex !== index));
                    }}
                />
            )}
            <img
                className="block h-24 object-contain"
                src={file.preview}
                // Revoke data uri after image is loaded
                onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                }}
            />
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    const uploadFiles = async () => {
        await uploadMedia(files).then(onModalClose);
    };

    return (
        <BaseModal
            title={title}
            subtitle="Upload a new asset to your media library."
            isOpen={isOpen}
            onClose={onModalClose}>
            <div className="mt-4 max-w-2xl flex flex-col items-center justify-center gap-4">
                <div
                    {...getRootProps()}
                    className={classNames(
                        `flex items-center justify-center border-4 border-card-border border-dotted h-32 w-full p-4`,
                        isFocused && `border-white`,
                        isDragAccept && `border-white`,
                        isDragActive && `border-white`,
                        isDragReject && `border-red-500`,
                    )}>
                    <input {...getInputProps()} />
                    <Typography>Drag & drop your media here OR click to select files</Typography>
                </div>
                <div className="flex items-center flex-wrap gap-2">{thumbnails}</div>
            </div>
            <div className="mt-4 flex flex-row justify-end">
                <Button isPrimary isLoading={isUploading} isDisabled={!files?.length} onClick={uploadFiles}>
                    Upload
                </Button>
            </div>
        </BaseModal>
    );
};

export default MediaLibraryUploadModal;
