import PageHeading from '../../components/PageHeading';
import { useTranslation } from 'react-i18next';
import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <VendorsLoadingLayout>
            <PageHeading title={t('Home')} subtitle={t('Courses.Description')} />
            <div className="mt-8 flex flex-col gap-8"></div>
        </VendorsLoadingLayout>
    );
};
export default HomePage;
