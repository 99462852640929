import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import './quill.css';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

Bugsnag.start({
    apiKey: '904bbe8966b14c76d68f3cbd2f7cc9ec',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({
    apiKey: '904bbe8966b14c76d68f3cbd2f7cc9ec',
})
const BugsnagPlugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult;
const BugsnagErrorBoundary = BugsnagPlugin.createErrorBoundary(React);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BugsnagErrorBoundary>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </BugsnagErrorBoundary>
    </React.StrictMode>,
);
