import RecursiveProductOutlineAccordion from '../RecursiveProductOutlineAccordion';
import BaseCard from '../_cards/BaseCard';
import Typography from '../Typography';
import StagePill from '../Pill/StagePill';
import { VendorProductStages } from '../../types/vendor-products-manager-provider.ts';
import Button from '../Button';
import RichTextEditor from '../../components/RichTextEditor';
import ReorderableContentAssetsList from '../ReorderableContentAssetsList';
import Loader from '../Loader';
import { useVendorProductsManager } from '../../providers/VendorProductsManagerProvider';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { VendorProductItem } from '../../types/vendor-product.ts';
import { VendorContentItem } from '../../types/vendor-content.ts';
import { useNavigate } from 'react-router-dom';

export default ({
    productId,
    setIsShowConfirmationModal,
    setIsContentPickerModalOpen,
}: {
    productId: string;
    setIsShowConfirmationModal: Dispatch<
        SetStateAction<{
            question: string;
            onConfirm: () => Promise<void>;
        } | null>
    >;
    setIsContentPickerModalOpen: Dispatch<
        SetStateAction<{
            title: string;
            onUpdateContentItems: (contentItems: VendorContentItem[]) => boolean;
            isMultiSelectEnabled?: boolean;
        } | null>
    >;
}) => {
    const {
        isFetchingProduct,
        product,
        updateProductQuery,
        createUpdateProducts,
        isCreatingUpdatingProducts,
        deleteProducts,
        isDeletingProducts,
    } = useVendorProductsManager();
    const [description, setDescription] = useState(product?.description || '');
    const navigate = useNavigate();

    useEffect(() => {
        setDescription(product?.description || ('' as string));
    }, [product]);

    useEffect(() => {
        if (productId)
            updateProductQuery({
                productId,
            });
    }, [productId]);

    const onUpdateStage = async (stage: VendorProductStages) => {
        if (product?.SK)
            await createUpdateProducts({
                products: [
                    {
                        SK: product?.SK,
                        stage,
                    },
                ],
            });
    };

    const onClickUpdateDescription = async () => {
        if (description !== product?.description && product?.SK)
            await createUpdateProducts({
                products: [{ SK: product?.SK as string, description: description as string }],
            });
    };

    const onClickDeleteProduct = async () => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete the product "${product?.title}"?`,
            onConfirm: async () => {
                if (product?.SK) {
                    await deleteProducts({
                        products: [{ SK: product?.SK as string }],
                    });

                    navigate('/digital-products', {
                        replace: true,
                    });
                }
            },
        });
    };

    const onProductItemsReordered = async ({ reorderedItems }: { reorderedItems: VendorProductItem[] }) => {
        const assignDisplayOrder = (items: VendorProductItem[]) => {
            const flatArray = [] as VendorProductItem[];

            items.forEach((item, index) => {
                flatArray.push({
                    SK: item.SK,
                    displayOrder: index, // Assign displayOrder within the current scope
                });

                // If this item has nested "items", flatten them as well
                if (Array.isArray(item?.items)) flatArray.push(...assignDisplayOrder(item?.items)); // Recursively process nested items
            });

            return flatArray;
        };
        await createUpdateProducts({
            productItems: assignDisplayOrder(reorderedItems),
        });
    };

    const onClickAddAsset = async () => {
        if (product?.SK)
            setIsContentPickerModalOpen({
                title: 'Select content',
                isMultiSelectEnabled: true,
                onUpdateContentItems: (contentItems) => {
                    if (contentItems?.length > 0)
                        (async () => {
                            await createUpdateProducts({
                                products: [
                                    {
                                        SK: product.SK,
                                        contentAssetsSKs: [
                                            ...(product?.contentAssets?.map(({ SK }) => SK) || []),
                                            ...(contentItems.map(({ SK }) => SK) || []),
                                        ],
                                    },
                                ],
                            });
                        })();
                    return true;
                },
            });
    };

    const onContentAssetsReordered = async ({
        reorderedContentAssets,
    }: {
        reorderedContentAssets: VendorContentItem[];
    }) => {
        if (product?.SK)
            await createUpdateProducts({
                products: [
                    {
                        SK: product.SK,
                        contentAssetsSKs: reorderedContentAssets.map(({ SK }) => SK),
                    },
                ],
            });
    };

    const onDeleteContentAsset = async ({ contentAsset }: { contentAsset: VendorContentItem }) => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete the asset "${contentAsset?.fileName}"?`,
            onConfirm: async () => {
                if (product?.SK)
                    await createUpdateProducts({
                        products: [
                            {
                                SK: product.SK,
                                contentAssetsSKs: product?.contentAssets
                                    ?.filter(({ SK }) => SK !== contentAsset.SK)
                                    ?.map(({ SK }) => SK),
                            },
                        ],
                    });
            },
        });
    };

    return (
        <div className="flex flex-row items-start gap-4 ">
            <div className="flex-1 flex flex-col gap-4">
                <div className="flex-1 shadow-sm ring-1 ring-gray-900/5 rounded-lg self-stretch">
                    <RecursiveProductOutlineAccordion
                        items={product?.items || []}
                        onProductItemsReordered={onProductItemsReordered}
                    />
                </div>
            </div>

            <BaseCard className="w-64 p-4 shadow-sm ring-1 ring-gray-900/5 rounded-lg overflow-hidden flex flex-col gap-6">
                <div className="flex flex-row items-center justify-between">
                    <Typography>Product Status:</Typography>
                    <StagePill isDropdown stage={product?.stage as VendorProductStages} onUpdateStage={onUpdateStage} />
                </div>
                <div>
                    <Typography className="mb-2">Description</Typography>
                    <RichTextEditor onTextChange={setDescription} defaultValue={description} />
                    {product?.description !== description && (
                        <Button isPrimary size="xs" onClick={onClickUpdateDescription} className="ml-auto mt-4">
                            Update Description
                        </Button>
                    )}
                </div>
                <div>
                    <div className="flex flex-row items-center justify-between">
                        <Typography>Assets</Typography>

                        <Button
                            size="xs"
                            onClick={onClickAddAsset}
                            isLoading={isFetchingProduct || isCreatingUpdatingProducts}>
                            Add
                        </Button>
                    </div>
                    <ReorderableContentAssetsList
                        items={product?.contentAssets || []}
                        onContentAssetsReordered={onContentAssetsReordered}
                        onDeleteContentAsset={onDeleteContentAsset}
                    />
                </div>

                {product?.creatorCognitoUser?.attributes?.sub && (
                    <div className="text-right">
                        <Typography>
                            {`Created by ${product?.creatorCognitoUser?.attributes?.givenName} ${product?.creatorCognitoUser?.attributes?.familyName}`.trim()}
                        </Typography>
                        <Typography isTertiary>{product?.creatorCognitoUser?.attributes?.email}</Typography>
                    </div>
                )}
                <Typography
                    isTertiary
                    className="!text-red-500 text-right underline cursor-pointer flex flex-row items-center justify-end gap-2"
                    onClick={onClickDeleteProduct}>
                    Delete Product
                    {isDeletingProducts && <Loader width={15} height={15} />}
                </Typography>
            </BaseCard>
        </div>
    );
};
