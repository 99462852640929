import classNames from '../../utils/classNames.ts';
import { ReactNode } from 'react';
import { get } from 'lodash-es';
import Typography from '../Typography';

export type DataTableColumn<T> = {
    label: string;
    accessor?: string;
    render?: (row: T, index: number) => JSX.Element | string;
};

interface DataTableProps<T> {
    columns: DataTableColumn<T>[];
    data: T[];
    getRowKey: (row: T, index: number) => string | number;
    className?: string;
    onRowClick?: ({ data, index }: { data: T; index: number }) => void;
}

export default function DataTable<T>({ columns, data, getRowKey, className, onRowClick }: DataTableProps<T>) {
    if (!data?.length) return null;

    return (
        <div className={classNames('sm:mx-0 sm:rounded-lg', className)}>
            <table className="min-w-full divide-y divide-gray-600">
                <thead>
                    <tr>
                        {columns.map((col, colIndex) => (
                            <th
                                key={`header-${colIndex}`}
                                scope="col"
                                className="px-3 py-3.5 text-left">
                                <Typography>{col.label}</Typography>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr
                            key={getRowKey(row, index)}
                            className={onRowClick ? 'hover:opacity-50 cursor-pointer' : ''}
                            onClick={() => onRowClick && onRowClick({ data: row, index })}
                        >
                            {columns.map((col, colIndex) => (
                                <td
                                    key={`cell-${getRowKey(row, index)}-${colIndex}`}
                                    className={classNames(
                                        index === 0 ? '' : 'border-t border-gray-600',
                                        'px-3 py-3.5',
                                    )}>
                                   <Typography>
                                       {col.render
                                           ? col.render(row, index)
                                           : (get(row, col.accessor as keyof T) as ReactNode)}
                                   </Typography>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
