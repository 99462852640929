import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    VendorContentItemQuery,
    VendorContentManagerProvider,
    VendorContentQuery,
    VendorContentUploadProgressItem,
} from '../../types/vendor-content-manager-provider.ts';
import getVendorContentQuery from '../../api/content/getVendorContentQuery.graphql';
import getVendorContentItemQuery from '../../api/content/getVendorContentItemQuery.graphql';
import { VendorContentItem } from '../../types/vendor-content.ts';
import createVendorContentMutation from '../../api/content/createVendorContentMutation.graphql';
import { Upload } from '@aws-sdk/lib-storage';
import { S3 } from '@aws-sdk/client-s3';
import { Progress } from '@aws-sdk/lib-storage/dist-types/types';
import moment from 'moment/moment';
import { useVendorManager } from '../VendorManagerProvider';
import { isEmpty, isEqual } from 'lodash-es';
import { toast } from 'react-toastify';
import deleteVendorContentMutation from '../../api/content/deleteVendorContentMutation.graphql';
import updateVendorContentMutation from '../../api/content/updateVendorContentMutation.graphql';

// Provider definition
const defaultContext = {} as VendorContentManagerProvider;
const VendorContentManagerContext = createContext(defaultContext);
const { Provider, Consumer: ConfigConsumer } = VendorContentManagerContext;
const VendorContentManagerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { currentVendor } = useVendorManager();
    const [contentQuery, setContentQuery] = useState({} as VendorContentQuery);
    const [contentItemQuery, setContentItemQuery] = useState({} as VendorContentItemQuery);

    const updateContentQuery = (config: VendorContentQuery) => {
        if (!isEqual(config, contentQuery)) setContentQuery(config);
    };

    const updateContentItemQuery = (config: VendorContentItemQuery) => {
        if (!isEqual(config, contentItemQuery)) setContentItemQuery(config);
    };

    const [contentItemsData, setContentItemsData] = useState({
        items: [] as VendorContentItem[],
        count: 0,
        cursor: undefined,
    });

    const [contentItemData, setContentItemData] = useState(undefined as VendorContentItem | undefined);

    const {
        client,
        loading: isFetchingContent,
        data: contentQueryData,
        fetchMore,
        startPolling,
        stopPolling,
    } = useQuery(getVendorContentQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            query: contentQuery,
        },
        skip: !currentVendor?.vendorUuid || isEmpty(contentQuery),
    });


    const { loading: isFetchingContentItem, data: contentItemQueryData } = useQuery(getVendorContentItemQuery, {
        variables: {
            vendorUuid: currentVendor?.vendorUuid,
            contentId: contentItemQuery?.contentId,
        },
        skip: !currentVendor?.vendorUuid || !contentItemQuery?.contentId,
    });

    useEffect(() => {
        setContentItemsData(contentQueryData?.getVendorContent?.data);
    }, [contentQueryData]);

    useEffect(() => {
        setContentItemData(contentItemQueryData?.getVendorContentItem?.data);
    }, [contentItemQueryData]);

    const [updateVendorContent, { loading: isUpdatingContent }] = useMutation(updateVendorContentMutation);
    const [deleteVendorContent, { loading: isDeletingContent }] = useMutation(deleteVendorContentMutation);

    useEffect(() => {
        // We poll to get latest status data from server
        const isPollingRequired = Boolean(
            contentItemsData?.items?.find(({ vdoCipherUploadParams, vdoCipherVideoData, s3UploadParams }) => {
                const isVdoCipherUpload = !!vdoCipherUploadParams;
                const isS3Upload = !!s3UploadParams;
                const nowMoment = moment();
                if (isVdoCipherUpload)
                    return (
                        vdoCipherVideoData?.status !== 'ready' &&
                        vdoCipherVideoData?.status?.toLowerCase()?.indexOf('error') == -1 &&
                        nowMoment.diff(moment(vdoCipherUploadParams.currentTime), 'hours') <= 24
                    );
                if (isS3Upload)
                    return (
                        s3UploadParams?.status !== 'COMPLETED' &&
                        1 &&
                        nowMoment.diff(moment(s3UploadParams.currentTime), 'hours') <= 24
                    );

                return false;
            }),
        );

        if (isPollingRequired) startPolling(5000);
        else stopPolling();
    }, [contentItemsData]);

    const contentItems = {
        items: contentItemsData?.items as VendorContentItem[],
        count: contentItemsData?.count,
        cursor: contentItemsData?.cursor,
        fetchMore: async () => {
            if (contentItemsData?.cursor)
                await fetchMore({
                    variables: {
                        vendorUuid: currentVendor?.vendorUuid,
                        contentQuery: {
                            cursor: contentItemsData?.cursor,
                            limit: contentQuery?.limit,
                            sortBy: contentQuery?.sortBy,
                            sortDirection: contentQuery?.sortDirection,
                        },
                    },
                });
        },
    };

    const refetchContentItems = async () =>
        client.refetchQueries({
            include: [getVendorContentQuery],
        });

    const refetchContentItem = async () =>
        client.refetchQueries({
            include: [getVendorContentItemQuery],
        });

    const updateContent = async (
        contentUpdates: {
            SK: string;
            title?: string;
            posterImageUrl?: string;
        }[],
    ) =>
        updateVendorContent({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                contentUpdates: contentUpdates?.map(({ SK, title, posterImageUrl }) => ({
                    SK,
                    ...(posterImageUrl ? { posterImageUrl } : {}),
                    ...(title ? { title } : {}),
                })),
            },
        })
            .then(refetchContentItems)
            .then(refetchContentItem);

    const deleteContent = async (contentIds: string[]) =>
        deleteVendorContent({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                contentIds,
            },
        })
            .then(refetchContentItems)
            .then(refetchContentItem);

    const [contentUploadProgress, setContentUploadProgress] = useState([] as VendorContentUploadProgressItem[]);
    const [isUploading, setIsUploading] = useState(false);

    const uploadContent = async (files: File[]) => {
        if (!files?.length) throw new Error('No files to upload.');
        toast.info('Content upload in progress...');
        setIsUploading(true);
        setContentUploadProgress(files.map((_) => ({ percentUploaded: 0 })));

        try {
            const response = await client.mutate({
                mutation: createVendorContentMutation,
                variables: {
                    vendorUuid: currentVendor?.vendorUuid,
                    files: files.map((file) => ({
                        fileName: file.name,
                        contentType: file.type,
                        fileSizeBytes: file.size,
                    })),
                },
                fetchPolicy: 'network-only',
            });

            const contentUploads = response.data?.createVendorContent?.data;

            await refetchContentItems();
            await refetchContentItem();
            await Promise.all(
                files.map(async (file, index) => {
                    const contentUploadParams =
                        contentUploads[index]?.vdoCipherUploadParams || contentUploads[index]?.s3UploadParams;
                    const Key = contentUploadParams.key;

                    const parallelUploads = new Upload({
                        client: new S3({
                            region: contentUploadParams.region,
                            credentials: {
                                accessKeyId: contentUploadParams.accessKeyId,
                                secretAccessKey: contentUploadParams.secretAccessKey,
                                sessionToken: contentUploadParams.sessionToken,
                            },
                        }),
                        params: {
                            Bucket: contentUploadParams.bucket,
                            Key,
                            Body: file,
                            Metadata: { contentId: contentUploads[index].SK },
                        },

                        tags: [
                            /*...*/
                        ], // optional tags
                        queueSize: 5, // optional concurrency configuration
                        partSize: 1024 * 1024 * 10, // optional size of each part, in bytes, at least 5MB
                        leavePartsOnError: false, // optional manually handle dropped parts
                    });

                    parallelUploads.on('httpUploadProgress', (progress: Progress) => {
                        if (progress?.total !== undefined)
                            setContentUploadProgress((prevState) =>
                                prevState.map((fileUploadProgress, uploadProgressIndex) => {
                                    if (index === uploadProgressIndex) {
                                        const percentUploaded =
                                            Math.round(
                                                (((progress?.loaded || 0) * 100) / (progress?.total || 1) +
                                                    Number.EPSILON) *
                                                    100,
                                            ) / 100;
                                        const uploadStartedAt = fileUploadProgress?.uploadStartedAt
                                            ? fileUploadProgress?.uploadStartedAt
                                            : new Date().toISOString();
                                        const uploadEndedAt =
                                            percentUploaded === 100
                                                ? new Date().toISOString()
                                                : fileUploadProgress?.uploadEndedAt;

                                        let totalUploadSeconds = undefined;
                                        if (uploadStartedAt && uploadEndedAt)
                                            totalUploadSeconds = moment(uploadEndedAt).diff(
                                                moment(uploadStartedAt),
                                                'seconds',
                                            );
                                        return {
                                            ...fileUploadProgress,
                                            fileS3Key: contentUploadParams?.key,
                                            percentUploaded,
                                            uploadStartedAt,
                                            uploadEndedAt,
                                            totalUploadSeconds,
                                        };
                                    }

                                    return fileUploadProgress;
                                }),
                            );
                    });

                    await parallelUploads.done();
                    await refetchContentItems();
                    await refetchContentItem();
                }),
            );

            await setContentUploadProgress([]);
            toast.success('Content uploaded to library.');
        } catch (e) {
            console.log('e', e);
        } finally {
            setIsUploading(false);
        }
    };
    return (
        <Provider
            value={{
                updateContentQuery,
                updateContentItemQuery,
                isFetchingContent,
                contentItems,
                isFetchingContentItem,
                contentItem: contentItemData,
                contentUploadProgress,
                isUploading,
                uploadContent,
                deleteContent,
                isDeletingContent,
                updateContent,
                isUpdatingContent,
                refetchContentItems,
                refetchContentItem,
            }}>
            {children}
        </Provider>
    );
};

// Quick access hook
const useVendorContentManager = (config?: { initialQuery: VendorContentQuery }) => {
    const { updateContentQuery, ...functions } = useContext(VendorContentManagerContext);
    useEffect(() => {
        if (config?.initialQuery) updateContentQuery(config?.initialQuery);
    }, [config]);

    return { ...functions, updateContentQuery };
};

export { VendorContentManagerProvider, ConfigConsumer, useVendorContentManager };
