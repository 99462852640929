import BaseModal from '../_BaseModal';
import { useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Button from '../../Button';
import classNames from '../../../utils/classNames.ts';
import { VendorCommunityTypeOption } from '../../../types/vendor-communities.ts';
import { useVendorCommunitiesManager } from '../../../providers/VendorCommunitiesManagerProvider';
import BaseCard from '../../_cards/BaseCard';
import Typography from '../../Typography';

const COMMUNITIES_TYPES_OPTIONS = [
    {
        communityType: 'discord',
        title: 'Discord',
        description: 'Connect your Discord Community and apply discord roles to your customers with their purchases. ',
    },
    {
        // isComingSoon: true,
        communityType: 'slack',
        title: 'Slack',
        description:
            'Connect your Slack Workspace and provide your customers access to specific channels based on their purchases. ',
    },
] as VendorCommunityTypeOption[];

// audiobook, fitness course/workout

const ConnectCommunityModal = ({ isOpen, onClose }: { isOpen?: boolean; onClose: () => void }) => {
    const [selectedCommunityType, setSelectedCommunityType] = useState(COMMUNITIES_TYPES_OPTIONS[0]);
    const [isFetchingRedirectUrl, setIsFetchingRedirectUrl] = useState(false);

    const { connectCommunity } = useVendorCommunitiesManager();

    const onClickCreate = async () => {
        if (!selectedCommunityType?.communityType) return;
        setIsFetchingRedirectUrl(true);
        try {
            const redirectUrl = await connectCommunity({ communityType: selectedCommunityType.communityType });
            if (redirectUrl) window.location.href = redirectUrl;
        } catch (e) {
            setIsFetchingRedirectUrl(false);
        }
    };

    return (
        <BaseModal title="Connect Community" subtitle="Where is your community?" isOpen={isOpen} onClose={onClose}>
            <div className="mt-4 flex flex-col gap-4 lg:max-w-lg">
                <RadioGroup
                    value={selectedCommunityType?.communityType}
                    onChange={(communityType) =>
                        setSelectedCommunityType(
                            COMMUNITIES_TYPES_OPTIONS.find(
                                (type) => type.communityType === communityType,
                            ) as VendorCommunityTypeOption,
                        )
                    }
                    className="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
                    {COMMUNITIES_TYPES_OPTIONS.map(({ communityType, title, description, isComingSoon }) => (
                        <Radio
                            key={communityType}
                            value={communityType}
                            aria-label={title}
                            aria-description={description}
                            disabled={isComingSoon}
                            className="group relative cursor-pointer">
                            <BaseCard className='flex h-full'>
                                <span className={classNames('flex flex-1', isComingSoon && 'opacity-30')}>
                                    <span className="flex flex-col">
                                        <div className="flex flex-row items-center gap-2">
                                            <img
                                                alt=""
                                                src={`/community-icons/${communityType}.svg`}
                                                className="h-7 w-7 flex-shrink-0 rounded-full"
                                            />
                                            <Typography isPrimary className="block font-medium">{title}</Typography>
                                        </div>
                                        <Typography isTertiary className="mt-1 flex items-center">
                                            {description}
                                        </Typography>
                                        {/*<span className="mt-6 text-sm font-medium text-gray-900">{mailingList.users}</span>*/}
                                    </span>
                                </span>
                                <CheckCircleIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 text-white [.group:not([data-checked])_&]:invisible"
                                />
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute -inset-px rounded-md border border-transparent group-data-[focus]:border group-data-[checked]:border-white"
                                />
                                {isComingSoon && (
                                    <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center italic text-xl">
                                        Coming Soon
                                    </div>
                                )}
                            </BaseCard>
                        </Radio>
                    ))}
                </RadioGroup>
                <div className={classNames('mt-4 flex flex-row items-center gap-4 justify-end')}>
                    <Button isPrimary isLoading={isFetchingRedirectUrl} onClick={onClickCreate}>
                        Connect Community
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default ConnectCommunityModal;
