export const sortByOptions = [
    {
        value: 'FILE_NAME',
        label: 'File Name',
    },
    {
        value: 'FILE_SIZE',
        label: 'File Size',
    },
    {
        value: 'CONTENT_TYPE',
        label: 'Content Type',
    },
    {
        value: 'UPLOADED_AT',
        label: 'Uploaded At',
    },
];
export const sortDirectionOptions = [
    {
        value: 'DESC',
        label: 'Descending',
    },
    {
        value: 'ASC',
        label: 'Ascending',
    },
];
