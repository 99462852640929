import PageHeading from '../../components/PageHeading';
import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import { useVendorCommunitiesManager } from '../../providers/VendorCommunitiesManagerProvider';
import PageLoader from '../../components/PageLoader';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import ConnectCommunityModal from '../../components/_modals/ConnectCommunityModal';
import CommunityDetailDrawer from '../../components/_drawers/CommunityDetailDrawer';
import Pill from '../../components/Pill';
import BaseCard from '../../components/_cards/BaseCard';
import Typography from '../../components/Typography';

const CommunitiesPage = () => {
    const { communities, isFetchingCommunities } = useVendorCommunitiesManager();
    const [isConnectCommunityModalOpen, setIsConnectCommunityModalOpen] = useState(false);
    const [selectedCommunityId, setSelectedCommunityId] = useState(undefined as undefined | string);

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Communities"
                subtitle="Manage your communities."
                actions={[
                    {
                        title: 'Connect Community',
                        isPrimary: true,
                        onClick: () => setIsConnectCommunityModalOpen(true),
                    },
                ]}
            />
            <PageLoader isLoading={isFetchingCommunities}>
                {communities?.length > 0 && (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                        {communities?.map((community) => {
                            const communityType = community?.SK?.split('#')[0];
                            return (
                                <BaseCard
                                    key={community.SK}
                                    className="col-span-1 divide-y divide-gray-200 cursor-pointer"
                                    onClick={() => setSelectedCommunityId(community.SK)}>
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex-1 flex flex-col gap-2 items-start">
                                            <Typography className="truncate">
                                                {
                                                    {
                                                        discord: community?.discordGuild?.name,
                                                        slack: community?.slackWorkspace?.team?.name,
                                                    }?.[communityType as string]
                                                }
                                            </Typography>
                                            <Pill>
                                                <img
                                                    alt=""
                                                    src={`/community-icons/${community?.SK?.split('#')?.[0]}.svg`}
                                                    className="h-4 w-4 flex-shrink-0 rounded-full"
                                                />
                                                <div>{startCase(communityType)}</div>
                                            </Pill>
                                            <Typography className="truncate">{`${{
                                                discord: community?.discordGuild?.memberCount,
                                                slack: community?.slackWorkspace?.totalUsers,
                                            }?.[communityType as string]} users`}</Typography>
                                        </div>
                                        <img
                                            alt=""
                                            src={
                                                {
                                                    discord: community?.discordGuild?.iconURL,
                                                    slack: community?.slackWorkspace?.team?.icon?.image132,
                                                }?.[communityType as string]
                                            }
                                            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                                        />
                                    </div>
                                </BaseCard>
                            );
                        })}
                    </div>
                )}

                <ConnectCommunityModal
                    isOpen={!!isConnectCommunityModalOpen}
                    onClose={() => setIsConnectCommunityModalOpen(false)}
                />

                <CommunityDetailDrawer
                    communityId={selectedCommunityId}
                    onClose={() => setSelectedCommunityId(undefined)}
                />
            </PageLoader>
        </VendorsLoadingLayout>
    );
};
export default CommunitiesPage;
