import { useMutation, useQuery } from '@apollo/client';
import getVendorThemeQuery from '../api/themes/getVendorThemeQuery.graphql';
import createUpdateVendorThemeMutation from '../api/themes/createUpdateVendorThemeMutation.graphql';
import { useVendorManager } from '../providers/VendorManagerProvider';
import { isEmpty, keys, omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import {
    VendorTheme,
    VendorThemeAssets,
    VendorThemeColours, VendorThemeIndicators,
    VendorThemeLogos,
    VendorThemeProperties,
    VendorThemeTypography,
} from '../types/vendor-theme.ts';
import { toast } from 'react-toastify';

const useVendorThemes = () => {
    const { currentVendor } = useVendorManager();
    const [createUpdateVendorTheme, { loading: isSavingTheme }] = useMutation(createUpdateVendorThemeMutation);

    const { loading: isFetchingTheme, data: themeData } = useQuery(getVendorThemeQuery, {
        variables: { vendorUuid: currentVendor?.vendorUuid },
        skip: !currentVendor?.vendorUuid,
    });
    const vendorTheme = themeData?.getVendorTheme?.data || [];
    const theme = vendorTheme?.reduce(
        (
            acc: object,
            {
                property,
                theme,
            }: {
                property: string;
                theme: VendorThemeLogos | VendorThemeColours | VendorThemeAssets | VendorThemeTypography | VendorThemeIndicators;
            },
        ) => ({
            ...acc,
            [property]: omit(theme, ['__typename', 'createdAt', 'updatedAt']),
        }),
        {},
    ) as VendorTheme;

    const [localChanges, setLocalChanges] = useState({} as VendorTheme);
    const clearLocalChanges = (config?: { noNotification: boolean }) => {
        setLocalChanges({});
        if (!config?.noNotification) toast.info('Theming changes cleared.');
    };
    const updateLocalChanges = ({
        property,
        data,
    }: {
        property: VendorThemeProperties;
        data: VendorThemeLogos | VendorThemeColours | VendorThemeAssets | VendorThemeTypography | VendorThemeIndicators;
    }) =>
        setLocalChanges({
            ...localChanges,
            [property]: {
                ...(localChanges?.[property] || {}),
                ...data,
            },
        });

    useEffect(() => {
        if (themeData) clearLocalChanges({ noNotification: true });
    }, [themeData]);

    const saveLocalChanges = async () => {
        if (isEmpty(localChanges)) return;

        await createUpdateVendorTheme({
            variables: {
                vendorUuid: currentVendor?.vendorUuid,
                themes: keys(localChanges).map((property: string) => ({
                    property,
                    theme: localChanges[property as VendorThemeProperties],
                })),
            },
            awaitRefetchQueries: true,
            refetchQueries: [{ query: getVendorThemeQuery, variables: { vendorUuid: currentVendor?.vendorUuid } }],
        });
        toast.success('Theming changes published.');
    };

    return {
        isFetchingTheme,
        isSavingTheme,
        theme,
        localChanges,
        updateLocalChanges,
        clearLocalChanges,
        saveLocalChanges,
    };
};

export default useVendorThemes;
