import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import PageHeading from '../../components/PageHeading';
import { ArrowUpOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import ContentUploadModal from '../../components/_modals/ContentUploadModal';
import ThemedSelect from '../../components/ThemedSelect';
import { sortByOptions, sortDirectionOptions } from '../../consts/media-library.ts';
import PageLoader from '../../components/PageLoader';
import { useVendorContentManager } from '../../providers/VendorContentManagerProvider';
import ContentDetailDrawer from '../../components/_drawers/ContentDetailDrawer';
import { VendorContentItem } from '../../types/vendor-content.ts';
import Button from '../../components/Button';
import {
    VendorContentQuerySortByOptions,
    VendorContentQuerySortDirections,
} from '../../types/vendor-content-manager-provider.ts';
import ContentItem from '../../components/_items/ContentItem';
import { useNavigate, useParams } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import ConfirmationModal from '../../components/_modals/ConfirmationModal';

const ContentLibraryPage = () => {
    const navigate = useNavigate();
    const { contentId } = useParams();
    const [sortDirection, setSortDirection] = useState({
        value: 'DESC',
        label: 'Descending',
    });
    const [sortBy, setSortBy] = useState({
        value: 'UPLOADED_AT',
        label: 'Uploaded At',
    });

    const { isFetchingContent, contentItems, deleteContent, isDeletingContent } = useVendorContentManager({
        initialQuery: {
            limit: 100,
            sortBy: sortBy?.value as VendorContentQuerySortByOptions,
            sortDirection: sortDirection?.value as VendorContentQuerySortDirections,
        },
    });
    const [selectedContentId, setSelectedContentId] = useState(undefined as undefined | string);
    useEffect(() => {
        setSelectedContentId(contentId);
    }, [contentId]);

    const [isContentUploadModalOpen, setIsContentUploadModalOpen] = useState(false);
    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(
        null as null | {
            question: string;
            onConfirm: () => Promise<void>;
        },
    );

    const [multiSelectedContentItems, setMultiSelectedContentItems] = useState([] as string[]);
    const toggleMultiSelectContentItem = (contentItem: VendorContentItem) => {
        if (multiSelectedContentItems.indexOf(contentItem.SK) > -1)
            setMultiSelectedContentItems((prevState) => prevState.filter((item) => item !== contentItem.SK));
        else setMultiSelectedContentItems((prevState) => [...prevState, contentItem.SK]);
    };

    const [sentryRef] = useInfiniteScroll({
        loading: isFetchingContent,
        hasNextPage: !!contentItems?.cursor,
        onLoadMore: contentItems?.fetchMore,
        // disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    const onClickDelete = async () => {
        setIsShowConfirmationModal({
            question: `Are you sure you want to delete ${multiSelectedContentItems?.length} items?`,
            onConfirm: async () => {
                if (multiSelectedContentItems?.length > 0) {
                    await deleteContent(multiSelectedContentItems);
                    setMultiSelectedContentItems([]);
                }
            },
        });
    };

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Content"
                subtitle="Manage your content library for digital products."
                actions={[
                    {
                        title: 'Upload',
                        isPrimary: true,
                        leftIcon: <ArrowUpOnSquareIcon className={`h-4 w-4`} aria-hidden="true" />,
                        onClick: () => setIsContentUploadModalOpen(true),
                    },
                ]}
            />

            <div className="mt-6 flex flex-col md:flex-row flex-wrap gap-6">
                <div className="mt-4 flex flex-row items-center w-full gap-4">
                    <ThemedSelect
                        defaultValue={sortBy}
                        onChange={(e) => e && setSortBy({ value: e.value, label: e.label })}
                        options={sortByOptions?.filter((option) => option.value !== sortBy?.value)}
                        isSearchable={false}
                    />
                    <ThemedSelect
                        defaultValue={sortDirection}
                        onChange={(e) => e && setSortDirection({ value: e.value, label: e.label })}
                        options={sortDirectionOptions?.filter((option) => option.value !== sortDirection?.value)}
                        isSearchable={false}
                    />
                    {multiSelectedContentItems?.length > 0 && (
                        <Button
                            size="sm"
                            onClick={onClickDelete}
                            leftIcon={<TrashIcon className={`h-4 w-4 shrink-0 text-white`} aria-hidden="true" />}
                            isLoading={isDeletingContent}>
                            {`Delete (${multiSelectedContentItems.length})`}
                        </Button>
                    )}
                </div>
                <PageLoader isLoading={isFetchingContent}>
                    <div className="flex flex-row flex-wrap gap-4">
                        {contentItems?.items?.map((contentItem) => (
                            <ContentItem
                                key={contentItem.SK}
                                contentItem={contentItem}
                                onClick={() => navigate(`/content-library/${contentItem?.SK}`)}
                                isMultiSelectEnabled
                                isMultiSelected={multiSelectedContentItems.indexOf(contentItem.SK) > -1}
                                onToggleMultiSelect={() => toggleMultiSelectContentItem(contentItem)}
                            />
                        ))}
                        {(isFetchingContent || !!contentItems?.cursor) && <div ref={sentryRef}>Loading...</div>}
                    </div>
                </PageLoader>
            </div>
            <ContentUploadModal
                isOpen={!!isContentUploadModalOpen}
                onClose={() => setIsContentUploadModalOpen(false)}
            />
            <ContentDetailDrawer contentId={selectedContentId} onClose={() => navigate(`/content-library`)} />
            <ConfirmationModal
                isOpen={!!isShowConfirmationModal}
                onClose={() => setIsShowConfirmationModal(null)}
                {...isShowConfirmationModal}
            />
        </VendorsLoadingLayout>
    );
};
export default ContentLibraryPage;
