import VendorsLoadingLayout from '../../components/VendorsLoadingLayout';
import useVendorThemes from '../../hooks/useVendorThemes.tsx';
import PageHeading from '../../components/PageHeading';
import { PencilIcon } from '@heroicons/react/24/outline';
import ColourPickerModal from '../../components/_modals/ColourPickerModal';
import { useState } from 'react';
import { cloneDeep, isEmpty, mergeWith } from 'lodash-es';
import PageLoader from '../../components/PageLoader';
import FontPickerModal from '../../components/_modals/FontPickerModal';
import MediaPickerModal from '../../components/_modals/MediaPickerModal';
import serveMediaLibraryImage from '../../utils/serveMediaLibraryImage.ts';
import Typography from '../../components/Typography';
import BaseCard from '../../components/_cards/BaseCard';
import Button from '../../components/Button';
import classNames from '../../utils/classNames.ts';

const ThemingPage = () => {
    const {
        isFetchingTheme,
        isSavingTheme,
        theme,
        localChanges,
        updateLocalChanges,
        clearLocalChanges,
        saveLocalChanges,
    } = useVendorThemes();
    const [isColourPickerModalOpen, setIsColourPickerModalOpen] = useState(
        null as null | {
            title: string;
            isGradientColourOptionEnabled?: boolean;
            defaultColours?: string[];
            defaultAngle?: number;
            onUpdateColours: ({ hexColours, angle }: { hexColours: string[]; angle?: number }) => boolean;
        },
    );
    const [isFontPickerModalOpen, setIsFontPickerModalOpen] = useState(
        null as null | {
            title: string;
            defaultFontFamilyName?: string;
            onUpdateFont: (fontFamilyName: string, fontUrl: string) => boolean;
        },
    );
    const [isMediaPickerModalOpen, setIsMediaPickerModalOpen] = useState(
        null as null | {
            title: string;
            defaultImageUrl?: string;
            defaultImageDarkBackground?: boolean;
            onUpdateImage: (imageUrl: string) => boolean;
        },
    );

    const latestTheme = mergeWith(cloneDeep(theme), cloneDeep(localChanges), (objValue, srcValue) => {
        // Replace arrays instead of merging them
        if (Array.isArray(objValue)) {
            return srcValue;
        }
    });

    const form = [
        {
            sectionTitle: 'Logos',
            fields: [
                {
                    title: 'Logo',
                    subtitle: 'Full size version of your logo, ideally wider than it is tall',
                    fieldType: 'logoImage',
                    fieldData: { data: [latestTheme?.LOGOS?.logoDark] },
                    onClickEdit: () =>
                        setIsMediaPickerModalOpen({
                            defaultImageUrl: latestTheme?.LOGOS?.logoDark,
                            defaultImageDarkBackground: true,
                            title: 'Update Logo Image',
                            onUpdateImage: (logoDark: string) => {
                                updateLocalChanges({ property: 'LOGOS', data: { logoDark: encodeURI(logoDark) } });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Logo Icon',
                    subtitle: 'Square icon version of your logo, used for icons',
                    fieldType: 'logoIconImage',
                    fieldData: { data: [latestTheme?.LOGOS?.logoIconDark] },
                    darkImageBackground: true,
                    onClickEdit: () =>
                        setIsMediaPickerModalOpen({
                            defaultImageUrl: latestTheme?.LOGOS?.logoIconDark,
                            title: 'Update Logo Icon Image',
                            onUpdateImage: (logoIconDark: string) => {
                                updateLocalChanges({
                                    property: 'LOGOS',
                                    data: { logoIconDark: encodeURI(logoIconDark) },
                                });
                                return true;
                            },
                        }),
                },
            ],
        },
        {
            sectionTitle: 'Typography',
            fields: [
                {
                    title: 'Font Family',
                    subtitle: 'The font family to be used for all typography',
                    fieldType: 'input',
                    fieldData: { data: [latestTheme?.TYPOGRAPHY?.fontFamilyName] },
                    onClickEdit: () =>
                        setIsFontPickerModalOpen({
                            defaultFontFamilyName: latestTheme?.TYPOGRAPHY?.fontFamilyName,
                            title: 'Update Font Family',
                            onUpdateFont: (fontFamilyName: string, fontUrl: string) => {
                                updateLocalChanges({ property: 'TYPOGRAPHY', data: { fontFamilyName, fontUrl } });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Text on Primary Colour',
                    subtitle: 'How text appears on the primary colour',
                    fieldType: 'colour',
                    fieldData: { data: [latestTheme?.COLOURS?.textOnPrimaryColour] },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.textOnPrimaryColour
                                ? [latestTheme.COLOURS.textOnPrimaryColour]
                                : [],
                            title: 'Update Text on Primary Colour',
                            onUpdateColours: ({ hexColours }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { textOnPrimaryColour: hexColours?.[0] },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Primary Text Colour',
                    subtitle: 'Colour of titles & headings',
                    fieldType: 'colour',
                    fieldData: { data: [latestTheme?.COLOURS?.primaryTextColour] },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.primaryTextColour
                                ? [latestTheme.COLOURS.primaryTextColour]
                                : [],
                            title: 'Update Primary Text Colour',
                            onUpdateColours: ({ hexColours }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { primaryTextColour: hexColours?.[0] },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Secondary Text Colour',
                    subtitle: 'Colour of subtitles & subheadings',
                    fieldType: 'colour',
                    fieldData: { data: [latestTheme?.COLOURS?.secondaryTextColour] },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.secondaryTextColour
                                ? [latestTheme.COLOURS.secondaryTextColour]
                                : [],
                            title: 'Update Secondary Text Colour',
                            onUpdateColours: ({ hexColours }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { secondaryTextColour: hexColours?.[0] },
                                });
                                return true;
                            },
                        }),
                },
            ],
        },
        {
            sectionTitle: 'Assets',
            fields: [
                {
                    title: 'Custom Loader',
                    subtitle: 'Use a looping custom loader animation (.gif) for your platform.',
                    fieldType: 'indicator',
                    fieldData: { data: [latestTheme?.INDICATORS?.loopUrl] },
                    onClickEdit: () =>
                        setIsMediaPickerModalOpen({
                            defaultImageUrl: latestTheme?.INDICATORS?.loopUrl,
                            title: 'Update Custom Loader',
                            onUpdateImage: (loopUrl: string) => {
                                updateLocalChanges({ property: 'INDICATORS', data: { loopUrl: encodeURI(loopUrl) } });
                                return true;
                            },
                        }),
                },
                // {
                //     title: 'Thank You Image',
                //     subtitle: 'Image a customer sees on the thank you page',
                //     fieldType: 'bannerImage',
                //     fieldData: { data: [latestTheme?.ASSETS?.thankYouImageUrl] },
                //     onClickEdit: () =>
                //         setIsMediaPickerModalOpen({
                //             defaultImageUrl: latestTheme?.ASSETS?.thankYouImageUrl,
                //             title: 'Update Thank You Image',
                //             onUpdateImage: (thankYouImageUrl: string) => {
                //                 updateLocalChanges({ property: 'ASSETS', data: { thankYouImageUrl: encodeURI(thankYouImageUrl) } });
                //                 return true;
                //             },
                //         }),
                // },
            ],
        },
        {
            sectionTitle: 'Colours',
            fields: [
                {
                    title: 'Primary Colour',
                    subtitle: 'Main colour, used mainly for main user actions',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.primaryColours,
                        angle: latestTheme?.COLOURS?.primaryColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.primaryColours,
                            defaultAngle: latestTheme?.COLOURS?.primaryColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Primary Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { primaryColours: hexColours, primaryColoursAngle: angle },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Secondary Colour',
                    subtitle: 'Secondary colour, used mainly for secondary user actions',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.secondaryColours,
                        angle: latestTheme?.COLOURS?.secondaryColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.secondaryColours,
                            defaultAngle: latestTheme?.COLOURS?.secondaryColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Secondary Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { secondaryColours: hexColours, secondaryColoursAngle: angle },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Background Colour',
                    subtitle: 'Background colour used for entire platform',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.backgroundColours,
                        angle: latestTheme?.COLOURS?.backgroundColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.backgroundColours,
                            defaultAngle: latestTheme?.COLOURS?.backgroundColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Background Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { backgroundColours: hexColours, backgroundColoursAngle: angle },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Card Background Colour',
                    subtitle: 'The colour used for the background of cards',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.cardBackgroundColours,
                        angle: latestTheme?.COLOURS?.cardBackgroundColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.cardBackgroundColours,
                            defaultAngle: latestTheme?.COLOURS?.cardBackgroundColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Card Background Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { cardBackgroundColours: hexColours, cardBackgroundColoursAngle: angle },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Card Border Colour',
                    subtitle: 'The colour used for the border of cards',
                    fieldType: 'colour',
                    fieldData: { data: [latestTheme?.COLOURS?.cardBorderColour] },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.cardBorderColour
                                ? [latestTheme.COLOURS.cardBorderColour]
                                : [],
                            title: 'Update Card Border Colour',
                            onUpdateColours: ({ hexColours }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { cardBorderColour: hexColours?.[0] },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Popup Background Colour',
                    subtitle: 'The colour used for the background of popups',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.popupBackgroundColours,
                        angle: latestTheme?.COLOURS?.popupBackgroundColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.popupBackgroundColours,
                            defaultAngle: latestTheme?.COLOURS?.popupBackgroundColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Popup Background Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { popupBackgroundColours: hexColours, popupBackgroundColoursAngle: angle },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Navigation Background Colour',
                    subtitle: 'Navigation background colour used for sidebars, top bars, etc',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.navigationColours,
                        angle: latestTheme?.COLOURS?.navigationColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.navigationColours,
                            defaultAngle: latestTheme?.COLOURS?.navigationColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Navigation Background Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { navigationColours: hexColours, navigationColoursAngle: angle },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Navigation Selected Text Colour',
                    subtitle: 'The colour used for the text of selected navigation menu items',
                    fieldType: 'colour',
                    fieldData: { data: [latestTheme?.COLOURS?.navigationSelectedTextColour] },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.navigationSelectedTextColour
                                ? [latestTheme.COLOURS.navigationSelectedTextColour]
                                : [],
                            title: 'Update Navigation Selected Text Colour',
                            onUpdateColours: ({ hexColours }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: { navigationSelectedTextColour: hexColours?.[0] },
                                });
                                return true;
                            },
                        }),
                },
                {
                    title: 'Navigation Selected Highlight Colour',
                    subtitle: 'The colour used to highlight a selected navigation menu item',
                    fieldType: 'colour',
                    fieldData: {
                        data: latestTheme?.COLOURS?.navigationSelectedHighlightColours,
                        angle: latestTheme?.COLOURS?.navigationSelectedHighlightColoursAngle,
                    },
                    onClickEdit: () =>
                        setIsColourPickerModalOpen({
                            defaultColours: latestTheme?.COLOURS?.navigationSelectedHighlightColours,
                            defaultAngle: latestTheme?.COLOURS?.navigationSelectedHighlightColoursAngle,
                            isGradientColourOptionEnabled: true,
                            title: 'Update Navigation Background Colour',
                            onUpdateColours: ({ hexColours, angle }) => {
                                updateLocalChanges({
                                    property: 'COLOURS',
                                    data: {
                                        navigationSelectedHighlightColours: hexColours,
                                        navigationSelectedHighlightColoursAngle: angle,
                                    },
                                });
                                return true;
                            },
                        }),
                },
            ],
        },
    ] as {
        sectionTitle: string;
        fields: {
            title: string;
            subtitle: string;
            fieldType: 'logoImage' | 'logoIconImage' | 'colour' | 'bannerImage' | 'input' | 'indicator';
            fieldData: { data?: string[]; angle?: number };
            darkImageBackground?: boolean;
            onClickEdit?: () => void;
            onUpdateData?: (data: string) => void;
        }[];
    }[];

    return (
        <VendorsLoadingLayout>
            <PageHeading
                title="Theming"
                subtitle="Customise your platform to your brand identity by adjusting your theme."
                actions={[
                    {
                        title: 'Clear',
                        isDisabled: isEmpty(localChanges),
                        onClick: clearLocalChanges,
                    },
                    {
                        title: 'Publish',
                        isPrimary: true,
                        isDisabled: isEmpty(localChanges),
                        onClick: saveLocalChanges,
                        isLoading: isSavingTheme,
                    },
                ]}
            />

            <div className="mt-6 flex flex-col md:flex-row flex-wrap gap-4">
                <PageLoader isLoading={isFetchingTheme}>
                    {form.map(({ sectionTitle, fields }, index) => (
                        <BaseCard className="flex-1" key={index}>
                            <Typography isHeading block className="font-semibold text-xl sm:w-64 sm:flex-none sm:pr-6">
                                {sectionTitle}
                            </Typography>
                            <div
                                className={classNames(
                                    'mt-4',
                                    sectionTitle === 'Colours'
                                        ? 'grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3'
                                        : 'flex flex-col gap-4',
                                )}>
                                {fields?.map(
                                    (
                                        { title, subtitle, fieldType, fieldData, darkImageBackground, onClickEdit },
                                        index,
                                    ) => (
                                        <div key={index}>
                                            <div className="flex items-center gap-4">
                                                {fieldType === 'logoImage' && (
                                                    <div
                                                        className={`h-16 w-16 object-contain flex items-center justify-center border overflow-hidden ${
                                                            darkImageBackground ? 'bg-black' : 'bg-gray-100'
                                                        }`}
                                                        style={{
                                                            borderColor: 'rgba(255, 255, 255, 0.3)',
                                                        }}>
                                                        {fieldData?.data?.[0] && (
                                                            <img
                                                                className="w-full h-full object-contain"
                                                                src={serveMediaLibraryImage(
                                                                    fieldData?.data?.[0] as string,
                                                                    {
                                                                        width: 128,
                                                                        format: 'png',
                                                                        // greyscale: true,
                                                                    },
                                                                )}
                                                                alt={title}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {fieldType === 'logoIconImage' && (
                                                    <div
                                                        className={`h-16 w-16 object-contain flex items-center justify-center border overflow-hidden ${
                                                            darkImageBackground ? 'bg-black' : 'bg-gray-100'
                                                        }`}
                                                        style={{
                                                            borderColor: 'rgba(255, 255, 255, 0.3)',
                                                        }}>
                                                        {fieldData?.data?.[0] && (
                                                            <img
                                                                className="w-full h-full object-contain"
                                                                src={serveMediaLibraryImage(
                                                                    fieldData?.data?.[0] as string,
                                                                    {
                                                                        width: 128,
                                                                        format: 'png',
                                                                        // greyscale: true,
                                                                    },
                                                                )}
                                                                alt={title}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {fieldData && fieldType === 'bannerImage' && (
                                                    <div
                                                        className={`w-32 object-contain flex items-center justify-center ring-1 ring-gray-200 overflow-hidden ${
                                                            darkImageBackground ? 'bg-black' : 'bg-gray-100'
                                                        }`}>
                                                        {fieldData?.data?.[0] && (
                                                            <img
                                                                className="w-full h-full object-contain"
                                                                src={serveMediaLibraryImage(
                                                                    fieldData?.data?.[0] as string,
                                                                    {
                                                                        width: 128,
                                                                        format: 'png',
                                                                        // greyscale: true,
                                                                    },
                                                                )}
                                                                alt={title}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {fieldType === 'indicator' && (
                                                    <div
                                                        className={`h-16 w-16 object-contain flex items-center justify-center overflow-hidden`}
                                                        style={{
                                                            borderColor: 'rgba(255, 255, 255, 0.3)',
                                                        }}>
                                                        {fieldData?.data?.[0] && (
                                                            <img
                                                                className="w-full h-full object-contain"
                                                                src={serveMediaLibraryImage(
                                                                    fieldData?.data?.[0] as string,
                                                                    {
                                                                        // greyscale: true,
                                                                    },
                                                                )}
                                                                alt={title}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {fieldType === 'colour' && (
                                                    <div
                                                        className={`h-8 w-8 rounded-full border`}
                                                        style={{
                                                            ...((fieldData?.data?.length ?? 0) > 1
                                                                ? {
                                                                      background: `linear-gradient(${
                                                                          fieldData?.angle || 0
                                                                      }deg, ${fieldData?.data?.join(',')}) no-repeat`,
                                                                      backgroundPosition: 'center',
                                                                      // backgroundSize: '107%'
                                                                  }
                                                                : { backgroundColor: fieldData?.data?.[0] }),
                                                            borderColor: 'rgba(255, 255, 255, 0.3)',
                                                        }}
                                                    />
                                                )}
                                                <div className="flex-1">
                                                    <Typography isPrimary>{title}</Typography>
                                                    <Typography isTertiary>{subtitle}</Typography>
                                                    <Button
                                                        size="xs"
                                                        className="mt-2"
                                                        leftIcon={<PencilIcon className="h-3 w-3" />}
                                                        onClick={onClickEdit}>
                                                        {fieldType === 'input'
                                                            ? fieldData?.data?.[0] || 'Edit'
                                                            : 'Edit'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>
                        </BaseCard>
                    ))}
                </PageLoader>
            </div>

            <MediaPickerModal
                isOpen={!!isMediaPickerModalOpen}
                onClose={() => setIsMediaPickerModalOpen(null)}
                {...isMediaPickerModalOpen}
            />

            <ColourPickerModal
                isOpen={!!isColourPickerModalOpen}
                onClose={() => setIsColourPickerModalOpen(null)}
                {...isColourPickerModalOpen}
            />

            <FontPickerModal
                isOpen={!!isFontPickerModalOpen}
                onClose={() => setIsFontPickerModalOpen(null)}
                {...isFontPickerModalOpen}
            />
        </VendorsLoadingLayout>
    );
};
export default ThemingPage;
