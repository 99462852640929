import { ComponentPropsWithRef, HTMLAttributes, ReactNode } from 'react';
import classNames from '../../../utils/classNames.ts';

export default ({
    ref,
    className,
    children,
    ...props
}: ComponentPropsWithRef<'div'> & {
    className?: string;
    children: ReactNode | ReactNode[];
} & HTMLAttributes<HTMLDivElement>) => (
    <div
        ref={ref}
        className={classNames('bg-card-background p-4 rounded-md shadow-lg border border-card-border', className)}
        {...props}>
        {children}
    </div>
);
