import BaseModal from '../_BaseModal';
import { useEffect, useRef, useState } from 'react';
import Button from '../../Button';
import classNames from '../../../utils/classNames.ts';
import isValidEmail from '../../../utils/isValidEmail.ts';
import { useVendorUsersManager } from '../../../providers/VendorUsersManagerProvider';
import Typography from '../../Typography';
import { VendorUserItem } from '../../../types/vendor-user.ts';
import Input from '../../Input';

const NewUserModal = ({
    isOpen,
    onClose,
    onNewUserCreated,
}: {
    isOpen?: boolean;
    onClose: () => void;
    onNewUserCreated?: (user: VendorUserItem) => void;
}) => {
    const { isCreatingUser, createUser } = useVendorUsersManager();
    const firstNameInputRef = useRef<HTMLInputElement>(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const onResetState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrorMessage('');
    };

    useEffect(() => {
        if (firstNameInputRef?.current) firstNameInputRef?.current?.focus();
    }, []);

    const onClickCreate = async () => {
        if (!firstName || !lastName || !isValidEmail(email)) return;

        const { error, data: newUser } = await createUser({
            user: {
                givenName: firstName,
                familyName: lastName,
                email,
            },
        });

        if (error?.code === 'USERNAME_EXISTS') setErrorMessage('This email address is already in use.');
        if (!!newUser && !error && onNewUserCreated) {
            onNewUserCreated(newUser);
            onResetState();
        }
    };

    return (
        <BaseModal title="New User" subtitle="Create a new user." isOpen={isOpen} onClose={onClose}>
            <div className="w-64 mt-4 flex flex-col gap-4 lg:max-w-2xl">
                <div>
                    <label htmlFor="first-name" className="sr-only">
                        First Name
                    </label>
                    <Input
                        ref={firstNameInputRef}
                        id="first-name"
                        name="first-name"
                        type="text"
                        autoComplete="false"
                        placeholder="First name..."
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="last-name" className="sr-only">
                        Last Name
                    </label>
                    <Input
                        id="last-name"
                        name="last-name"
                        type="text"
                        autoComplete="false"
                        placeholder="Last name..."
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                {errorMessage && (
                    <Typography isError className="text-center">
                        {errorMessage}
                    </Typography>
                )}
                <div>
                    <label htmlFor="email-address" className="sr-only">
                        Email
                    </label>
                    <Input
                        id="email-address"
                        name="email-address"
                        type="text"
                        autoComplete="false"
                        placeholder="Email address..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className={classNames('mt-4 flex flex-row items-center gap-4 justify-end')}>
                    <Button
                        isPrimary
                        isLoading={isCreatingUser}
                        isDisabled={!firstName || !lastName || !isValidEmail(email)}
                        onClick={onClickCreate}>
                        Create User
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};

export default NewUserModal;
